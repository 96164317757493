import { Injectable } from '@angular/core'
import { getCoreLocales } from '@arzt-direkt/wfa-definitions'
import { Nullable, removeDuplicates } from '@arzt-direkt/wfa-generic-utils'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'

type Locales = string[]

/**
 * Manages language selection and translation with NgxTranslate integration.
 * Provides reactive locale updates with automatic sorting and deduplication.
 */
@Injectable({
  providedIn: 'root',
})
export class LanguagePickerService {
  private _locales$: Nullable<BehaviorSubject<Locales>> =
    new BehaviorSubject<Locales>(getCoreLocales())

  /**
   * @remarks
   * The component using this observable must handle its lifecycle
   * and ensure proper cleanup when no longer needed
   */
  get locales$(): Nullable<BehaviorSubject<Locales>> {
    return this._locales$
  }

  updateLocales(locales: Locales) {
    const unique = removeDuplicates(locales)
    const sorted = this.sortLocalesByTranslation(unique)
    this._locales$?.next(sorted)
  }

  /**
   * Override this function to handle locale changes
   */
  onLocaleChange = (_lang: string) => {
    // Default implementation does nothing
  }

  constructor(private translateService: TranslateService) {}

  update(lang: string): void {
    try {
      this.translateService.use(lang)
      this.onLocaleChange(lang)
    } catch (error) {
      console.error('[LanguagePickerService/update]:', error)
    }
  }

  private sortLocalesByTranslation(locales: string[]): string[] {
    return locales.sort((a, b) => {
      const translationA = this.translateLanguage(a)
      const translationB = this.translateLanguage(b)
      return translationA.localeCompare(translationB)
    })
  }

  /**
   * Returns the localized language name or falls back to the language code
   * if translation key 'WFA.LANGUAGES.[code]' is not found
   */
  private translateLanguage(lang: string): string {
    const candidate = this.translateService.instant(`WFA.LANGUAGES.${lang}`)
    return candidate.includes('WFA.LANGUAGES') ? lang : candidate
  }
}
