import { isNil } from './is-nil'

/* from https://youmightnotneed.com/lodash */

export function get(
  obj: unknown,
  path: string | string[],
  defValue?: any,
): any {
  if (!path) return undefined
  if (isNil(obj)) return undefined

  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  if (pathArray == null) return undefined

  const result = pathArray.reduce(
    (prevObj, key) => prevObj && (prevObj as Record<string, unknown>)?.[key],
    obj,
  )
  return result === undefined && defValue ? defValue : result
}
