import {
  CoreLocale,
  SurveyjsFileInputFormat,
} from '@arzt-direkt/wfa-definitions'
import {
  isWfaMessage,
  newWfaMessage,
  isNil,
  partition,
  WfaMessage,
} from '@arzt-direkt/wfa-generic-utils'
import {
  filter,
  forkJoin,
  fromEvent,
  map,
  mergeMap,
  Observable,
  of,
  take,
} from 'rxjs'
import { SurveyModel, UploadFilesEvent } from 'survey-core'

import { FileUploadSettings, settings } from '../settings'
import { formatFile } from './format-file'
import { getLocalizedErrorMessage } from './get-localized-error-message'
import { isFileImage } from './image-upload/is-file-image'
import { scaleImage } from './image-upload/scale-image'

type Base64String = string

export function handleFileUpload(
  surveyModel: SurveyModel,
  options: UploadFilesEvent,
): void {
  const locale = surveyModel.locale as CoreLocale
  uploadFiles(options.files, settings, locale)
    .pipe(take(1))
    .subscribe(results => handleUploadResults(results, options))
}

function uploadFiles(
  files: File[],
  settings: FileUploadSettings,
  locale: CoreLocale,
): Observable<(SurveyjsFileInputFormat | WfaMessage)[]> {
  const observables = files.map(file => uploadFile(file, settings, locale))
  return forkJoin(observables)
}

function uploadFile(
  file: File,
  settings: FileUploadSettings,
  locale: CoreLocale,
): Observable<SurveyjsFileInputFormat | WfaMessage> {
  const validatedFile = validateFile(file, settings, locale)
  if (isWfaMessage(validatedFile)) return of(validatedFile)

  const { name, type } = file
  const fileIsImage = isFileImage(type)

  const reader = new FileReader()
  reader.readAsDataURL(file)

  return fromEvent(reader, 'load').pipe(
    take(1),
    mergeMap(_ => {
      if (reader.error) {
        const message = getLocalizedErrorMessage(locale, 'uploadError', {
          errorMessage: reader.error.message,
        })
        return of(newWfaMessage(message))
      }

      const base64String = reader.result as Base64String
      if (isNil(base64String)) {
        const message = getLocalizedErrorMessage(locale, 'readFileError', {
          name,
        })
        return of(newWfaMessage(message))
      }

      if (fileIsImage) return scaleImage(base64String, settings)
      return of(base64String)
    }),
    filter(
      (x: Base64String | WfaMessage): x is Base64String => !isWfaMessage(x),
    ),
    map((base64String: Base64String) => {
      const fileType = fileIsImage ? settings.image.saveType : type
      return formatFile(name, fileType, base64String)
    }),
  )
}

function validateFile(
  file: File,
  settings: FileUploadSettings,
  locale: CoreLocale,
): WfaMessage | File {
  const { mediaTypes, maxMegaByteSize } = settings
  const { type, name, size } = file
  if (mediaTypes.includes(type) === false) {
    const message = getLocalizedErrorMessage(locale, 'fileTypeError', {
      name,
      type,
    })
    return newWfaMessage(message)
  }

  const maxByteSize = maxMegaByteSize * Math.pow(1024, 2)
  if (size > maxByteSize) {
    const message = getLocalizedErrorMessage(locale, 'fileSizeError', {
      name,
      maxMegaByteSize,
    })
    return newWfaMessage(message)
  }

  return file
}

function handleUploadResults(
  results: (SurveyjsFileInputFormat | WfaMessage)[],
  options: UploadFilesEvent,
): void {
  const [messages, surveyjsFiles] = partition(results, isWfaMessage)
  if (surveyjsFiles.length > 0) options.callback('success', surveyjsFiles)
  if (messages.length > 0)
    options.callback('error', (messages as WfaMessage[])[0].message)
}
