import {
  CustomPropertyInfo,
  sharedProperties,
  TomedoVersion,
} from '@arzt-direkt/wfa-definitions'

import { Dictionary } from '../../../types/dictionary'
import { mergeObjects } from '../../../utility/merge-objects'
import { editorOnlyProperties } from './editor-only/_editor-only.barrel'

export function customEditorProperties(tomedoVersion?: TomedoVersion) {
  const supportedSharedProperties = sharedProperties(tomedoVersion)
  const supportedEditorOnlyProperties = editorOnlyProperties(tomedoVersion)
  return mergeObjects(
    ...supportedSharedProperties,
    ...supportedEditorOnlyProperties,
  ) as Dictionary<CustomPropertyInfo[]>
}

export const customViewerProperties = mergeObjects(
  ...sharedProperties(),
) as Dictionary<CustomPropertyInfo[]>
