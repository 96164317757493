import { CoreLocale, WfaForm } from '@arzt-direkt/wfa-definitions'
import { Nullable, nonNil } from '@arzt-direkt/wfa-generic-utils'

import { replaceUmlautsInString } from '../../utility/replace-umlauts'
import { initialViewerState } from '../viewer.state'
import { formatDate } from './format-date'

const defaultLocale = initialViewerState.env.locale

export function formatTitleToFileName(
  locale: CoreLocale,
  wfaForm: WfaForm,
  date: Date,
): string {
  const title = getTitle(locale, wfaForm)
  const formattedTitle = nonNil(title)
    ? formatTitle(title)
    : 'unbekannter-titel'
  const formattedDate = formatDate(date, '-')
  return `${formattedTitle}_${formattedDate}`
}

function getTitle(locale: CoreLocale, wfaForm: WfaForm): Nullable<string> {
  const title = wfaForm.title
  if (typeof title === 'string') return title
  return title[locale] ?? title[defaultLocale]
}

function formatTitle(title: string): string {
  let formatted = title.toLowerCase()
  formatted = replaceUmlautsInString(formatted)
  formatted = formatted.replace(/[^a-z\s-]/g, '')
  return formatted.replace(/\s/g, '-')
}
