export const propertyTitles = {
  altText: {
    de: 'Alternativtext',
    en: 'Alternative text',
    es: 'Texto alternativo',
    fr: 'Texte de remplacement',
    it: 'Testo alternativo',
  },
  expression: {
    name: {
      de: 'ID des Ausdrucks',
      en: 'Expression ID',
      es: 'ID de la expresión',
      fr: "ID de l'expression",
      it: "ID dell'espressione",
    },
  },
  imageLink: {
    de: 'Bild (aus URL oder Datei)',
    en: 'Image (from URL or file)',
    es: 'Imagen (de URL o archivo)',
    fr: `Image (de l'URL ou du fichier)`,
    it: 'Immagine (da URL o file)',
  },
  logo: {
    de: 'Logo (aus URL oder Datei)',
    en: 'Logo (from URL or file)',
    es: 'Logotipo (de URL o archivo)',
    fr: `Logo (de l'URL ou du fichier)`,
    it: 'Logo (da URL o file)',
  },
  question: {
    name: {
      de: 'ID der Frage',
      en: 'Question ID',
      es: 'ID de la pregunta',
      fr: 'ID de la question',
      it: 'ID della domanda',
    },
  },
  value: {
    de: 'ID',
    en: 'ID',
    es: 'ID',
    fr: 'ID',
    it: 'ID',
  },
}
