import { Question } from 'survey-core'
import { SurveyCreatorModel } from 'survey-creator-core'

export function generateUniqueShortTitle(
  creator: SurveyCreatorModel,
  question: Question,
  newShortTitle: string,
): string {
  let uniqueShortTitle = newShortTitle
  const shortTitlesOfOtherQuestions = creator.survey
    .getAllQuestions()
    .filter(q => q !== question)
    .map(q => q.shortTitle)

  let index = 1
  while (shortTitlesOfOtherQuestions.includes(uniqueShortTitle)) {
    index++
    uniqueShortTitle = newShortTitle + index
  }

  return uniqueShortTitle
}

export function isShortTitleUnique(
  creator: SurveyCreatorModel,
  selectedQuestion: Question,
  selectedShortTitle: string,
): boolean {
  const questions = creator.survey.getAllQuestions()
  const shortTitles = questions
    .filter(question => question !== selectedQuestion)
    .map(question => question.shortTitle)
    .filter(shortTitle => shortTitle === selectedShortTitle)

  return shortTitles.length === 0
}
