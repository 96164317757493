import { isNil, nonNil, Nullable, set } from '@arzt-direkt/wfa-generic-utils'

import { trimSlashes } from './string.utils'

export function getPermalink(
  frontendUrl: string,
  frontendSubdirectory: string,
  praxisKennung: string,
  wfaFormId: string,
): string {
  return [
    frontendUrl,
    frontendSubdirectory,
    'permalink',
    praxisKennung,
    wfaFormId,
  ]
    .map(trimSlashes)
    .filter(str => nonNil(str) && str !== '')
    .join('/')
}

export function updatePermalink(
  oldPermalink?: Nullable<string>,
  change?: {
    praxisKennung?: string
    wfaFormId?: string
  },
): string | null {
  if (isNil(oldPermalink)) return null

  const parts = trimSlashes(oldPermalink).split('/')
  if (parts.length < 3) {
    console.error(`[updatePermalink]: could not parse link ${oldPermalink}`)
    return null
  }

  const lastIndex = parts.length - 1

  if (nonNil(change?.praxisKennung))
    set(parts, `${lastIndex - 1}`, change?.praxisKennung)

  if (nonNil(change?.wfaFormId)) set(parts, `${lastIndex}`, change?.wfaFormId)

  return parts.join('/')
}
