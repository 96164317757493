import {
  CopyDynamicWfaFormFormParam as CFormParams,
  CopyDynamicWfaFormFormParamJoi as cFormJoi,
  CopyDynamicWfaFormParams as CParams,
  CopyDynamicWfaFormPreferredParams as CDbParams,
  CopyDynamicWfaFormPreferredParamsJoi as cDbJoi,
  PraxisId,
  WfaFormInfo,
  zollsoftPublicLibrary,
} from '@arzt-direkt/wfa-definitions'
import { isValid, Nullable, isNil } from '@arzt-direkt/wfa-generic-utils'
import { combineLatest, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import {
  emptyInfos,
  FormAndDraftInfos,
  ZsSupportCommsService as Comms,
} from '../zs-support-comms.service'

function sumbitUnlockFromMongoDb(p: CDbParams, comms: Comms) {
  return comms
    .unlockWfaForm(p.praxisId, p.wfaFormVersionId, p.newWfaFormId)
    .pipe(switchMap(() => comms.getFormAndDraftInfos(p.praxisId)))
}

function sumbitUnlockUploadedByUser(p: CFormParams, comms: Comms) {
  return comms
    .unlockWfaFormWithForm(p.wfaForm)
    .pipe(switchMap(() => comms.getFormAndDraftInfos(p.wfaForm.praxisId)))
}

export function submitUnlock(
  unlockParams: Partial<CParams>,
  comms: Comms,
): Observable<FormAndDraftInfos> {
  if (isNil(unlockParams)) {
    console.error(
      `[submitUnlock]: could not submit unlockParams ${JSON.stringify(
        unlockParams,
      )}.`,
    )
    return of(emptyInfos)
  }
  if (isValid<CDbParams>(cDbJoi, unlockParams))
    return sumbitUnlockFromMongoDb(unlockParams, comms)
  if (isValid<CFormParams>(cFormJoi, unlockParams))
    return sumbitUnlockUploadedByUser(unlockParams, comms)
  return of(emptyInfos)
}

export function sumbitStandardForms(
  praxisId: Nullable<PraxisId>,
  comms: Comms,
) {
  if (isNil(praxisId)) {
    console.error(`[submitStandardForms]: praxisId is missing.`)
    return of(emptyInfos)
  }

  const stdInfos$ = comms.getFormAndDraftInfos(zollsoftPublicLibrary._id)

  const stdUnlocks$ = stdInfos$.pipe(
    map((infos: FormAndDraftInfos) => infos.formInfos),
    switchMap((formInfos: WfaFormInfo[]) =>
      combineLatest(
        formInfos.map(fi =>
          comms.unlockWfaForm(praxisId, fi.wfaFormVersionId, fi.wfaFormId),
        ),
      ),
    ),
  )

  return combineLatest(stdUnlocks$).pipe(
    switchMap(() => comms.getFormAndDraftInfos(praxisId)),
  )
}
