import { coreLocales } from '@arzt-direkt/wfa-definitions'
import { mergeDeep, nonNil } from '@arzt-direkt/wfa-generic-utils'
import { localization } from 'survey-creator-core'

import { customizeTranslations as customizePreviewTranslations } from '../../../shared-surveyjs/translation-customization/customize-translations'
import { getLocalizedTranslations } from '../../../utility/get-localized-translations'
import { customTranslations } from './custom-translations/_custom-translations'
import { renameTerms } from './rename-terms'

export function customizeTranslations(): void {
  customizeCreatorTranslations()
  customizePreviewTranslations()
}

function customizeCreatorTranslations(): void {
  coreLocales.forEach(locale => {
    const translations = localization.getLocale(locale)
    const localizedTranslations = getLocalizedTranslations(
      customTranslations,
      locale,
    )
    if (nonNil(translations) && nonNil(localizedTranslations)) {
      renameTerms(translations, locale)
      mergeDeep(translations, localizedTranslations)
    }
  })
}
