import {
  dummyGetInstance,
  env,
  TargetBuild,
  WfaInstanceSlice,
} from '@arzt-direkt/wfa-definitions'
import { consoleLogger, Logger, Nullable } from '@arzt-direkt/wfa-generic-utils'
import { BehaviorSubject } from 'rxjs'

import {
  defaultEncryptString,
  EncryptString,
} from '../app/types/encrypt-string'
import { NotificationService } from '../app/utility-components/notification/notification.service'
import { EditorEnv, initialEditorEnv } from './../app/editor/editor.env'

interface InstanceDetails {
  identifier: string
}

export interface WfaEnv {
  instanceDetails?: Nullable<InstanceDetails>
  editorEnv$: BehaviorSubject<EditorEnv>
  encryptString: EncryptString
  frontendUrl: string
  frontendUrlSubdirectory: string
  logger: Logger
  production: boolean
  demoMode: boolean
  serverUrl: string
  serverUrlSubdirectory: string
  targetBuild: TargetBuild
  notification: NotificationService
  getInstance: () => WfaInstanceSlice
}

export type AdisWfaEnv = WfaEnv & {
  targetBuild: 'adis'
}

export type LotosWfaEnv = WfaEnv & {
  targetBuild: 'lotos'
}

export type IndesWfaEnv = WfaEnv & {
  targetBuild: 'indes'
}

export const indesWfaEnv: Omit<WfaEnv, 'notification'> = {
  demoMode: true,
  editorEnv$: new BehaviorSubject<EditorEnv>(initialEditorEnv),
  encryptString: defaultEncryptString,
  frontendUrl: env.indesWfaUrl,
  frontendUrlSubdirectory: env.indesWfaUrlSubdirectory,
  logger: consoleLogger,
  production: false,
  serverUrl: env.indesWfbUrl,
  serverUrlSubdirectory: env.indesWfbUrlSubdirectory,
  targetBuild: 'indes',
  getInstance: () => dummyGetInstance(),
}

export const lotosWfaEnv: Omit<WfaEnv, 'notification'> = {
  demoMode: false,
  editorEnv$: new BehaviorSubject<EditorEnv>(initialEditorEnv),
  encryptString: defaultEncryptString,
  frontendUrl: env.lotosWfaUrl,
  frontendUrlSubdirectory: env.lotosWfaUrlSubdirectory,
  logger: consoleLogger,
  production: false,
  serverUrl: env.lotosWfbHost,
  serverUrlSubdirectory: env.lotosWfbUrlSubdirectory,
  targetBuild: 'lotos',
  getInstance: () => dummyGetInstance(),
}

export function isAdisWfaEnv(x: unknown): x is AdisWfaEnv {
  return (x as WfaEnv).targetBuild === 'adis'
}

export function isLotosWfaEnv(x: unknown): x is LotosWfaEnv {
  return (x as WfaEnv).targetBuild === 'lotos'
}

export function isIndesWfaEnv(x: unknown): x is IndesWfaEnv {
  return (x as WfaEnv).targetBuild === 'indes'
}
