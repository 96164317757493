import { CommonModule } from '@angular/common'
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import {
  ensureWfaForm,
  FormResponseData,
  initialViewerEnvStateDict,
  isCompleteForm,
  isExportPDF,
  isFormFromTomedo,
  isResponseRequestedByTomedo,
  isStatusRequestedByTomedo,
  ViewerEnvState,
  WebviewOutputData,
  WfaForm,
  WfaFormResponse,
  WfaFormResponseJoi,
  wfaVersion,
} from '@arzt-direkt/wfa-definitions'
import {
  ensureType,
  isWfaMessage,
  Nullable,
  nonNil,
  isNil,
} from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy } from '@ngneat/until-destroy'
import { NGXLogger } from 'ngx-logger'
import { BehaviorSubject } from 'rxjs'

import { WfaEnvService } from '../../environments/wfa-env.service'
import { PlainMessageComponent } from '../plain-message/plain-message.component'
import { convertFormResponseToHumanReadable } from '../viewer/utility/convert-form-response-to-human-readable/convert-form-response-to-human-readable'
import { ViewerComponent } from '../viewer/viewer.component'
import { applyAdi3325Hack } from './apply-adi-3325-hack'
import { createWfaFormResponse } from './create-wfa-form-response'
import waitingForInputMsg from './waiting-for-input.msg.json'

type ShowContentId = 'waitingForInput' | 'loading' | 'form' | 'invalidForm'

@UntilDestroy()
@Component({
  selector: 'wfa-webview-viewer',
  standalone: true,
  imports: [CommonModule, ViewerComponent, PlainMessageComponent],
  templateUrl: './webview-viewer.component.html',
  styleUrls: ['./webview-viewer.component.scss'],
  providers: [],
})
export class WebviewViewerComponent implements OnInit {
  @ViewChild('wfaViewer') wfaViewer!: ViewerComponent

  envState$ = new BehaviorSubject<ViewerEnvState>(
    initialViewerEnvStateDict['iOsTomedo'],
  )
  form$ = new BehaviorSubject<Nullable<WfaForm>>(undefined)
  formResponse$ = new BehaviorSubject<Nullable<WfaFormResponse>>(undefined)
  requestResponse$ = new BehaviorSubject<boolean>(false)
  showContentTitle: ShowContentId = 'waitingForInput'
  showContentJson: Nullable<string> = waitingForInputMsg.de

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private logger: NGXLogger,
    private router: Router,
    private wfaEnv: WfaEnvService,
  ) {}

  ngOnInit(): void {
    this.logger.log(`[WFA] webview-viewer onInit`)

    this.envState$.subscribe(envState => {
      this.logger.log(
        `[WFA] webview-viewer envState.isEmbeddedIn ${envState.isEmbeddedIn}`,
      )
    })

    window.addEventListener('message', (event: MessageEvent) => {
      if (isStatusRequestedByTomedo(event.data)) this.submitStatusToTomedo()
      if (isResponseRequestedByTomedo(event.data)) this.submitResponseToTomedo()
      if (isExportPDF(event.data)) this.wfaViewer.exportPdf()
      if (isCompleteForm(event.data)) this.wfaViewer.completeForm()
      if (isFormFromTomedo(event.data)) {
        const formData = event.data.formData
        let formResponse = formData.formResponse

        const sanitizedForm = formData.form
        applyAdi3325Hack(sanitizedForm)
        try {
          ensureWfaForm(sanitizedForm)
        } catch (e) {
          this.wfaEnv.notification.warn([
            'WFA.NOTIFICATION.WEBVIEW-VIEWER.FORM-VALIDATION-601',
            `${sanitizedForm['message']} for wfaVersion ${wfaVersion}`,
          ])
        }

        this.form$.next(sanitizedForm as WfaForm)
        this.showContentTitle = 'form'
        this.showContentJson = undefined

        try {
          ensureType(WfaFormResponseJoi, formResponse)
        } catch (e) {
          const newResponse = createWfaFormResponse(sanitizedForm)
          if (isWfaMessage(newResponse)) {
            this.wfaEnv.notification.warn([
              'WFA.NOTIFICATION.WEBVIEW-VIEWER.FORM-RESPONSE-602',
              `${sanitizedForm['message']} for wfaVersion ${wfaVersion}`,
            ])
            return
          }
          formResponse = newResponse
        }
        this.formResponse$.next(formResponse)

        if (nonNil(event.data.envState))
          this.envState$.next(event.data.envState)
      }
    })

    window?.parent?.postMessage(
      { action: 'webview-viewer: OnInit complete' },
      '*',
    )
  }

  submitStatusToTomedo() {
    if (this.form$.value) {
      window?.parent?.postMessage({ action: 'submitStatusToTomedo' }, '*')
    }
  }

  submitResponseToTomedo() {
    this.requestResponse$.next(true)
  }

  onFormResponseDataEmitted(data: FormResponseData): void {
    const origin = data.formResponse.origin ?? ''

    if (
      [
        'blur',
        'completeButton',
        'requestResponse',
        'validateOnRender',
      ].includes(origin)
    ) {
      const form = this.form$.value
      if (isNil(form)) return

      this.sendFormAndResponseToTomedo(form, data)
    }
  }

  sendFormAndResponseToTomedo(
    form: WfaForm,
    formResponseData: FormResponseData,
  ): void {
    const { formIsValid, formResponse } = formResponseData
    const surveyjsResponse = formResponse?.surveyjsResponse ?? {}
    const humanReadable = form
      ? convertFormResponseToHumanReadable(form, surveyjsResponse)
      : undefined

    const formData = {
      form,
      formResponse,
      formIsValid,
      humanReadable,
    }

    const json: WebviewOutputData = {
      action: 'jsonToTomedo',
      formData,
    }

    this.logger.info(
      'webview-viewer: sendFormAndResponseToTomedo sends',
      JSON.stringify(json),
    )

    window.parent.postMessage(
      JSON.stringify({ ...json, postFromParentWindow: true }),
      '*',
    )
  }
}
