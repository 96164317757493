@if (showPublish) {
  <button
    matSuffix
    mat-icon-button
    aria-label="Publish"
    class="action-button"
    data-selenium-id="button-@action-buttons/publish"
    [matTooltip]="'WFA.ACTION-BUTTONS.PUBLISH' | translate"
    (click)="onPublish.emit()"
  >
    <mat-icon>check</mat-icon>
  </button>
}

@if (showPermalink) {
  <button
    matSuffix
    mat-icon-button
    aria-label="Permalink"
    class="action-button"
    matTooltipClass="allow-cr"
    [matTooltip]="
      (permalink
        ? 'WFA.ACTION-BUTTONS.PERMALINK-DISABLE'
        : 'WFA.ACTION-BUTTONS.PERMALINK-ENABLE'
      ) | translate
    "
    (click)="onPermalink.emit()"
    data-selenium-id="button-@action-buttons/permalink"
  >
    @if (permalink) {
      <mat-icon>link</mat-icon>
    } @else {
      <mat-icon>link_off</mat-icon>
    }
  </button>
}

<button
  matSuffix
  mat-icon-button
  aria-label="Edit"
  class="action-button"
  data-selenium-id="button-@action-buttons/edit"
  [matTooltip]="'WFA.ACTION-BUTTONS.EDIT' | translate"
  (click)="onEdit.emit()"
>
  <mat-icon>edit</mat-icon>
</button>

<button
  matSuffix
  mat-icon-button
  aria-label="Copy"
  class="action-button"
  data-selenium-id="button-@action-buttons/copy"
  [matTooltip]="'WFA.ACTION-BUTTONS.COPY' | translate"
  (click)="onCopy.emit()"
>
  <mat-icon>content_copy</mat-icon>
</button>

<button
  matSuffix
  mat-icon-button
  aria-label="Delete"
  class="action-button"
  data-selenium-id="button-@action-buttons/delete"
  [matTooltip]="'WFA.ACTION-BUTTONS.DELETE' | translate"
  (click)="onDelete.emit()"
>
  <mat-icon>delete</mat-icon>
</button>
