import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { nonNil } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy } from '@ngneat/until-destroy'
import { filter, map, of, take, withLatestFrom } from 'rxjs'

import { SingleOrMultilinkStore } from './../../adis/viewer-from-link/single-or-multilink/single-or-multilink.store'
import { generateFormResponse } from './../../viewer/utility/generate-form-response/generate-form-response'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-magic-fill',
  templateUrl: './magic-fill.component.html',
  styleUrls: ['./magic-fill.component.scss'],
  imports: [CommonModule, MatIconModule],
})
export class MagicFillComponent {
  constructor(private store: SingleOrMultilinkStore) {}

  magicFill() {
    of(null)
      .pipe(
        withLatestFrom(this.store.fwr$),
        map(([_, fwr]) => fwr),
        filter(nonNil),
        take(1),
      )
      .subscribe(fwr => {
        const surveyjsResponse = generateFormResponse(fwr.form)
        const formResponse = { ...fwr.formResponse, surveyjsResponse }
        this.store.setFormResponse(formResponse)
      })
  }
}
