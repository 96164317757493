import version from './version.json'

export * from './assets'
export { Env, env } from './env'
export * from './interfaces'
export { ResponseId } from './interfaces/response-id'
export * from './interfaces-autogen-from-joi'
export * from './joi'
export {
  CoreLocaleJoi,
  coreLocales,
  getCoreLocales,
  isCoreLocale,
} from './joi/core-locale.joi'
export {
  visibilities,
  visibilityDict,
  VisibilityJoi,
} from './joi/editor/property-grid/visibility.joi'
export {
  getIsEmbeddedIn,
  isEmbeddedInDict,
  IsEmbeddedInJoi,
  isEmbeddedIns,
} from './joi/is-embedded-in.joi'
export {
  hasUnencryptedResponse,
  SurveyjsResponseJoi,
} from './joi/surveyjs-response.joi'
export {
  dummyGetInstance,
  WfaInstanceSliceJoi,
} from './joi/wfa-instance-slice.joi'
export { WfaKeyJoi, wfaKeys } from './joi/wfa-key.joi'
export * from './joi-custom-validation'
export * from './shared-surveyjs'
export * from './utils'
export { getPermalink, updatePermalink } from './utils/get-permalink'
export { ensureWfaForm, validateWfaForm } from './utils/validate-wfa-form'
export { legacyForm } from './utils/wfa-form/form'
const { wfaDefinitionsVersion, wfaVersion } = version
export { wfaDefinitionsVersion, wfaVersion }
