import { isNil, Nullable } from '@arzt-direkt/wfa-generic-utils'
import {
  PropertyValueChangingEvent,
  SurveyCreatorModel,
} from 'survey-creator-core'
import { generateUniqueShortTitle } from '../generate-unique-short-title'
import { Question } from 'survey-core'

export function updatePropertiesWithShortTitle(
  creator: SurveyCreatorModel,
  options: PropertyValueChangingEvent,
): void {
  const question = options.obj as unknown as Question
  let newShortTitle = options.newValue as string

  if (isNil(newShortTitle) || newShortTitle.length === 0) {
    return
  }

  newShortTitle = newShortTitle.replace(/\s+/g, ' ').trim()
  const uniqueShortTitle = generateUniqueShortTitle(
    creator,
    question,
    newShortTitle,
  )
  options.newValue = uniqueShortTitle
}
