import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RxLet } from '@rx-angular/template/let'
import { NGXLogger } from 'ngx-logger'
import { switchMap } from 'rxjs'

import errorJson from './../../../user-message/single-or-multilink.load-error.json'
import loadingJson from './../../../user-message/single-or-multilink.loading.json'
import { UserMessageComponent } from './../../../user-message/user-message.component'
import { MultilinkViewerComponent } from './../multilink-viewer/multilink-viewer.component'
import { ViewerFromLinkComponent } from './../viewer-from-link.component'
import { SingleOrMultilinkStore } from './single-or-multilink.store'

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    MultilinkViewerComponent,
    RxLet,
    ViewerFromLinkComponent,
    UserMessageComponent,
  ],
  templateUrl: './single-or-multilink.component.html',
  styleUrls: ['./single-or-multilink.component.scss'],
})
export class SingleOrMultilinkComponent implements OnInit {
  readonly loadingJson = loadingJson
  readonly errorJson = errorJson

  constructor(
    private logger: NGXLogger,
    private route: ActivatedRoute,
    public store: SingleOrMultilinkStore,
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        switchMap(params => this.store.loadData(params)),
        untilDestroyed(this),
      )
      .subscribe()
  }
}
