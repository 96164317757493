import Joi from 'joi'

import { BaseQuestionJoi } from './base-question.joi'
import { wfaPflegegradQuestionExample } from './examples/wfa-pflegegrad-question.joi.example'

export const WfaPflegegradQuestionJoi = BaseQuestionJoi.keys({
  type: Joi.string().valid('pflegegrad').required(),
})
  .meta({
    className: 'WfaPflegegradQuestion',
  })
  .example(wfaPflegegradQuestionExample)
