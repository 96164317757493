import {
  defaultViewerEnvState,
  FormResponseOrigin,
  ViewerEnvState,
  WfaForm,
  WfaFormResponse,
} from '@arzt-direkt/wfa-definitions'
import { Nullable } from '@arzt-direkt/wfa-generic-utils'

export interface ViewerState extends Record<string, unknown> {
  env: ViewerEnvState
  form: Nullable<WfaForm>
  formResponse: Nullable<WfaFormResponse>
  latestResponseOrigin: Nullable<FormResponseOrigin>
}

export const initialViewerState: ViewerState = {
  env: defaultViewerEnvState,
  form: undefined,
  formResponse: undefined,
  latestResponseOrigin: undefined,
}
