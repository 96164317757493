import { allowMultiple } from './allow-multiple'
import { backgroundColor } from './background-color'
import { checkErrorsMode } from './check-errors-mode'
import { clearIfInvisible } from './clear-if-invisible'
import { inputType } from './input-type'
import { penColor } from './pen-color'
import { searchMode } from './search-mode'
import { showQuestionNumbers } from './show-question-numbers'
import { storeDataAsText } from './store-data-as-text'
import { title } from './title'
import { waitForUpload } from './wait-for-upload'

export const surveyjsProperties = [
  allowMultiple,
  backgroundColor,
  clearIfInvisible,
  checkErrorsMode,
  inputType,
  penColor,
  searchMode,
  showQuestionNumbers,
  storeDataAsText,
  title,
  waitForUpload,
]
