<!-- Screen reader page title (ARIA requirement) -->
<h1 class="visually-hidden">{{ title }}</h1>
<div
  class="viewer"
  [ngClass]="{
    'is-embedded-in-ios-tomedo': isEmbeddedIn === 'iOsTomedo',
    'is-embedded-in-arzt-direkt': isEmbeddedIn === 'arztDirekt',
    'is-embedded-in-ad-stepper': isEmbeddedIn === 'adStepper',
    multilink: linkType === 'multi',
    permalink: linkType === 'perma',
  }"
>
  @if (isEmbeddedIn !== 'adStepper') {
    <div class="viewer__menu">
      @if (wfaEnv.demoMode === true) {
        <span class="viewer__menu-entry">
          <wfa-magic-fill></wfa-magic-fill>
        </span>
      }
      <span class="viewer__menu-entry">
        <wfa-language-picker></wfa-language-picker>
      </span>
    </div>
  }

  <div class="viewer__surveyjs-model">
    <survey [model]="surveyModel"></survey>
  </div>

  <div class="viewer__footer" *ngIf="completed">
    <button mat-button class="btn-primary" (click)="exportPdf()">
      {{ 'PDF' | translate }}
    </button>
  </div>
</div>
