import Joi from 'joi'

import { WfaAcademicTitleQuestionJoi } from './wfa-academic-title-question.joi'
import { WfaBirthdateQuestionJoi } from './wfa-birthdate-question.joi'
import { WfaBooleanQuestionJoi } from './wfa-boolean-question.joi'
import { WfaCheckboxQuestionJoi } from './wfa-checkbox-question.joi'
import { WfaCommentQuestionJoi } from './wfa-comment-question.joi'
import { WfaContactQuestionJoi } from './wfa-contact-question.joi'
import { WfaCountryQuestionJoi } from './wfa-country-question.joi'
import { WfaDropdownQuestionJoi } from './wfa-dropdown-question.joi'
import { WfaExpressionQuestionJoi } from './wfa-expression-question.joi'
import { WfaFileQuestionJoi } from './wfa-file-question.joi'
import { WfaGenderQuestionJoi } from './wfa-gender-question.joi'
import { WfaGradDerBehinderungQuestionJoi } from './wfa-grad-der-behinderung-question.joi'
import { WfaHtmlQuestionJoi } from './wfa-html-question.joi'
import { WfaMatrixQuestionJoi } from './wfa-matrix-question.joi'
import { WfaPflegegradQuestionJoi } from './wfa-pflegegrad-question.joi'
import { WfaRadiogroupQuestionJoi } from './wfa-radiogroup-question.joi'
import { WfaSendingInformationQuestionJoi } from './wfa-sending-information-question.joi'
import { WfaSignaturepadQuestionJoi } from './wfa-signaturepad-question.joi'
import { WfaTagboxQuestionJoi } from './wfa-tagbox-question.joi'
import { WfaTextQuestionJoi } from './wfa-text-question.joi'

// adi-3172 <delete note when ticket is completed>
const wfaQuestions = [
  WfaAcademicTitleQuestionJoi,
  WfaBirthdateQuestionJoi,
  WfaBooleanQuestionJoi,
  WfaCheckboxQuestionJoi,
  WfaCommentQuestionJoi,
  WfaContactQuestionJoi,
  WfaCountryQuestionJoi,
  WfaDropdownQuestionJoi,
  WfaExpressionQuestionJoi,
  WfaFileQuestionJoi,
  WfaGenderQuestionJoi,
  WfaHtmlQuestionJoi,
  WfaMatrixQuestionJoi,
  WfaRadiogroupQuestionJoi,
  WfaSendingInformationQuestionJoi,
  WfaSignaturepadQuestionJoi,
  WfaTagboxQuestionJoi,
  WfaTextQuestionJoi,
  WfaPflegegradQuestionJoi,
  WfaGradDerBehinderungQuestionJoi,
]

export const WfaQuestionJoi = Joi.alternatives(...wfaQuestions).meta({
  className: 'WfaQuestion',
})
