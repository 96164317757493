import { CommonModule } from '@angular/common'
import { Component, ViewChild } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu'
import { setDocumentStyles } from '@arzt-direkt/colors'
import { WfaFormWithResponse as FWR } from '@arzt-direkt/wfa-definitions'
import { get, Nullable, nonNil } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { RxLet } from '@rx-angular/template/let'
import { filter, first } from 'rxjs'

import { I18nPipe } from './../../../utility/i18n.pipe'
import { NotificationService } from './../../../utility-components/notification/notification.service'
import { ScrollableSidebarComponent } from './../../../utility-components/scrollable-sidebar/scrollable-sidebar.component'
import { SingleOrMultilinkStore } from './../single-or-multilink/single-or-multilink.store'
import { ViewerFromLinkComponent } from './../viewer-from-link.component'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-multilink-viewer',
  imports: [
    CommonModule,
    I18nPipe,
    MatIconModule,
    MatMenuModule,
    RxLet,
    ScrollableSidebarComponent,
    TranslateModule,
    ViewerFromLinkComponent,
  ],
  styleUrl: './multilink-viewer.component.scss',
  templateUrl: './multilink-viewer.component.html',
})
export class MultilinkViewerComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined

  constructor(
    public notificationService: NotificationService,
    public store: SingleOrMultilinkStore,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    setDocumentStyles(document)
    this.store.fwrs$
      .pipe(filter(nonNil), first(), untilDestroyed(this))
      .subscribe((fwrs: Nullable<FWR[]>) => {
        const defaultForm = get(fwrs, '0')
        this.store.setFwr(defaultForm)
      })
  }
}
