<!-- errors -->
<div class="error" *ngIf="(doesParamExist && !betriebsstaetten) || isServerError">
  <div *ngIf="(doesParamExist && !betriebsstaetten)" class="title">
    {{'OTK.BOOKING-BS.ERRORS.INVALID-PARAMETERS' | translate}}
  </div>
  <div *ngIf="isServerError" class="title">
    {{'OTK.BOOKING-BS.ERRORS.SERVER' | translate}}
  </div>
  <span></span><img [src]="failureAssetUrl" [alt]="'OTK.BOOKING-BS.ERRORS.SERVER' | translate">
</div>
<!-- title -->
<h4>{{ (isZwCodeCorrect ? 'OTK.BOOKING-BS.LOCATION-QUESTION-ZW' : 'OTK.BOOKING-BS.LOCATION-QUESTION') | translate }}
</h4>
<!-- bs form group -->
<form attr.data-selenium-id="form-bs" [formGroup]="bookingBsFormGroup" id="otk-scrollable-content--booking-bs"
  *ngIf="isStepActive && isBetriebsstaettenArray" #form>
  <mat-button-toggle-group [hideSingleSelectionIndicator]="true" attr.data-selenium-id="mat-button-toggle-group-bs"
    formControlName="bs">

    <!-- bs options -->
    <mat-button-toggle *ngFor="let bs of betriebsstaetten; let i = index" attr.data-selenium-id="'button-bs-'+i"
      [value]="bs">
      <div class="panel-content bs-panel">
        <div class="map-container">
          <app-streetmap #maps [id]="'map'+bs.betriebsstaetteIdent" [geodata]="bs.contact.geodata"></app-streetmap>
        </div>
        <div class="icon-container">
          <mat-icon class="all-icon">place</mat-icon>
        </div>
        <div class="bs-description">
          <span class="bstitle">{{ bs.name }}</span>
          <p class="address">
            {{ bs.contact.address_1 }} <br>
            {{ bs.contact.zip }} {{ bs.contact.city }}
          </p>
        </div>
      </div>
    </mat-button-toggle>

    <!-- "all" option (hide in case several bs were given as parameter, otherwise one can use this to access appointments of hidden bs) -->
    <mat-button-toggle *ngIf="!doesParamExist" attr.data-selenium-id="button-bs-all" [value]="bookingService.bsAll">
      <div class="panel-content icon-panel">
        <div class="icon-container">
          <mat-icon class="all-icon">today</mat-icon>
        </div>
        <div class="bs-description all-width">
          <span class="bstitle">
            {{ 'OTK.BOOKING-BS.ALLBS-ALT' | translate }}
          </span>
        </div>
      </div>
    </mat-button-toggle>

  </mat-button-toggle-group>
</form>