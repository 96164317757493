import { isNil } from '@arzt-direkt/wfa-generic-utils'
import { Question } from 'survey-core'
import { ElementFocusedEvent, SurveyCreatorModel } from 'survey-creator-core'

import { isWfaKey } from './wfa-key/wfa-key-utils'

export function handlePropertyGridOnElementChange(
  creator: SurveyCreatorModel,
): void {
  creator.onSelectedElementChanged.add((creatorBase, options) => {
    preventWfaKeyReset(options)
    creatorBase.validateSelectedElement()
    creator.collapseAllPropertyGridCategories()
    creator.expandPropertyTab('general')
  })
}

function preventWfaKeyReset(options: ElementFocusedEvent) {
  const question = options.newSelectedElement as Question
  if (isNil(question)) return
  if (isWfaKey(question.name)) question['wfaKey'] = question.name
}
