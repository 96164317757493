import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LibModule } from '@lib/lib.module';
import { TranslateModule } from '@ngx-translate/core';
import { SecondsToDoubleDigitsMinutesPipe } from './booking/booking-reservation/SecondsToDoubleDigitsMinutes.pipe';
import { I18NArrayPipe, I18NStringPipe, trustHtmlPipe } from './i18n/i18n.pipe';
import { AbbreviateFileNamePipe } from './misc/abbreviateFileName.pipe';

import { CounterComponent } from './misc/counter.component';
import { AppDateAdapter, AppDateFormats } from './misc/date-formats';
import { DatepickerCustomComponent } from './misc/datepicker-custom/datepicker-custom.component';
import { ExcerptPipe } from './misc/excerpt.pipe';
import { KbToMbPipe } from './misc/kb-to-Mb.pipe';
import { SanitizeHtmlPipe } from './misc/sanitize.pipe';
import { SearchStringPipe } from './misc/search-string.pipe';
import { VersionCheckPipe } from './misc/version-check.pipe';
import { WeekdayPipe } from './misc/weekday.pipe';

const SHARED_MODULES = [
    // General
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    LibModule,
    TranslateModule,
    // Angular Material
    MatFormFieldModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatStepperModule,
    MatTabsModule,
    MatTooltipModule
]


const SHARED_COMPONENTS = [
  CounterComponent,
  DatepickerCustomComponent
]


const SHARED_PIPES = [
  I18NStringPipe,
  I18NArrayPipe,
  trustHtmlPipe,
  SanitizeHtmlPipe,
  ExcerptPipe,
  KbToMbPipe,
  WeekdayPipe,
  AbbreviateFileNamePipe,
  SecondsToDoubleDigitsMinutesPipe,
  SearchStringPipe,
  VersionCheckPipe
]


@NgModule({
    imports: [...SHARED_MODULES, ...SHARED_COMPONENTS,
        ...SHARED_PIPES,],
    exports: [
        ...SHARED_MODULES,
        ...SHARED_COMPONENTS,
        ...SHARED_PIPES,
    ],
    providers: [
        ...SHARED_PIPES,
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de'
        }, {
            provide: DateAdapter,
            useClass: AppDateAdapter,
            deps: [MAT_DATE_LOCALE, Platform]
        }, {
            provide: MAT_DATE_FORMATS,
            useValue: AppDateFormats
        },
    ]
})
export class SharedModule { }


declare global {
  interface Window {
    PasswordCredential: any;
    FederatedCredential: any;
  }
}
