import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly DEFAULT_SNACKBAR_OPTIONS: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
  }

  private readonly MAX_LINES = 5
  private readonly TRUNCATION_INDICATOR = '...'

  constructor(
    private logger: NGXLogger,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  private truncateMessage(message: string): {
    truncated: string
    isLong: boolean
  } {
    const lines = message.split('\n')
    if (lines.length <= this.MAX_LINES) {
      return { truncated: message, isLong: false }
    }

    const truncated =
      lines.slice(0, this.MAX_LINES).join('\n') + this.TRUNCATION_INDICATOR
    return { truncated, isLong: true }
  }

  private show(
    message: string | string[],
    panelClass: string[],
    action = 'WFA.NOTIFICATION.CLOSE',
  ): void {
    const translatedMessage = Array.isArray(message)
      ? message.map(m => this.translateService.instant(m)).join(' ')
      : this.translateService.instant(message)
    const translatedAction = this.translateService.instant(action)

    const { truncated, isLong } = this.truncateMessage(translatedMessage)

    // Create the classes array conditionally
    const classes = ['c-snackbar', ...panelClass]
    if (isLong) {
      classes.push('c-snackbar--truncated')
    }

    this.snackBar.open(truncated, translatedAction, {
      ...this.DEFAULT_SNACKBAR_OPTIONS,
      panelClass: classes,
    })
  }

  log(message: string | string[]): void {
    this.logger.log(message)
    this.show(message, ['c-snackbar--log'])
  }

  warn(message: string | string[]): void {
    this.logger.warn(message)
    this.show(message, ['c-snackbar--warn'])
  }

  error(message: string | string[]): void {
    this.logger.error(message)
    this.show(message, ['c-snackbar--error'])
  }
}
