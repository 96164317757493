import { isNil } from './not-lodash/is-nil'

export interface WfaMessage {
  type: 'wfaMessage'
  message: string
}

export function isWfaMessage<T>(x: T | WfaMessage): x is WfaMessage {
  if (isNil(x)) return false
  return (
    (x as WfaMessage).type === 'wfaMessage' &&
    typeof (x as WfaMessage).message === 'string'
  )
}

export function newWfaMessage(message: string): WfaMessage {
  return {
    type: 'wfaMessage',
    message,
  }
}

export function printWfaMessage(w: WfaMessage): void {
  console.log(w.message)
}

export function explain(w: WfaMessage, details: string): WfaMessage {
  return {
    type: 'wfaMessage',
    message: `${details}. ${w.message}`,
  }
}

export function explainMissingParameter(
  w: WfaMessage,
  funcName: string,
  paramName: string,
  params: Record<string, unknown>,
): WfaMessage {
  const stringParams = JSON.stringify(params)

  return explain(
    w,
    `${funcName}: Parameter ${paramName} is missing in ${stringParams}`,
  )
}
