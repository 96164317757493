/**
 * Removes all elements from array that predicate returns truthy for
 * and returns an array of the removed elements.
 * The predicate is invoked with three arguments: (value, index, array).
 *
 * Unlike `filter`, this mutates the array.
 *
 * @param array The array to modify
 * @param predicate The function invoked per iteration
 * @returns Returns the new array of removed elements
 *
 * @example
 * const array = [1, 2, 3, 4]
 * const evens = remove(array, n => n % 2 === 0)
 * console.log(array) // => [1, 3]
 * console.log(evens) // => [2, 4]
 */
export function remove<T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => boolean,
): T[] {
  const removed: T[] = []

  // Iterate backwards to handle splicing safely
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i], i, array)) {
      // Remove the element and add it to our removed array
      // We use unshift instead of push because we're iterating backwards
      removed.unshift(array.splice(i, 1)[0])
    }
  }

  return removed
}
