import {
  CoreLocale,
  LocString,
  propertyNames as creatorPropertyNames,
} from '@arzt-direkt/wfa-definitions'
import { get, Nullable, isNil, set } from '@arzt-direkt/wfa-generic-utils'
import { Serializer, surveyLocalization } from 'survey-core'

type LocStringWithDefault = Required<Pick<LocString, 'default'>>
type LocalizableValue = Nullable<string | LocString | LocStringWithDefault>

/**
 * Properties in a JSON that are localized (`isLocalizable` is `true`) should always be specified as `LocString`.
 * This is currently only the case if the value for a property is set in multiple languages.
 * If the value is only available in one language, it is stored as a string.
 * However, the value cannot be parsed as a string either in the AD app or on the tomedo® server.
 * Both the app and the server can only interpret `LocString`s correctly.
 *
 * @param json JSON that is created by the editor
 */
export const localizeCreatorJson = (
  json: Record<string, unknown>,
  locale?: CoreLocale,
) => localizePropertyValues(json, locale)

/**
 * These properties come with `string` by default in their `choices` array.
 * In these choices, `string`s must be replaced by `LocString`s
 */
const PROPERTIES_WITH_ENFORCED_LOCALIZATION = [
  'checkbox',
  'dropdown',
  'radiogroup',
  'tagbox',
]

/**
 * This function goes through the list of all currently available editor properties and filters them according to their localizability.
 * @returns all properties that are localizable.
 */
function getLocalizableProperties(): string[] {
  const localizableProperties: string[] = creatorPropertyNames.flatMap(
    (name: string) =>
      Serializer.getAllPropertiesByName(name)
        .filter(
          prop =>
            prop.isLocalizable === true ||
            PROPERTIES_WITH_ENFORCED_LOCALIZATION.includes(prop.name),
        )
        .map(prop => prop.name),
  )
  return [...new Set(localizableProperties)]
}

/**
 * @param obj is mutated
 */
function localizePropertyValues(
  obj: Record<string, unknown>,
  locale?: CoreLocale,
): void {
  if (typeof obj !== 'object' || isNil(obj)) return
  if (Array.isArray(obj))
    obj.map(element => localizePropertyValues(element, locale))

  Object.entries(obj).forEach(([key, value]) => {
    if (localizableProperties.includes(key))
      obj[key] = generateLocString(value as LocalizableValue, locale)
    if (!localizableProperties.includes(key) && typeof value === 'object')
      localizePropertyValues(value as Record<string, unknown>, locale)
  })

  localizeChoices(obj, locale)
}

export interface MinLocalizableChoices {
  type: string
  choices: LocalizableValue[]
}

export function hasLocalizableChoices(
  obj: MinLocalizableChoices,
  type: string,
): boolean {
  return (
    obj.type === type &&
    get(obj, 'choices.length') > 0 &&
    obj.choices.some(choice => typeof choice === 'string')
  )
}

/**
 * @param obj is mutated
 */
function localizeChoices(
  _obj: Record<string, unknown>,
  locale?: CoreLocale,
): void {
  const obj = _obj as unknown as MinLocalizableChoices

  if (
    PROPERTIES_WITH_ENFORCED_LOCALIZATION.some(property =>
      hasLocalizableChoices(obj, property),
    )
  ) {
    const localizedChoices = obj.choices.map(choice => {
      if (typeof choice === 'string')
        return {
          value: choice,
          text: generateLocString(choice, locale),
        }
      return choice
    })
    set(obj, 'choices', localizedChoices)
  }
}

function generateLocString(
  value: LocalizableValue,
  locale?: CoreLocale,
): Nullable<LocString> {
  if (isNil(value)) return value
  if (typeof value === 'string') return { de: value }
  if (value.default)
    return replaceDefaultKey(value as LocStringWithDefault, locale)
  return value as LocString
}

function replaceDefaultKey(
  obj: LocStringWithDefault,
  locale?: CoreLocale,
): LocString {
  const localeKey = locale ?? surveyLocalization.defaultLocale
  const objWithLocaleKey = { [localeKey]: obj.default }
  const locString = obj as LocString
  delete locString.default
  return { ...objWithLocaleKey, ...locString }
}

export const localizableProperties = getLocalizableProperties()
