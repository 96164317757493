import Joi from 'joi'

import { TomedoVersion } from './../interfaces-autogen-from-joi/tomedo-version'
import { TomedoVersionJoi } from './tomedo-version.joi'

export function dummyGetInstance(
  version?: TomedoVersion,
  isInternalZollsoftSupportInstance?: boolean,
) {
  const tomedoVersion = version ?? process.env?.TOMEDO_VERSION ?? 'latest'
  return {
    tomedoVersion,
    settings: {
      general: {
        wfaInstanceSettings: {
          isInternalZollsoftSupportInstance:
            isInternalZollsoftSupportInstance ?? false,
        },
      },
    },
    // no default values
  }
}

export const WfaInstanceSliceJoi = Joi.object()
  .keys({
    tomedoVersion: TomedoVersionJoi,
    settings: Joi.object().keys({
      general: Joi.object().keys({
        wfaInstanceSettings: Joi.object().keys({
          isInternalZollsoftSupportInstance: Joi.boolean(),
        }),
      }),
    }),
  })
  .description(
    'A partial version of the Arzt-Direkt Instance Object. Is set optionally by the Arzt-Direkt frontend.',
  )
  .meta({ className: 'WfaInstanceSlice' })
