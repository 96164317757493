import {
  Id,
  PraxisId,
  updatePermalink,
  WfaForm,
  WfaFormId,
} from '@arzt-direkt/wfa-definitions'
import { curry, nonNil } from '@arzt-direkt/wfa-generic-utils'
import { combineLatest, EMPTY, Observable } from 'rxjs'
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators'

import {
  openEditWfaFormIdModal,
  updateInfos,
  ZsSupportService,
} from './zs-support.service'
import { ZsSupportCommsService } from './zs-support-comms.service'

function _logIfInconsistent(wfaFormVersionId: Id, wfaForm: WfaForm) {
  if (wfaForm.wfaFormVersionId !== wfaFormVersionId) {
    console.error(`
[copyForm]: expected wfaForm with wfaFormVersionId ${wfaFormVersionId},
got ${wfaForm.wfaFormVersionId} instead.

Inspect the database to check whether there is only one wfaForm with
wfaFormId ${wfaForm.wfaFormId} and praxisId ${wfaForm.praxisId}
that has status set to 'live'.`)
  }
}

type Log = (id: Id) => (form: WfaForm) => void
export const logIfInconsistent: Log = curry(_logIfInconsistent)
export function logErrorAndComplete(error: unknown) {
  console.error('[copyForm]: Error in operation chain:', error)
  // Return EMPTY to complete the observable without emitting
  return EMPTY
}

export function copyFormOrDraft(
  wfaFormVersionId: Id,
  wfaFormId: WfaFormId,
  praxisId: PraxisId,
  zsSupportService: ZsSupportService,
  comms: ZsSupportCommsService,
  formLoader: Observable<WfaForm>,
  formCreator: (form: WfaForm) => Observable<any>,
) {
  zsSupportService.setWfaFormId(wfaFormId)

  const formAndNewId: Observable<[WfaForm, WfaFormId]> = combineLatest([
    formLoader,
    openEditWfaFormIdModal(zsSupportService.editWfaFormIdModal),
  ])

  return formAndNewId.pipe(
    map(([wfaForm, id]) => updateWfaForm(wfaForm, id, praxisId)),
    tap(logIfInconsistent(wfaFormVersionId)),
    tap(() => zsSupportService.showSpinner$.next(true)),
    switchMap(formCreator),
    switchMap(() => comms.getFormAndDraftInfos(praxisId)),
    tap(updateInfos(zsSupportService)),
    catchError(logErrorAndComplete),
    finalize(() => zsSupportService.showSpinner$.next(false)),
  )
}

function updateWfaForm<T extends Pick<WfaForm, 'permalink'>>(
  wfaForm: T,
  wfaFormId: WfaFormId,
  praxisId: PraxisId,
): T {
  const newForm = {
    ...wfaForm,
    wfaFormId,
    praxisId,
  }

  const permalink = updatePermalink(wfaForm?.permalink, {
    wfaFormId,
  })
  if (nonNil(permalink)) return { ...newForm, permalink }

  return newForm
}
