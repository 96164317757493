<!-- errors -->
<div
  *ngIf="(isZwModeError || !doAppointmentTypesExist
  || (doesParamExistCategory && !appointmentCategoryGeneric && appointmentCategoriesNonGeneric?.length===0)) && !bookingService.currentlyLoading"
  class="error">
  <!-- isZwModeError -->
  <div *ngIf="isZwModeError">{{'OTK.BOOKING-TYPE.ERRORS.ZW-CODE-WRONG' | translate}}</div>
  <!-- invalid parameter or no openings -->
  <div
    *ngIf="!doAppointmentTypesExist || (doesParamExistCategory && !appointmentCategoryGeneric && appointmentCategoriesNonGeneric?.length===0)"
    class="title">
    {{ errorTitle }}
  </div>
  <span></span><img [src]="failureAssetUrl">
</div>
<!-- form -->
<form
  *ngIf="doAppointmentTypesExist && (appointmentTypesOfGenericAppointmentCategory || appointmentCategoriesNonGeneric)"
  [formGroup]="bookingTypeFormGroup" #form id="otk-scrollable-content--booking-type">
  <!-- generic booking category -->
  <mat-button-toggle-group [hideSingleSelectionIndicator]="true" #genericButtonToggleGroup="matButtonToggleGroup"
    Accordion="matAccordion" *ngIf="appointmentTypesOfGenericAppointmentCategory" formControlName="appointmentType"
    required>
    <!-- appointment types of the generic booking category -->
    <mat-button-toggle attr.data-selenium-id="mat-button-toggle-appointment-type-of-generic-booking-category-{{i}}"
      class="mat-button-toggle--appointment-type"
      *ngFor="let appointmentType of appointmentTypesOfGenericAppointmentCategory; let i = index"
      [disabled]="appointmentType.isDisabled"
      [ngClass]="bookingService.bookingTypeFormGroup.controls['appointmentType'].value===appointmentType ? 'mat-button-toggle--active':''"
      [value]="appointmentType" (click)="closeAllExpansionPanels()">
      <app-appointment-type [appointmentType]="appointmentType"></app-appointment-type>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <!-- all other booking categories -->
  <mat-accordion #appAccordion="matAccordion" *ngIf="appointmentCategoriesNonGeneric">
    <mat-expansion-panel attr.data-selenium-id="mat-expansion-panel-{{i}}" class="mat-elevation-z0"
      (opened)="appointmentCategoryNonGeneric.setValue(category)" (closed)="closeCategory(category)"
      [expanded]="areAllNonGenericCategoriesExpanded"
      *ngFor="let category of appointmentCategoriesNonGeneric; let i = index">
      <mat-expansion-panel-header>
        <!-- category name and description -->
        <mat-panel-title>
          <div class="name">{{category.name[baseLanguage] === bookingService.I18NString_MISSING_VALUE ?
            category.name[languageService.DEFAULT_BASE_LANG] : category.name[baseLanguage]}}
          </div>
          <div class="description" *ngIf="category.description[baseLanguage]">
            {{category.description | i18nString:'' }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- appointment types of the selected category -->
      <mat-panel-description *ngIf="appointmentTypesOfSelectedAppointmentCategory">
        <mat-button-toggle-group [hideSingleSelectionIndicator]="true" formControlName="appointmentType" required>
          <mat-button-toggle attr.data-selenium-id="mat-button-toggle-appointment-type-of-selected-category{{i}}"
            *ngFor="let appointmentType of appointmentTypesOfSelectedAppointmentCategory; let i = index"
            [disabled]="appointmentType.isDisabled"
            [ngClass]="bookingService.bookingTypeFormGroup.controls['appointmentType'].value===appointmentType ? 'mat-button-toggle--active':''"
            [value]="appointmentType">
            <app-appointment-type [appointmentType]="appointmentType"></app-appointment-type>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </mat-panel-description>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- contact form -->
  <!-- special feature for the support otk. Upon selection, skip booking-date -->
  <mat-button-toggle-group [hideSingleSelectionIndicator]="true" *ngIf="isContactFormActive">
    <mat-button-toggle attr.data-selenium-id="mat-button-toggle-contact-settings" (change)="onContactFormSelect()"
      [ngClass]="bookingService.isContactFormSelected$.value ? 'mat-button-toggle--active':''"
      [value]="contactSettings">
      <app-appointment-type [appointmentType]="appointmentTypeContactSettings"></app-appointment-type>
    </mat-button-toggle>
  </mat-button-toggle-group>
</form>