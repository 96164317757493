import Joi from 'joi'

import { isNil } from './not-lodash/is-nil'
import { newWfaMessage, WfaMessage } from './wfa-message'

type JoiSchema<T> =
  | Joi.ObjectSchema<T>
  | Joi.AlternativesSchema<T>
  | Joi.StringSchema

/**
 * Validates input against schema, returns error message if invalid
 * @deprecated Use `ensureType` instead
 */
export function validate<T>(
  joiSchema: JoiSchema<T>,
  x: unknown,
): T | WfaMessage {
  if (isNil(x)) return newWfaMessage(`[WFA] validate: nil input`)
  const { error } = joiSchema.validate(x)
  if (isNil(error)) return x as T
  return newWfaMessage(`[WFA] validate: ${error.message}`)
}

/** Type guard checking if input matches schema */
export function isValid<T>(joiSchema: JoiSchema<T>, x: unknown): x is T {
  if (isNil(x)) return false
  const { error } = joiSchema.validate(x)
  if (isNil(error)) return true
  return false
}

/** Creates validation utilities for a specific schema */
export function createVldUtils<T>(joiSchema: JoiSchema<T>) {
  return {
    isValid: (x: unknown) => isValid(joiSchema, x),
    ensureType: (x: unknown) => ensureType(joiSchema, x),
  }
}

/** Validates input against schema, throws error if invalid */
export function ensureType<T>(
  joiSchema: JoiSchema<T> | Joi.ArraySchema<T>,
  x: unknown,
): T {
  if (isNil(x)) throw new Error(`[ensureType]: validation failed: nil input`)
  const { error } = joiSchema.validate(x)
  if (error)
    throw new Error(
      `[ensureType] validation failed: ${error.message} for value ${JSON.stringify(x, null, 2)}`,
    )
  return x as T
}

/**
 * Validates input against schema, accepting either a single value or an array of values
 * @param joiSchema - The Joi schema to validate against
 * @param x - Input value to validate
 * @returns The validated input as an array
 * @throws Error if validation fails
 */
export function ensureArray<T>(joiSchema: JoiSchema<T>, x: unknown): T | T[] {
  const arraySchema: Joi.ArraySchema<T[]> = Joi.array().items(joiSchema)
  return ensureType(arraySchema, x) as T[]
}
