import { Router } from '@angular/router'
import {
  AdisEditorInput,
  getGrandparentUrl,
  getParentUrl,
  WfaFormDraft,
} from '@arzt-direkt/wfa-definitions'

import { WfaEnvService } from '../../../environments/wfa-env.service'

export function navigateToAdisEditor(
  wfaFormDraft: WfaFormDraft,
  router: Router,
  _wfaEnvService: WfaEnvService,
) {
  const adisEditorInput: AdisEditorInput = {
    wfaFormDraft,
  }

  const splitUrl = router.url.split('/')
  const secondToLast = splitUrl[splitUrl.length - 2]
  const isRedirect = secondToLast === 'editor'

  const url = isRedirect
    ? `${getGrandparentUrl(router.url)}/wfa/adis-editor`
    : `${getParentUrl(router.url)}/wfa/adis-editor`
  router.navigateByUrl(url, { state: { adisEditorInput } })
}
