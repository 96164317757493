import 'survey-creator-core/survey-creator-core.i18n'

import { Clipboard } from '@angular/cdk/clipboard'
import { MatDialog } from '@angular/material/dialog'
import { SurveyCreatorModel } from 'survey-creator-core'

import { addCustomQuestionTypes } from '../shared-surveyjs/question-type-customization/add-custom-question-types'
import { combineCreatorOptions } from './customization/combine-creator-options'
import { customize } from './customization/customize'
import { addCustomIcons } from './customization/toolbox-customization/customize-icons/add-custom-icons'
import { EditorDataService } from './editor.data-service'
import { EditorStore } from './editor.store'
import { Routing } from './interfaces'
import { saveToStoreAndDb } from './save-to-store-and-db'
import { SetCreatorParams } from './set-creator-params'
import { TomedoVersion } from '@arzt-direkt/wfa-definitions'
import { addCustomFunctions } from '../shared-surveyjs/expression-function-customization/add-custom-functions'

export function createCreator(
  editorStore: EditorStore,
  scp: SetCreatorParams,
  dialog: MatDialog,
  editorDataService: EditorDataService,
  routing: Routing,
  clipboard: Clipboard,
) {
  const tomedoVersion = routing.wfaEnvService.getInstance()?.tomedoVersion
  addCustomQuestionTypes(tomedoVersion)
  addCustomIcons()
  addCustomFunctions()

  const licenseTier = scp?.editorEnv?.licenseTier ?? 'basic'
  const options = combineCreatorOptions(licenseTier)

  const creator = new SurveyCreatorModel(options)
  creator.JSON = scp?.json

  creator.saveSurveyFunc = saveToStoreAndDb(
    editorStore,
    creator,
    editorDataService,
    routing.wfaEnvService,
    clipboard,
  )

  customize(creator, dialog, routing)
  creator.showSaveButton = false

  return creator
}
