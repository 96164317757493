import { SurveyCreatorModel } from 'survey-creator-core'

export function generateUniqueQuestionName(
  creator: SurveyCreatorModel,
): string {
  const questions = creator.survey.getAllQuestions()
  const questionNames = questions.map(question => question.name)

  let index = 1
  let newName: string
  do newName = `question${index++}`
  while (questionNames.includes(newName))

  return newName
}
