import { Clipboard } from '@angular/cdk/clipboard'
import {
  ensureWfaForm,
  WfaForm,
  WfaFormDraftUpdateQueryResultJoi,
} from '@arzt-direkt/wfa-definitions'
import { cloneDeep, ensureType } from '@arzt-direkt/wfa-generic-utils'
import { of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { SurveyCreatorModel } from 'survey-creator-core'

import { WfaEnv } from '../../environments/wfa-env'
import { customizeCreatorJson } from './customization/creator-json-customization/customize-creator-json'
import {
  addFormValidationWarning,
  removeFormValidationWarning,
} from './customization/toolbox-customization/customize-toolbox-actions/add-toolbox-action-form-validation-warning'
import { EditorDataService } from './editor.data-service'
import { EditorStore } from './editor.store'

// see https://surveyjs.io/survey-creator/documentation/get-started-angular
// section `Save and Load Survey Model Schemas`
type Callback = (
  currentChangeNumber: number,
  serverHasAppliedChange: boolean,
) => unknown

export function saveToStoreAndDb(
  store: EditorStore,
  creator: SurveyCreatorModel,
  editorDataService: EditorDataService,
  wfaEnv: WfaEnv,
  clipboard: Clipboard,
) {
  return (saveNo: number, callback: Callback) => {
    const json = addWfaData(cloneDeep(creator.JSON)) as WfaForm
    customizeCreatorJson(json)
    store.setForm(json)

    try {
      ensureWfaForm(json)
      removeFormValidationWarning(creator)
    } catch (error: any) {
      addFormValidationWarning(creator, wfaEnv, clipboard)
      if (error.message.includes('names within the form must be unique')) {
        wfaEnv.notification.warn([
          `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.VALIDATE-WFA-FORM-ERROR`,
          `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.VALIDATE-WFA-FORM-NAME-UNIQUE`,
        ])
      } else {
        wfaEnv.notification.warn([
          `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.VALIDATE-WFA-FORM-ERROR`,
          error.message,
        ])
      }
    }

    const possiblyInvalidWfaForm = json as WfaForm

    store.setFormIsValid(possiblyInvalidWfaForm)

    editorDataService
      .wfaUpdateWfaFormDraft(possiblyInvalidWfaForm)
      .pipe(
        catchError(err => {
          wfaEnv.notification.error([
            `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.HTTP-CONNECTION-ERROR`,
          ])
          console.error('HTTP request error:', err)
          callback(saveNo, false)
          return of({ message: 'Error occurred, fallback data' })
        }),
      )
      .subscribe((res: any) => {
        try {
          ensureType(WfaFormDraftUpdateQueryResultJoi, res?.queryResult)
        } catch (error: any) {
          wfaEnv.notification.error([
            `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.HTTP-RESPONSE-ERROR`,
          ])
          console.error('HTTP result mismatch type: ', error.message)
          callback(saveNo, false)
          return
        }

        callback(saveNo, true)
      })
  }
}

function addWfaData(maybeForm: Partial<WfaForm>): WfaForm {
  return {
    ...maybeForm,
    _id: maybeForm.wfaFormVersionId,
    status: 'live',
  } as unknown as WfaForm
}
