import { hasStringKeys } from '@arzt-direkt/wfa-generic-utils'

import {
  ExtractedParams,
  PermaLinkParams,
  SingleOrMultilinkParams,
} from '../single-or-multilink.types'

export function isSingleOrMultilinkParams(
  x: unknown,
): x is SingleOrMultilinkParams {
  return hasStringKeys(x, ['responseId'])
}

export function isPermaLinkParams(x: unknown): x is PermaLinkParams {
  return hasStringKeys(x, ['instanceIdentifier', 'wfaFormId'])
}

export function extractRouteParams(x: unknown): ExtractedParams {
  if (isSingleOrMultilinkParams(x)) {
    return { responseId: x.responseId, type: 'singleOrMultilink' }
  }

  if (isPermaLinkParams(x)) {
    return {
      type: 'permalink',
      instanceIdentifier: x.instanceIdentifier,
      wfaFormId: x.wfaFormId,
    }
  }

  throw new Error(
    [
      `[extractRouteParams]: could not pass route parameters`,
      `to retrieve survey from backend. Got parameters:`,
      JSON.stringify(x, null, 2),
    ].join(' '),
  )
}
