import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  Component,
  ElementRef,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RxLet } from '@rx-angular/template/let'
import { debounceTime, fromEvent } from 'rxjs'

import { SingleOrMultilinkStore as Store } from './../../adis/viewer-from-link/single-or-multilink/single-or-multilink.store'
import { I18nPipe } from './../../utility/i18n.pipe'

@UntilDestroy()
@Component({
  selector: 'wfa-scrollable-sidebar',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatIconModule, I18nPipe, RxLet],
  templateUrl: './scrollable-sidebar.component.html',
  styleUrls: ['./scrollable-sidebar.component.scss'],
})
export class ScrollableSidebarComponent implements AfterViewInit {
  @ViewChild('sidebarNav') sidebarNav!: ElementRef
  @ViewChild('sidebarList') sidebarList!: ElementRef

  isMobileView = false
  isScrolledToStart = true
  isScrolledToEnd = false

  constructor(public store: Store) {
    this.checkMobileView()
    fromEvent(window, 'resize')
      .pipe(debounceTime(200), untilDestroyed(this))
      .subscribe(() => {
        this.checkMobileView()
        this.updateScrollButtons()
      })
  }

  private checkMobileView() {
    const MOBILE_BREAKPOINT = 768
    this.isMobileView = window.innerWidth <= MOBILE_BREAKPOINT
  }

  ngAfterViewInit() {
    if (this.sidebarList) {
      fromEvent(this.sidebarNav.nativeElement, 'scroll')
        .pipe(debounceTime(50), untilDestroyed(this))
        .subscribe(() => this.updateScrollButtons())
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items'] && this.sidebarList) {
      // Wait for next tick to ensure DOM is updated
      setTimeout(() => {
        this.updateScrollButtons()
      })
    }
  }

  updateScrollButtons() {
    const nav = this.sidebarNav.nativeElement
    const list = this.sidebarList.nativeElement
    // Check if there's actually scrollable content
    const hasScrollableContent = list.scrollWidth > nav.clientWidth

    if (!hasScrollableContent) {
      this.isScrolledToStart = true
      this.isScrolledToEnd = true
      return
    }

    const scrollPosition = nav.scrollLeft
    const maxScroll = list.scrollWidth - nav.clientWidth

    this.isScrolledToStart = scrollPosition <= 0
    this.isScrolledToEnd = Math.abs(maxScroll - scrollPosition) < 1
  }

  test() {
    this.updateScrollButtons()
  }

  scrollSidebar(direction: 'left' | 'right') {
    const nav = this.sidebarNav.nativeElement
    const scrollAmount = nav.clientWidth * 0.8

    if (direction === 'left') {
      nav.scrollBy({ left: -scrollAmount, behavior: 'smooth' })
    } else {
      nav.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    }
  }
}
