import { Route } from '@angular/router'

import { ViewerFromLinkErrorComponent } from '../adis/viewer-from-link-error/viewer-from-link-error.component'
import { SingleOrMultilinkComponent } from './../adis/viewer-from-link/single-or-multilink/single-or-multilink.component'
import {
  aboutRoute,
  adisEditorRoute,
  fallbackRoute,
  supportEditorRoute,
  userMessageRoute,
} from './shared.routes'

const permalinkRoute: Route = {
  path: 'permalink/:instanceIdentifier/:wfaFormId',
  title: 'Patientenformulare | Online ausfüllen',
  component: SingleOrMultilinkComponent,
}

const viewerFromLinkRoute: Route = {
  path: 'viewer-from-link/:responseId',
  title: 'Patientenformulare | Online ausfüllen',
  component: SingleOrMultilinkComponent,
}

const viewerFromLinkErrorRoute = {
  path: 'viewer-from-link-error',
  title: 'Patientenformulare | Fehler',
  component: ViewerFromLinkErrorComponent,
}

export const adisRoutes: Route[] = [
  aboutRoute,
  viewerFromLinkRoute,
  viewerFromLinkErrorRoute,
  permalinkRoute,
  userMessageRoute,
  fallbackRoute,
]

// routes that are visible in adis for instances (require log in)
export const adisInstanceRoutes: Route[] = [
  adisEditorRoute,
  supportEditorRoute,
  fallbackRoute,
]

export const adisInstanceSettingsRoutes: Route[] = [adisEditorRoute]
