import { CoreLocale } from '@arzt-direkt/wfa-definitions'
import { initialEditorState } from '../../../editor.state'

const defaultLocale = initialEditorState.editorEnv.locale

const duplicateValueMessage = {
  de: 'Fehler: dieser Wert wird bereits verwendet.',
  en: 'Error: this value is already in use.',
  es: 'Error: este valor ya está en uso.',
  fr: 'Erreur : cette valeur est déjà utilisée.',
  it: 'Errore: questo valore è già in uso.',
}

const containsSpaceMessage = {
  de: 'Fehler: dieser Wert darf keine Leerzeichen enthalten.',
  en: 'Error: this value must not contain spaces.',
  es: 'Error: este valor no debe contener espacios.',
  fr: 'Erreur : cette valeur ne doit pas contenir d’espaces.',
  it: 'Errore: questo valore non deve contenere spazi.',
}

const containsMultiSpaceMessage = {
  de: 'Fehler: dieser Wert darf höchstens ein Leerzeichen zwischen zwei Wörtern enthalten.',
  en: 'Error: this value may contain at most one space between two words.',
  es: 'Error: este valor puede contener como máximo un espacio entre dos palabras.',
  fr: 'Erreur: cette valeur peut contenir au maximum un espace entre deux mots.',
  it: 'Errore: questo valore può contenere al massimo uno spazio tra due parole.',
}

export function getLocalizedDuplicateValueMessage(locale: CoreLocale): string {
  return duplicateValueMessage[locale] ?? duplicateValueMessage[defaultLocale]
}

export function getLocalizedContainsSpacesMessage(locale: CoreLocale): string {
  return containsSpaceMessage[locale] ?? containsSpaceMessage[defaultLocale]
}

export function getLocalizedContainsMultiSpacesMessage(
  locale: CoreLocale,
): string {
  return (
    containsMultiSpaceMessage[locale] ??
    containsMultiSpaceMessage[defaultLocale]
  )
}
