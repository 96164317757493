import { SurveyCreatorModel } from 'survey-creator-core'

import { updatePropertiesWithInputType } from './update-properties-with-input-type'
import { updatePropertiesWithWfaKey } from './update-properties-with-wfa-key'
import { updatePropertiesWithShortTitle } from './update-properties-with-short-title'

export function updatePropertiesOnValueChange(
  creator: SurveyCreatorModel,
): void {
  creator.onPropertyValueChanging.add((creator, options) => {
    const propertyName = options.propertyName
    if (propertyName === 'wfaKey') updatePropertiesWithWfaKey(creator, options)
    if (propertyName === 'inputType') updatePropertiesWithInputType(options)
    if (propertyName === 'shortTitle') updatePropertiesWithShortTitle(creator, options)
  })
}
