import { CustomPropertyInfo } from './../../../../interfaces-autogen-from-joi/custom-property-info'

const groupId: CustomPropertyInfo = {
  name: 'groupId',
  type: 'string',
  visible: false,
}

const surveyElements = ['survey']

export const surveyElementsWithGroupId = surveyElements.reduce(
  (acc, item) => {
    acc[item] = groupId
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
