console.log('catbug main/top-of-the-file')

import { LOCALE_ID, NgZone, enableProdMode, importProvidersFrom } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app/app-routing.module';
import { FormFieldsModule } from './app/forms/form-fields.module';
import { DialectTranslationService } from './app/i18n/dialect-translation.service';
import { IconsModule } from './app/icons.module';
import { HtmlEditButton } from './app/rich-text-editor/htmlEditButton';
import { AdisWfaEnvSPModule } from './app/wfr/wfa/adis-wfa-env.module';
import { environment } from './environments/environment';

import { AppComponent } from '@a-d/app.component';
import { BookingStandaloneComponent } from '@a-d/booking/booking-standalone/booking-standalone.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule, Title, createApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PureAbility } from '@casl/ability';
import { DEFAULT_CONFIG, Driver, NgForageOptions } from 'ngforage';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { SocketIoModule } from 'ngx-socket-io';
import { DsgvoModule } from './app/dsgvo/dsgvo.module';
import { AppAbility } from './app/entities/Ability.entity';
import { MatPaginatorIntlDe } from './app/misc/paginator-intl';
import { SessionRecoveryInterceptor } from './app/session-recovery.interceptor';
import { SharedModule } from './app/shared.module';
import { ThemingModule } from './app/theming/theming.module';
import { WfaModule } from './app/wfr/wfa/wfa.module';
import { ADNGXLoggerWriterService } from './logger/a-d-ngx-logger-writer-service';
import { ConsoleMethods, MainFunctions } from './main-functions';

const defaultLogLevel = (environment.production) ? NgxLoggerLevel.ERROR : NgxLoggerLevel.INFO

console.log('catbug main/after-all-the-imports')

/**
 * Methods from console that will be disabled for a production build
 * Console output might give away sensitive information from the DB, therefore
 * disable some of this output in production builds.
 */
const disabledConsoleMethods: ConsoleMethods = {
    debug: true,
    info: true
}



if (environment.production) {
    enableProdMode();
}


// disable certain console output in 'stable' but not in 'testing'
if (environment.production && !environment.demoMode) {
    MainFunctions.disableConsoleMethodsInProduction(disabledConsoleMethods);
}


(async () => {
    console.log('catbug main/before await createApplication')
    const app = await createApplication({
        providers: [
            importProvidersFrom(
                DsgvoModule,
                BrowserModule,
                SocketIoModule,
                SharedModule,
                ThemingModule,
                FormFieldsModule,
                AppRoutingModule,
                HighlightModule,
                ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
                QuillModule.forRoot({
                    customModules: [{
                        implementation: HtmlEditButton,
                        path: 'modules/htmlEditButton'
                    }]
                }),
                TranslateModule.forRoot({
                    loader: {
                        provide: TranslateLoader,
                        useClass: DialectTranslationService,
                    }
                }),
                LoggerModule.forRoot({
                    serverLoggingUrl: '/api/logs/angular',
                    level: defaultLogLevel,
                    enableSourceMaps: true,
                    serverLogLevel: NgxLoggerLevel.ERROR,
                }, {
                    writerProvider: {
                        provide: TOKEN_LOGGER_WRITER_SERVICE, useClass: ADNGXLoggerWriterService
                    }
                }),
                IconsModule,
                WfaModule,
                AdisWfaEnvSPModule,
            ),
            Title,
            {
                provide: LOCALE_ID,
                useValue: 'de-DE'
            }, {
                provide: MAT_DIALOG_DEFAULT_OPTIONS,
                useValue: { width: '30rem', panelClass: 'c-dialog', hasBackdrop: true }
            }, {
                provide: STEPPER_GLOBAL_OPTIONS,
                useValue: { showError: true }
            }, {
                provide: MAT_RIPPLE_GLOBAL_OPTIONS,
                useValue: { disabled: true }
            }, {
                provide: MatPaginatorIntl,
                useClass: MatPaginatorIntlDe
            }, {
                provide: DEFAULT_CONFIG,
                useValue: {
                    name: 'arzt-direkt',
                    driver: [
                        Driver.INDEXED_DB,
                        Driver.LOCAL_STORAGE
                    ]
                } as NgForageOptions
            }, {
                provide: HTTP_INTERCEPTORS,
                useClass: SessionRecoveryInterceptor,
                multi: true
            },
            {
                provide: HIGHLIGHT_OPTIONS,
                useValue: {
                    coreLibraryLoader: () => import('highlight.js/lib/core'),
                    lineNumbersLoader: () => import('highlightjs-line-numbers.js'),
                    languages: {
                        xml: () => import('highlight.js/lib/languages/xml')
                    }
                }
            },
            { provide: AppAbility, useValue: new AppAbility() },
            { provide: PureAbility, useExisting: AppAbility },
            provideAnimations(),
            provideHttpClient(withInterceptorsFromDi(), withXsrfConfiguration({ cookieName: 'XSRF-TOKEN', headerName: 'X-XSRF-TOKEN' })),
        ],
    })
    /* console.info('HOST', window.location.host)
    console.info('HOST-name', window.location.hostname)
    console.info('HOST-href', window.location.href)
    console.info('ENV', environment.url) */
    console.log('catbug main/before bootstrapping', window.location.href, environment)
    if (window.location.href.includes(environment.url)) {
        /* console.info('HOST', window.location.host)
        console.info('HOST-name', window.location.hostname) */
        app.injector.get(NgZone).run(() => {
            console.log('catbug main/app.bootstrap')
            app.bootstrap(AppComponent);
        });
    }


    const mainElement = createCustomElement(AppComponent, {
        injector: app.injector
    })

    customElements.define('app-standalone-main', mainElement)

    const bookingEmbedElement = createCustomElement(BookingStandaloneComponent, {
        injector: app.injector
    })

    customElements.define('arzt-direkt-booking', bookingEmbedElement)
})();

// bootstrapApplication(AppComponent, {
//     providers: [
//         importProvidersFrom(DsgvoModule, BrowserModule, SocketIoModule, SharedModule, ThemingModule, FormFieldsModule, AppRoutingModule, HighlightModule, ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }), QuillModule.forRoot({
//             customModules: [{
//                 implementation: HtmlEditButton,
//                 path: 'modules/htmlEditButton'
//             }]
//         }), TranslateModule.forRoot({
//             loader: {
//                 provide: TranslateLoader,
//                 useClass: DialectTranslationService,
//             }
//         }), LoggerModule.forRoot({
//             serverLoggingUrl: '/api/logs/angular',
//             level: defaultLogLevel,
//             serverLogLevel: NgxLoggerLevel.ERROR
//         }), IconsModule, WfaModule, StoreModule.forRoot(reducers, {
//             initialState,
//             metaReducers,
//         })),
//         Title,
//         {
//             provide: LOCALE_ID,
//             useValue: 'de-DE'
//         }, {
//             provide: MAT_DIALOG_DEFAULT_OPTIONS,
//             useValue: { width: '30rem', panelClass: 'c-dialog', hasBackdrop: true }
//         }, {
//             provide: STEPPER_GLOBAL_OPTIONS,
//             useValue: { showError: true }
//         }, {
//             provide: MAT_RIPPLE_GLOBAL_OPTIONS,
//             useValue: { disabled: true }
//         }, {
//             provide: MatPaginatorIntl,
//             useClass: MatPaginatorIntlDe
//         }, {
//             provide: DEFAULT_CONFIG,
//             useValue: {
//                 name: 'arzt-direkt',
//                 driver: [
//                     Driver.INDEXED_DB,
//                     Driver.LOCAL_STORAGE
//                 ]
//             } as NgForageOptions
//         }, {
//             provide: HTTP_INTERCEPTORS,
//             useClass: SessionRecoveryInterceptor,
//             multi: true
//         },
//         {
//             provide: HIGHLIGHT_OPTIONS,
//             useValue: {
//                 coreLibraryLoader: () => import('highlight.js/lib/core'),
//                 lineNumbersLoader: () => import('highlightjs-line-numbers.js'),
//                 languages: {
//                     xml: () => import('highlight.js/lib/languages/xml')
//                 }
//             }
//         },
//         { provide: AppAbility, useValue: new AppAbility() },
//         { provide: PureAbility, useExisting: AppAbility },
//         provideAnimations(),
//         provideHttpClient(withInterceptorsFromDi())
//     ]
// }).catch(err => console.log(err));
