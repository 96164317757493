import { CustomPropertyInfo, TomedoVersion } from '@arzt-direkt/wfa-definitions'
import { nonNil } from '@arzt-direkt/wfa-generic-utils'
import { IJsonPropertyInfo, Serializer } from 'survey-core'
import { PropertyGridEditorCollection } from 'survey-creator-core'

import { Dictionary } from '../../../types/dictionary'
import {
  customEditorProperties,
  customViewerProperties,
} from './_custom-properties.barrel'

function addCustomProperties(
  customPropertiesDictionary: Dictionary<CustomPropertyInfo[]>,
): void {
  resetPropertyGrid()
  Object.entries(customPropertiesDictionary).forEach(
    ([surveyjsClass, propertiesInfo]) => {
      Serializer.addProperties(
        surveyjsClass,
        propertiesInfo as IJsonPropertyInfo[],
      )
      customizePropertyDisplaySettings(propertiesInfo)
    },
  )
}

function resetPropertyGrid() {
  PropertyGridEditorCollection.clearHash()
  Serializer.removeProperty('itemValue', 'score')
}

function customizePropertyDisplaySettings(
  propertiesInfo: CustomPropertyInfo[],
) {
  propertiesInfo.forEach(propertyInfo => {
    const displaySettings = propertyInfo.editorDisplaySettings
    if (nonNil(displaySettings))
      PropertyGridEditorCollection.register(displaySettings)
  })
}

export function addCustomEditorProperties(tomedoVersion?: TomedoVersion) {
  addCustomProperties(customEditorProperties(tomedoVersion))
}

export const addCustomViewerProperties = () =>
  addCustomProperties(customViewerProperties)
