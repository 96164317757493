import { InstanceService } from '@a-d/instance/instance.service';
import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InstanceModule } from './../entities/Instance.entity';
import { OtkUserService } from './otkuser.service';


/**
 * Guard which ensures that the OtkUserService has fetched a user from the database.
 */
@Injectable({
  providedIn: 'root'
})
export class OtkUserGuard {
  constructor(
    private adLoggerService: AdLoggerService,
    private instanceService: InstanceService,
    private otkUserService: OtkUserService,
    private logger: NGXLogger
  ) { }

  canActivate(_1: ActivatedRouteSnapshot, _2: RouterStateSnapshot) {

    // fetch otkuser only when needed (module otk is enabled for the instance)
    if (!this.instanceService.activeInstance?.modules?.includes(InstanceModule.OTK) && !this.instanceService.customInstance?.modules?.includes(InstanceModule.OTK)) {
      this.logger.info("Not loading otkuser as no otk module is enabled for this instance")
      return of(true)
    }

    const instanceId = this.instanceService.activeInstanceIsAdmin ? this.instanceService.customInstance._id : this.instanceService.activeInstance._id
    return this.otkUserService.loadOtkUser(instanceId).pipe(
      map((_) => true),
      catchError((error) => {
        this.adLoggerService.error("Error fetching OtkUser; continue navigation", error);
        return of(true);
      })
    )
  }
}
