/**
 * Generates a valid MongoDB ObjectId.
 *
 * This function can be used in Angular Frontend for
 * ahead-of-type id generation, without the need to
 * importing anything from mongoose.
 *
 * Takes optional date parameter, defaults to current date
 *
 * MongoDB ObjectId Structure (24 characters total):
 * - First 8 chars (4 bytes): Timestamp
 * - Next 6 chars (3 bytes): Machine ID
 * - Next 4 chars (2 bytes): Process ID
 * - Last 6 chars (3 bytes): Counter
 *
 * See https://www.mongodb.com/docs/manual/reference/method/ObjectId/
 *
 * @returns A string representation of a MongoDB ObjectId
 */
export function generateObjectId(date: Date = new Date()): string {
  // 1. Timestamp (8 characters)
  const timestamp = Math.floor(date.valueOf() / 1000).toString(16)
  // Converts date to Unix timestamp (seconds since epoch)
  // Then converts to hexadecimal

  // 2. Machine ID (6 characters)
  const machineId = Math.floor(Math.random() * 16777215) // 2^24-1
    .toString(16)
    .padStart(6, '0')
  // Generates random number up to 24 bits (2^24-1)
  // Converts to hex and ensures 6 characters with leading zeros

  // 3. Process ID (4 characters)
  const processId = Math.floor(Math.random() * 65535) // 2^16-1
    .toString(16)
    .padStart(4, '0')
  // Generates random number up to 16 bits (2^16-1)
  // Converts to hex and ensures 4 characters with leading zeros

  // 4. Counter (6 characters)
  const counter = Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')
  // Similar to machineId, generates random 24-bit number
  // Converts to hex and ensures 6 characters

  // Concatenates all parts to create 24-character ObjectId
  return timestamp + machineId + processId + counter
}
