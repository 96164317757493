import { FormArray, FormControl, FormGroup } from '@angular/forms';

export enum CountryCode {
  DE = 'DE',
  AT = 'AT',
  CH = 'CH'
}

export enum StoreTypes {
  global = 'global',
  betriebsstaette = 'bs',
  appointmentType = 'oat'
}

export enum CountryName {
  DE = 'Deutschland',
  AT = 'Österreich',
  CH = 'Schweiz'
}

export enum LegalEntityType {
  individual = 'individual',
  organization = 'organization',
  soleProprietor = 'soleProprietorship',
}

export enum TransactionChannel {
  Android = 'Android',
  iOS = 'iOS',
  Web = 'Web',
}

export interface PaymentAmount {
  currency: string    // three letter iso currency code EUR, USD etc.
  value: number       // transaction amount in minor units eg cent
}

export enum PaymentResultCode {
  refused = 'Refused',
  cancelled = 'Cancelled',
  error = 'Error',
  authorised = 'Authorised'
}

export enum PaymentStatus {
  authorised = 'authorised',
  canceled = 'canceled',
  captured = 'captured',
  booked = 'booked',
  chargeback = 'chargeback',
  refunded = 'refunded'
}

export interface FraudResult {
  accountScore?: number
  checkId?: number
  name?: string
}

export interface FraudSchema {
  accountScore: number
  results: FraudResult[]
}

export interface PaymentAuthoriseOrder {
  amount: PaymentAmount
  expiresAt: string
  orderData: string
  pspReference: string
  reference: string
  remainingAmount: PaymentAmount
}
export interface PaymentAuthorise {
  action: any
  additionalData?: any
  amount: PaymentAmount
  fraudResult?: FraudSchema
  merchantReference?: string
  order?: PaymentAuthoriseOrder
  paymentMethod: PaymentMethod
  pspReference: string
  status: string
  refusalReason?: string
  refusalReasonCode?: string
  resultcode?: string
  threeDS2ResponseData?: any
  threeDSPaymentData?: string
  createdAt: Date
  updatedAt: Date
}

export interface PaymentCapture {
  amount: PaymentAmount
  merchantAccount?: string
  pspReference: string
  paymentPspReference: string
  platformChargebackLogic?: any
  reference?: string
  splits?: any
  status?: string
  createdAt: Date
  updatedAt: Date
}

export interface PaymentStatusEntry {
  status: PaymentStatus
  date: Date
}

export enum RefundStatus {
  requested = 'requested',
  completed = 'completed',
  denied = 'denied',
  none = 'none'
}

export interface PaymentDocument {
  _id: string
  instance: string
  authorise: PaymentAuthorise
  capture: PaymentCapture
  storeId: string
  statusHistory: PaymentStatusEntry[]
  type: string
  refundStatus: RefundStatus
  createdAt: Date
  updatedAt: Date
}

export interface PaymentData {
  payments: PaymentDocument[]
  pageCount: number
  documentCount: number
}

export interface PaymentOverviewResponse {
  message: string
  status: number
  paymentData: PaymentData
}

export interface TransactionAmount {
  currency: string
  sellerSplit: number
  fixedFee: number
  variableFee: number
  paymentFee: number
}

export interface TransactionLifeCycle {
  received: Date
  authorised: Date
  captured: Date
  booked: Date
  fee: Date
  value: Date
  refunded: Date
  chargeback: Date
}

export interface Transaction {
  amount: TransactionAmount
  balanceAccount: string
  balanceAccountName: string
  billingNumber?: string
  instance: string
  lifeCycle?: TransactionLifeCycle
  patientName?: string
  patientNumber?: string
  pspReference: string
  type: string
  dueDate?: Date
  counterpartyIban?: string
  counterpartyBic?: string
}

// transaction table row data
export interface TransactionEntry {
  pspReference: string
  amount: PaymentAmount
  status: PaymentStatus
  balanceAccountName: string
  billingNumber: string
  patientNumber: string
  patientName: string
  valueDate?: string
  iban?: string
  bic?: string
}

export interface TransactionData {
  transactions: Transaction[]
  pageCount: number
  documentCount: number
}

export interface ReportTransactionData {
  transactions: string[]
  start: Date
  end: Date
}

export interface TransactionOverviewResponse {
  message: string
  status: number
  transactionData: TransactionData
}

export interface TransactionDownloadResponse {
  message: string
  status: number
  reportData: ReportTransactionData
}

export interface AllTransactionSums {
  _id: string,
  instanceCountry?: string,
  instanceIdentifier?: string,
  customerId?: number,
  transactions: number,
  currency: string,
  sellerSplitSum: number,
  feeSum: number,
  adyenFeeSum: number,
  zollsoftFeeSum: number,
  bankTransferSum: number
}

// admin transaction table row data
export interface AllTransactionSumsEntry {
  instanceId: string,
  instanceCountry?: string,
  instanceIdentifier?: string,
  customerId?: number,
  transactions: number,
  currency: string,
  transactionSum: number,
  sellerSplitSum: number,
  feeSum: number,
  feeSumTurnoverTax: number,
  adyenFeeSum: number,
  zollsoftFeeSum: number,
  bankTransferSum: number
}

export interface AllTransactionSumsData {
  transactionsData: AllTransactionSums[]
  pageCount: number
  documentCount: number
}

export interface TransactionAdminOverviewResponse {
  message: string
  status: number
  data: AllTransactionSumsData
}

export interface ReportAllTransactionSumsData {
  reportData: string[]
  start: Date
  end: Date
}

export interface TransactionAdminDownloadResponse {
  message: string
  status: number
  data: ReportAllTransactionSumsData
}

export interface PaymentInfo {
  paymentId: string
  amount: PaymentAmount
  pspReference: string
}

export enum DeferredStatusType {
  pending = 'pending',
  paid = 'paid'
}

export enum PaymentMethodDeferred {
  Online_Banking = 'paybybank'
}

export interface DeferredStatus {
  status: DeferredStatusType
  date: Date
}

export interface PaymentDeferred {
  accessCode: string
  amount: PaymentAmount
  appointment: string
  currentState: DeferredStatusType
  expiration: Date
  instance: string
  statusHistory: DeferredStatus[]
  storeId: string
  type: PaymentMethodDeferred
}

export interface PaymentInfoDeferred {
  amount?: PaymentAmount
  type: PaymentMethodTypes
  paymentPendingId?: string
  storeId: string
}

export interface PaymentEntry {
  _id: string
  type: string
  amount: PaymentAmount
  pspReference: string
  date: string
  status: PaymentStatus
  statusName: string
  refundStatus: RefundStatus
}

export interface RefundData {
  type: string
  pspReference: string
  merchantRefundReason: string
  amount: PaymentAmount
}

export enum ADPayEnvironment {
  test = 'test',
  live = 'live'
}

export enum RegistrationStatus {
  pending = 'pending',
  setup = 'setup',
  onboarding = 'onboarding',
  verified = 'verified',
  completed = 'completed'
}

export enum AssociationType {
  soleProprietorship = 'soleProprietorship',
  uboThroughOwnership = 'uboThroughOwnership',
  uboThroughControl = 'uboThroughControl',
  director = 'director',
  signatory = 'signatory',
  ultimateParentCompany = 'ultimateParentCompany'
}

export interface EntityAssociationForm {
  jobTitle?: FormControl<string>
  legalEntityId: FormControl<string>
  type: FormControl<AssociationType>
}

export interface EntityAssociation {
  jobTitle?: string
  legalEntityId: string
  type: AssociationType
}


export interface AdyenName {
  firstName: string
  lastName: string
  infix?: string
}
export interface AdyenNameForm {
  firstName: FormControl<string>
  lastName: FormControl<string>
  infix?: FormControl<string>
}

export interface AdyenBirthDataForm {
  dateOfBirth: FormControl<string>   // format YYYY-MM-DD
}
export interface AdyenBirthData {
  dateOfBirth: string   // format YYYY-MM-DD
}


export enum AdyenPhoneType {
  mobile = 'mobile',
  landline = 'landline',
  sip = 'sip',
  fax = 'fax'
}
export interface AdyenPhoneForm {
  number: FormControl<string>
  type?: FormControl<AdyenPhoneType>
}
export interface AdyenPhone {
  number: string
  type?: AdyenPhoneType
}

export interface TransferInstrumentStatus {
  enabled: boolean
  requested: boolean
  requestedLevel: RequestLevel
  allowed?: boolean
  id?: string
}

export interface IdentificationDataForm {
  cardNumber?: FormControl<string>,
  issuerState?: FormControl<string>,
  nationalIdExempt?: FormControl<boolean>,
  number?: FormControl<string>,
  type: FormControl<string>
}
export interface IdentificationData {
  cardNumber?: string,
  issuerState?: string,
  nationalIdExempt?: boolean,
  number?: string,
  type: string
}

export interface AdyenResidencialAddressForm {
  city?: FormControl<string>
  country: FormControl<string>
  postalCode?: FormControl<string>
  stateOrProvince?: FormControl<string>
  street?: FormControl<string>
  street2?: FormControl<string>
}
export interface AdyenResidencialAddress {
  city?: string
  country: string
  postalCode?: string
  stateOrProvince?: string
  street?: string
  street2?: string
}

export interface TaxInformationForm {
  country?: FormControl<string>
  number?: FormControl<string>
  type?: FormControl<string>
}
export interface TaxInformation {
  country?: string
  number?: string
  type?: string
}

export enum BusinessType {
  other = 'other',
  listedPublicCompany = 'listedPublicCompany',
  subsidiaryOfListedPublicCompany = 'subsidiaryOfListedPublicCompany',
  governmentalOrganization = 'governmentalOrganization',
  internationalOrganization = 'internationalOrganization',
  financialInstitution = 'financialInstitution'
}

export enum IncomeType {
  other = 'other',
  businessOperation = 'businessOperation',
  realEstateSales = 'realEstateSales',
  investmentInterestOrRoyalty = 'investmentInterestOrRoyalty',
  propertyRental = 'propertyRental'
}

export enum TaxReportingType {
  nonFinancialNonReportable = 'nonFinancialNonReportable',
  financialNonreportable = 'financialNonReportable',
  nonFinancialActive = 'nonFinancialActive',
  nonFinancialPassive = 'nonFinancialPassive'
}

export interface TaxReportingForm {
  businessType?: FormControl<BusinessType>
  financialInstitutionNumber?: FormControl<string>
  mainSourceOfIncome?: FormControl<IncomeType>
  type?: FormControl<TaxReportingType>
}

export interface TaxReporting {
  businessType?: BusinessType
  financialInstitutionNumber?: string
  mainSourceOfIncome?: IncomeType
  type?: TaxReportingType
}

export enum VatExemption {
  industryExemption = 'industryExemption',
  belowTaxThreshold = 'belowTaxThreshold'
}

export interface AdyenStockForm {
  marketIdentifier?: FormControl<string>
  stockNumber?: FormControl<string>
  tickerSymbol?: FormControl<string>
}
export interface AdyenStock {
  marketIdentifier?: string
  stockNumber?: string
  tickerSymbol?: string
}

export enum OrganizationType {
  associationIncorporated = 'associationIncorporated',
  governmentalOrganization = 'governmentalOrganization',
  listedPublicCompany = 'listePublicCompany',
  nonProfit = 'nonProfit',
  partnershipIncorporated = 'partnershipIncorporated',
  privateCompany = 'privateCompany'
}

export interface LegalEntityIndividualForm {
  birthData?: FormGroup<AdyenBirthDataForm>
  email: FormControl<string>
  identificationData?: FormGroup<IdentificationDataForm>
  name: FormGroup<AdyenNameForm>
  nationality?: FormControl<string>
  phone?: FormGroup<AdyenPhoneForm>
  residentialAddress: FormGroup<AdyenResidencialAddressForm>
  taxInformation?: FormArray<FormGroup<TaxInformationForm>>
}

export interface LegalEntityIndividual {
  birthData?: AdyenBirthData
  email: string
  identificationData?: IdentificationData
  name: AdyenName
  nationality?: string
  phone?: AdyenPhone
  residentialAddress: AdyenResidencialAddress
  taxInformation?: TaxInformation[]
}


export interface LegalEntitySoleProprietorForm {
  countryOfGoverningLaw: FormControl<string>                            // alpha 2 country code
  dateOfIncorporation?: FormControl<string>                              // format YYYY-MM-DD
  doingBusinessAs?: FormControl<string>                                 // registered name if different from standard name
  name: FormControl<string>                                             // business name
  principalPlaceOfBusiness?: FormGroup<AdyenResidencialAddressForm>   // additional address if different from registrar
  registeredAddress: FormGroup<AdyenResidencialAddressForm>           // address registered at registrar (e.g. chamber of commerce)
  registrationNumber?: FormControl<string>
  vatAbsenceReason?: FormControl<VatExemption>
  vatNumber?: FormControl<string>
}
export interface LegalEntitySoleProprietor {
  countryOfGoverningLaw: string                       // alpha 2 country code
  dateOfIncorporation?: string                         // format YYYY-MM-DD
  doingBusinessAs?: string                            // registered name if different from standard name
  name: string                                        // business name
  principalPlaceOfBusiness?: AdyenResidencialAddress  // additional address if different from registrar
  registeredAddress: AdyenResidencialAddress          // address registered at registrar (e.g. chamber of commerce)
  registrationNumber?: string
  vatAbsenceReason?: VatExemption
  vatNumber?: string
}

export interface LegalEntityData {
  type?: LegalEntityType                          //required for LegalEntityRequest (create), not for LegalEntityUpdateRequest
  soleProprietorship?: LegalEntitySoleProprietor,
  organization?: LegalEntityOrganization,
  individual?: LegalEntityIndividual
  reference?: string,
  entityAssociations?: EntityAssociation[]
}

export interface LegalEntityRequest {
  instanceId: string
  adPayId: string
  entityData: LegalEntityData
}

export interface LegalEntityUpdateRequest {
  instanceId: string
  legalEntityId: string
  update: LegalEntityData
}

// ACCOUNT HOLDER

export enum RequestLevel {
  notApplicable = 'notApplicable',
  low = 'low',
  medium = 'medium',
  high = 'high'
}

export enum AllowLevel {
  notApplicable = 'notApplicable',
  low = 'low',
  medium = 'medium',
  high = 'high'
}

export interface Monetary {
  currency: string
  value: number
}

export interface Settings {
  amountPerIndustry: Monetary
  authorizedCardUsers: boolean
  fundingSource: string[]
  interval: string
  maxAmount: Monetary
}

export enum EntityType {
  bankAccount = 'BankAccount',
  document = 'Document',
  legalEntity = 'LegalEntity'
}

export interface Owner {
  documents: string[]
  id: string
  type: EntityType
}

export interface Entity {
  documents: string[]
  id: string
  owner: Owner
  type: EntityType
}

export enum VerifyErrorType {
  invalidInput = 'invalidInput',
  dataMissing = 'dataMissing'
}

export interface VerifyErrorAction {
  code: string
  message: string
}

export interface SubError {
  capabilities: string[]
  code: string
  message: string
  type: VerifyErrorType
  remediatingActions: VerifyErrorAction[]
}
export interface VerifyError {
  capabilities: string[]
  code: string,
  message: string,
  remediatingActions: VerifyErrorAction[]
  subErrors: SubError[]
  type: VerifyErrorType
}

export interface Problem {
  entity: Entity,
  verificationErrors: VerifyError[]
}

export interface Capability {
  enabled: boolean
  requested: boolean
  requestedLevel: RequestLevel
  transferInstruments: TransferInstrumentStatus[]
  allowed?: boolean                         //in response (get/webhook from adyen)
  requestedSettings?: Settings              //in response (get/webhook from adyen)
  allowedLevel?: AllowLevel                 //in response (get/webhook from adyen)
  allowedSettings?: Settings                //in response (get/webhook from adyen)
  verificationStatus?: VerificationStatus   //in response (get/webhook from adyen)
  problems?: Problem[]                      //in response (get/webhook from adyen)
}

export interface Capabilities {
  receiveFromPlatformPayments: Capability
  receiveFromBalanceAccount: Capability
  sendToBalanceAccount: Capability
  sendToTransferInstrument: Capability
  receivePayments: Capability
  receiveFromTransferInstrument: Capability
}

export interface AccountHolder {
  balancePlatform?: string
  capabilities?: Capabilities
  description?: string
  legalEntityId: string
  metadata?: object
  reference?: string
  timeZone?: string
  id?: string
  instance?: string
}

export interface AccountHolderRequest {
  instanceId: string
  adPayId: string
  accountHolderData: AccountHolder
}

export enum BusinessCapability {
  receivePayments = 'receivePayments',
  receiveFromPlatformPayments = 'receiveFromPlatformPayments',
  issueBankAccount = 'issueBankAccount'
}

export enum SalesChannel {
  pointOfSale = 'pos',
  posMoto = 'posMoto',
  eCommerce = 'eCommerce',
  ecomMoto = 'ecomMoto',
  payByLink = 'payByLink'
}

export interface SourceOfFunds {
  acquiringBusinessLineId?: string    // id of business line where funds originate
  adyenProcessFunds: boolean          // true if transactions processed by adyen
  description: string                 // describe origin of payments
  type: string                        // allowed value = business
}

export interface WebData {
  webAddress: string
}

export interface WebDataExemption {
  reason: string
}

export interface BusinessLine {
  id?: string
  capability: BusinessCapability
  industryCode: string
  legalEntityId: string
  salesChannels?: SalesChannel[]
  sourceOfFunds?: SourceOfFunds
  webData?: WebData[]
  webDataExemption?: WebDataExemption
}

export interface BusinessLineRequest {
  instanceId: string
  adPayId: string
  businessLineData: BusinessLine
}

export interface PaymentConfig {
  salesDayClosingTime: string     // format HH:MM H -> 0 to 7 M -> 00
  settlementDelayDays: number     // 0 to 10 or null -> less than 2 needs adyen approval
}

export enum BalanceAccountStatus {
  active = 'active',
  inactive = 'inactive',
  closed = 'closed',
  suspended = 'suspended'
}

export interface BalanceAccount {
  accountHolderId: string
  defaultCurrencyCode?: string                  // EUR is default
  description?: string                          // 300 character limit
  platformPaymentConfiguration?: PaymentConfig  // when to move settled funds from balance account
  reference: string
  metadata?: object
  status?: BalanceAccountStatus
  timeZone?: string,
  id?: string,
  connectedTransferInstrument?: boolean
}

export interface BalanceAccountRequest {
  instanceId: string
  adPayId: string
  accountData: BalanceAccount
}

export interface TransferInstrument {
  bankAccount: BankAccount
  legalEntityId: string
  type: TransferInstrumentType
  id?: string
}

export enum TransferInstrumentType {
  bankAccount = 'bankAccount'
}

export interface BankAccount {
  accountIdentification: AccountIdentification
  bankName?: string
  countryCode?: string
}

export interface AccountIdentification {
  type: AccountType
  iban?: string
  accountNumber?: string
  additionalBankIdentification?: AdditionalBankIdentification
  bic?: string
}

export enum AccountType {
  iban = 'iban',
  numberAndBic = 'numberAndBic'
}

export interface AdditionalBankIdentification {
  code?: string
  type?: BankIdentificationType
}

export enum BankIdentificationType {
  gbSortCode = 'gbSortCode',
  usRoutingNumber = 'usRoutingNumber'
}

export interface TransferInstrumentRequest {
  instanceId: string
  adPayId: string
  transferInstrumentData: TransferInstrument
}

export interface Sweep {
  category?: SweepCategory,
  counterparty: SweepCounterparty,
  currency: string,                         // three letter iso currency code EUR, USD etc.
  description?: string,
  priorities?: SweepPriority[],
  schedule: SweepSchedule,
  status?: SweepStatus,
  sweepAmount?: SweepAmount,
  targetAmount?: TargetAmount,
  triggerAmount?: TriggerAmount,
  type?: SweepType,
  // in DB
  id?: string,
  instance?: string,
  balanceAccountId?: string
}

export enum SweepCategory {
  bank = 'bank',
  internal = 'internal'
}

export interface SweepCounterparty {
  balanceAccountId?: string,
  merchantAccountId?: string,
  transferInstrumentId?: string,
}

export enum SweepPriority {
  regular = 'regular',
  fast = 'fast',
  wire = 'wire',
  instant = 'instant',
  crossBorder = 'crossBorder',
  internal = 'internal'
}

export interface SweepSchedule {
  cronExpression?: string,
  type: SweepScheduleType
}

export enum SweepScheduleType {
  cron = 'cron',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  balance = 'balance'
}

export enum SweepStatus {
  active = 'active',
  inactive = 'inactive'
}

export interface SweepAmount {
  currency: string,           // three letter iso currency code EUR, USD etc.
  value: number               // transaction amount in minor units eg cent
}

export interface TargetAmount {
  currency: string,           // three letter iso currency code EUR, USD etc.
  value: number               // transaction amount in minor units eg cent
}

export interface TriggerAmount {
  currency: string,           // three letter iso currency code EUR, USD etc.
  value: number               // transaction amount in minor units eg cent
}

export enum SweepType {
  push = 'push',
  pull = 'pull'
}

export interface SweepRequest {
  instanceId: string
  adPayId: string
  balanceAccountId: string
  sweepData: Sweep
}

export interface LegalEntityOrganizationForm {
  dateOfIncorporation?: FormControl<string>
  description: FormControl<string>
  doingBusinessAs?: FormControl<string>
  email?: FormControl<string>
  legalName: FormControl<string>
  phone?: FormGroup<AdyenPhoneForm>
  principalPlaceOfBusiness?: FormGroup<AdyenResidencialAddressForm>
  registeredAddress: FormGroup<AdyenResidencialAddressForm>
  registrationNumber?: FormControl<string>
  stockData?: FormGroup<AdyenStockForm>
  taxInformation?: FormArray<FormGroup<TaxInformationForm>>
  taxReportingClassification?: FormGroup<TaxReportingForm>
  type?: FormControl<OrganizationType>
  vatAbsenceReason?: FormControl<VatExemption>
  vatNumber?: FormControl<string>
}

export interface LegalEntityOrganization {
  dateOfIncorporation?: string
  description: string
  doingBusinessAs: string
  email?: string
  legalName: string
  phone?: AdyenPhone
  principalPlaceOfBusiness?: AdyenResidencialAddress
  registeredAddress: AdyenResidencialAddress
  registrationNumber?: string
  stockData?: AdyenStock
  taxInformation?: TaxInformation[]
  taxReportingClassification?: TaxReporting
  type?: OrganizationType
  vatAbsenceReason?: VatExemption
  vatNumber?: string
}

export interface AdyenLegalEntity {
  _id: string
  instance: string
  id: string
  type: LegalEntityType
  reference?: string
  entityAssociations?: EntityAssociation[]
  individual?: LegalEntityIndividual
  soleProprietorship?: LegalEntitySoleProprietor
  organization?: LegalEntityOrganization
}

export interface AdPayConfig {
  instance: string                          // associated instance
  onboardingCompleted: boolean              // set after webhook once onboarding validation complete
  registrationStatus: RegistrationStatus    // check if onboarding was completed
  legalEntityMain?: AdyenLegalEntity                  // sole proprietor or organization id
  legalEntitySub?: AdyenLegalEntity[]                 // individual legal entity ids
  accountHolder?: AccountHolder[]                  // 
  businessLines?: BusinessLine[]
  balanceAccounts?: BalanceAccount[]
  verificationStatus?: VerificationStatus
  store?: AdyenStore[]
  paymentMethods?: StorePaymentMethod[]
  transferInstruments?: TransferInstrument[]
  sweeps?: Sweep[]
  _id?: string
  contractAccepted?: boolean                  // set after last step of registration
  contractAcceptedDate?: Date
  associatedInstances?: string[]
}

export enum AdPayInstanceRole {
  associated = 'associatedInstance',
  main = 'mainInstance',
  admin = 'adminAccess'
}

export interface AdyenSelf {
  href: string
}

export interface AdyenLink {
  self: AdyenSelf
}

export interface AdyenStoreAddress {
  city: string
  country: string
  line1: string
  line2: string
  line3: string
  postalCode: string
  stateOrProvince: string
}

export enum AdyenShopStatus {
  active = 'active',
  inactive = 'inactive',
  closed = 'closed'
}

export interface AdyenSplitConfig {
  balanceAccountId: string
  splitConfigurationId: string
}

export interface AdyenStore {
  instance: string
  _links?: AdyenLink
  address: AdyenStoreAddress
  businessLineIds: string[]
  description: string
  externalReferenceId?: string,
  id?: string
  merchantId?: string
  phoneNumber: string
  reference?: string
  shopperStatement?: string
  splitConfiguration?: AdyenSplitConfig
  status: AdyenShopStatus
  createdAt?: Date
  updatedAt?: Date
  salesChannel?: SalesChannel
}

export interface StoreRequest {
  instanceId: string
  adPayId: string
  salesChannel: string
  storeData: AdyenStore
}

export enum ServiceLevel {
  noContract = 'noContract',
  midNumber = 'midNumber',
  reuseMidNumber = 'reuseMidNumber'
}
export interface AmexPayMethod {
  midNumber?: string
  reuseMidNumber?: boolean
  serviceLevel: ServiceLevel
}

export interface ApplePayMethod {
  domains: string[]
}

export enum TransactionType {
  fixed = 'fixed',
  append = 'append',
  dynamic = 'dynamic'
}

export interface TransactionDescription {
  doingBusinessAsName?: string
  type?: TransactionType
}

export interface CreditCardPayMethod {
  transactionDescription?: TransactionDescription
}

export interface GooglePayMethod {
  merchantId: string
  reuseMerchantId?: boolean
}

export enum KlarnaRegion {
  NorthAmerica = 'NA',
  Europe = 'EU',
  Switzerland = 'CH',
  Australia = 'AU'
}

export interface KlarnaPayMethod {
  autoCapture?: boolean
  disputeEmail: string
  region: KlarnaRegion
  supportEmail: string
}

export enum PaymentMethodTypes {
  ACH = 'ach',  // ACH Direct Debit
  Afterpay = 'afterpaytouch',      // Afterpay
  AliPay = 'alipay',             // Alipay
  AmericanExpress = 'amex',               // American Express
  ApplePay = 'applepay',           // Apple Pay
  BACSDirectDebit = 'directdebit_GB',     // BACS Direct Debit
  Bancontact = 'bcmc',               // Bancontact
  BLIK = 'blik',               // BLIK
  CarteBancaire = 'cartebancaire',      // Carte Bancaire
  ChinaUnionPay = 'cup',                // China Union Pay
  Clearpay = 'clearpay',           // Clearpay
  Diners = 'diners',             // Diners
  Discover = 'disover',            // Discover
  eftposAustralia = 'eftpos_australia',   // eftpos Australia
  Girocard = 'girocard',           // girocard
  Giropay = 'giropay',            // giropay
  Googlepay = 'googlepay',          // Google Pay
  iDEAL = 'ideal',              // iDEAL
  Interac = 'interac_card',       // Interac
  JCB = 'jcb',                // JCB
  Klarna = 'klarna',             // Klarna
  KlarnaAcc = 'klarna_account',
  KlarnaPaynow = 'klarna_paynow',
  Maestro = 'maestro',            // Maestro
  MBWay = 'mbway',              // MB WAY
  Mastercard = 'mc',                 // Mastercard
  MobilePay = 'mobilepay',          // MobilePay
  Multibanco = 'multibanco',         // Multibanco
  EBankingFI = 'ebanking_fi',        // Online Banking Finland
  EBankingPL = 'onlinebanking_Pl',   // Online Banking Poland
  OpenBanking = 'paybybank',          // Open Banking
  PayPal = 'paypal',             // PayPal
  Payshop = 'payshop',            // Payshop
  Sofort = 'directEbanking',     // Sofort
  Swish = 'swish',              // Swish'
  TitresRestaurant = 'mealVoucher_FR',     // Titres-Restaurant
  Trustly = 'trustlty',           // Trustly
  TWINT = 'twint',              // TWINT,
  TWINTpos = 'twint_pos',
  Vipps = 'vipps',              // Vipps
  Visa = 'visa',               // Visa
  VPay = 'vpay',               // V Pay
  WeChatPay = 'wechatpay',          // WeChat Pay
  WeChatPOS = 'wechatpay_pos'
}

export enum VerificationStatus {
  valid = 'valid',
  pending = 'pending',
  invalid = 'invalid',
  rejected = 'rejected'
}

export interface SwishPaymentMethod {
  swishNumber: string // 10 digits no spaces
}

export interface TwintPaymentMethod {
  logo: string // base 64 encoded logo
}

export interface StorePaymentMethod {
  allowed?: boolean
  amex?: AmexPayMethod
  applePay?: ApplePayMethod
  businessLineId: string
  countries?: string[]
  currencies?: string[]
  enabled?: boolean
  girocard?: CreditCardPayMethod
  googlePay?: GooglePayMethod
  id?: string
  instance?: string
  klarna?: KlarnaPayMethod
  mc?: CreditCardPayMethod
  reference?: string
  shopperInteraction?: string
  storeIds: string[]          // POST-Request / GET-Request / DB
  storeId?: string             // only PATCH-Request and addPaymentMethod (adyen-store.controller.js)
  swish?: SwishPaymentMethod
  twint?: TwintPaymentMethod
  type: PaymentMethodTypes
  verificationStatus?: VerificationStatus
  visa?: CreditCardPayMethod
  salesChannel?: SalesChannel
}


/**
 * The group where this payment method belongs to.
 */
export interface PaymentMethodGroup {
  /**
   * The name of the group.
   */
  name: string;
  /**
   * Echo data to be used if the payment method is displayed as part of this group.
   */
  paymentMethodData: string;
  /**
   * The unique code of the group.
   */
  type: string;
}
export interface StoredPaymentMethod extends PaymentMethod {
  /**
   * The supported shopper interactions for this stored payment method.
   */
  supportedShopperInteractions: string[];
  /**
   * A unique identifier of this stored payment method.
   * Mapped from 'storedPaymentMethod.id'
   */
  storedPaymentMethodId?: string;
}

export interface PaymentMethod {
  /**
   * The unique payment method code.
   */
  type: string;
  /**
   * The displayable name of this payment method.
   */
  name: string;
  /**
   * All input details to be provided to complete the payment with this payment method.
   */
  details?: object;
  /**
   * Configuration props as set by the merchant in the CA and received in the PM object in the /paymentMethods response
   */
  configuration?: object;
  /**
   * Brand for the selected gift card. For example: plastix, hmclub.
   */
  brand?: string;
  /**
   * List of possible brands. For example: visa, mc.
   */
  brands?: string[];
  /**
   * The funding source of the payment method.
   */
  fundingSource?: string;
  /**
   * The group where this payment method belongs to.
   */
  group?: PaymentMethodGroup;
}

export interface PaymentMethodsResponse {
  /**
   * Detailed list of payment methods required to generate payment forms.
   */
  paymentMethods?: PaymentMethod[];
  /**
   * List of all stored payment methods.
   */
  storedPaymentMethods?: StoredPaymentMethod[];
}

export enum SplitChargeback {
  liable = 'deductFromLiableAccount',
  balance = 'deductFromOneBalanceAccount',
  split = 'deductAccordingToSplitRatio'
}
export enum SplitDeduct {
  liable = 'deductFromLiableAccount',
  balance = 'deductFromOneBalanceAccount',
}
export enum SplitAdd {
  liable = 'addToLiableAccount',
  balance = 'addToOneBalanceAccount',
}
export interface SplitCommission {
  fixedAmount: number,
  variablePercentage: number
}
export interface SplitLogic {
  chargeback?: SplitChargeback,
  commission: SplitCommission,
  paymentFee?: SplitDeduct,
  remainder?: SplitAdd,
  surcharge?: SplitAdd,
  tip?: SplitAdd,
}
export enum SplitPaymentMethodVariant {
  americanExpress = 'amex',
  americanExpressAP = 'amex_applepay',
  applepay = 'applepay',
  girocard = 'girocard',
  girocardAP = 'girocard_applepay',
  mastercard = 'mc',
  mastercardAP = 'mc_applepay',
  visa = 'visa',
  visaAP = 'visa_applepay',
  twint = 'twint',
  twintPos = 'twint_pos',
  payByBank = 'paybybank',
  any = 'ANY'
}
export enum SplitFundingSource {
  credit = 'credit',
  debit = 'debit',
  any = 'ANY'
}
export enum SplitShopperInteraction {
  ecommerce = 'Ecommerce',
  contAuth = 'ContAuth',
  moto = 'Moto',
  pos = 'POS',
  any = 'ANY'
}
export interface SplitRule {
  paymentMethod: SplitPaymentMethodVariant,
  currency: string,
  fundingSource?: SplitFundingSource,
  shopperInteraction: SplitShopperInteraction,
  splitLogic: SplitLogic
}
export interface SplitConfiguration {
  description: string
  rules: SplitRule[]
}