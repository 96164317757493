import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { PraxisDetails, PraxisId } from '@arzt-direkt/wfa-definitions'
import { Nullable, nonNil, isNil } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { map, Observable } from 'rxjs'

import { WfaEnvService } from '../../../environments/wfa-env.service'
import { FormTableComponent } from '../zs-support/form-table/form-table.component'
import { MatInputPopupComponent } from './../../utility-components/mat-input-popup.component'
import { SpinnerComponent } from './../../utility-components/spinner/spinner.component'
import { FormDraftTableComponent } from './../zs-support/form-draft-table/form-draft-table.component'
import { PraxisSelectComponent } from './../zs-support/praxis-select.component'
import { WfaUnlockFormComponent } from './../zs-support/unlock-form/unlock-form.component'
import {
  updateInfos,
  ZsSupportService,
} from './../zs-support/zs-support.service'
import { ZsSupportCommsService } from './../zs-support/zs-support-comms.service'
import { CreateBlankFormComponent } from './create-blank-form.component'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-doctor-form-table-management',
  template: `
    <wfa-spinner [showSpinner]="showSpinner"></wfa-spinner>
    @if (nonNil(praxisId)) {
      <wfa-form-table></wfa-form-table>
      <wfa-form-draft-table></wfa-form-draft-table>
      <wfa-create-blank-form></wfa-create-blank-form>
      <wfa-mat-input-popup [params]="zsSupportService.editWfaFormIdModal">
      </wfa-mat-input-popup>
    }
  `,
  imports: [
    CommonModule,
    CreateBlankFormComponent,
    FormDraftTableComponent,
    FormTableComponent,
    HttpClientModule,
    MatInputPopupComponent,
    MatProgressSpinnerModule,
    PraxisSelectComponent,
    SpinnerComponent,
    WfaUnlockFormComponent,
  ],
  styleUrls: ['./../zs-support/zs-support.component.scss'],
})
export class DoctorFormTableManagementComponent implements OnInit {
  @Input() praxisId: PraxisId | undefined
  readonly nonNil = nonNil
  readonly showSpinner = toSignal(
    this.zsSupportService.showSpinner$.pipe(untilDestroyed(this)),
    { initialValue: false },
  )

  constructor(
    private comms: ZsSupportCommsService,
    public wfaEnvService: WfaEnvService,
    public zsSupportService: ZsSupportService,
  ) {}

  ngOnInit() {
    if (isNil(this.praxisId)) {
      console.error(
        `[DoctorFormTableManagementComponent/ngOnInit]: praxisId is required.`,
      )
      return
    }

    loadPraxisDetails(this.praxisId, this.comms)
      .pipe(untilDestroyed(this))
      .subscribe((pd: Nullable<PraxisDetails>) => {
        if (isNil(pd)) {
          console.error(
            `[DoctorFormTableManagementComponent/ngOnInit]: could not load praxisDetails.`,
          )
          return
        }
        this.zsSupportService.praxisDetails$.next(pd)
        this.zsSupportService.praxisDetails$.complete()
        this.wfaEnvService.editorEnv$.next({
          ...this.wfaEnvService.editorEnv$.value,
          origin: 'doctorFormTableManagement',
          praxisId: this.praxisId,
        })
      })

    this.comms
      .getFormAndDraftInfos(this.praxisId)
      .pipe(untilDestroyed(this))
      .subscribe(updateInfos(this.zsSupportService))
  }
}

function loadPraxisDetails(
  praxisId: PraxisId,
  comms: ZsSupportCommsService,
): Observable<Nullable<PraxisDetails>> {
  return comms
    .loadAllPraxisDetails()
    .pipe(map((pds: PraxisDetails[]) => pds.find(pd => pd._id === praxisId)))
}
