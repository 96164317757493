import { updateMetadata, WfaForm } from '@arzt-direkt/wfa-definitions'
import {
  isWfaMessage,
  Nullable,
  newWfaMessage,
  isNil,
} from '@arzt-direkt/wfa-generic-utils'
import { catchError, of } from 'rxjs'

import { Routing } from '../../../interfaces'
import { getUrl } from './../../../../utility/get-url'
import { httpOptions } from './../../../../utility/http-params'
import { AdisEditorEnv } from './../../../editor.env'
import { customizeCreatorJson } from './../../creator-json-customization/customize-creator-json'
import { navigateToEditorOrigin } from './navigate-to-editor-origin'

export function publishDraftUsingForm(
  maybeWfaForm: Nullable<WfaForm>,
  routing: Routing<AdisEditorEnv>,
) {
  if (isNil(maybeWfaForm)) {
    console.error(`[publishDraft]: isNil wfaForm`)
    return
  }
  const wfaForm = updateMetadata(maybeWfaForm)
  customizeCreatorJson(wfaForm)
  publishForm(wfaForm, routing)
}

function publishForm(wfaForm: WfaForm, routing: Routing<AdisEditorEnv>) {
  const url = getUrl(routing.wfaEnvService, `wfaFormDraft/publish`)
  const body = {
    wfaForm,
  }

  routing.httpClient
    .post(url, body, httpOptions)
    .pipe(
      catchError(error => {
        routing.wfaEnvService.logger.error(
          `[publishDraft]: called post with url ${url}, got error `,
          error,
          `for form`,
          wfaForm,
        )
        return of(newWfaMessage(`[publishForm]: ${error?.message}`))
      }),
    )
    .subscribe((res: unknown) => {
      if (!isWfaMessage(res)) {
        navigateToEditorOrigin(routing)
      }
    })
}
