import { CoreLocale, WfaKey } from '@arzt-direkt/wfa-definitions'
import {
  PropertyDisplayCustomErrorEvent,
  SurveyCreatorModel,
} from 'survey-creator-core'
import { getLocalizedDuplicateValueMessage } from './get-localized-message'
import { generateUniqueQuestionName } from '../generate-unique-question-name'
import { isWfaKey } from '../wfa-key/wfa-key-utils'
import { Question } from 'survey-core'
import { isNil, nonNil } from '@arzt-direkt/wfa-generic-utils'

export function validateWfaKey(
  creator: SurveyCreatorModel,
  options: PropertyDisplayCustomErrorEvent,
) {
  const wfaKey = options.value
  const question = options.obj as Question

  if (isNil(wfaKey) && isWfaKey(question.name))
    question.name = generateUniqueQuestionName(creator)

  if (nonNil(wfaKey) && isWfaKeyUnique(creator, wfaKey) === false) {
    const locale = creator.locale as CoreLocale
    options.error = getLocalizedDuplicateValueMessage(locale)
  }
}

function isWfaKeyUnique(
  creator: SurveyCreatorModel,
  selectedWfaKey: WfaKey,
): boolean {
  const questions = creator.survey.getAllQuestions()
  const wfaKeys = questions
    .map(question => question.name)
    .filter(name => name === selectedWfaKey)

  if (wfaKeys.length > 1) return false
  return true
}
