import { Nullable, isNil } from '@arzt-direkt/wfa-generic-utils'
import dayjs from 'dayjs'

export function formatDate(
  date: Nullable<string | Date>,
  format: '.' | '-',
): Nullable<string> {
  if (isNil(date)) return undefined
  if (format === '-') return dayjs(date).format('DD-MM-YYYY')
  return dayjs(date).format('DD.MM.YYYY')
}
