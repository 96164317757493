import { nonNil } from './../not-lodash/is-nil'

export function hasStringKeys(x: unknown, keys: string[]): boolean {
  return (
    typeof x === 'object' &&
    nonNil(x) &&
    keys.every(
      key =>
        key in x && typeof (x as Record<string, unknown>)[key] === 'string',
    )
  )
}
