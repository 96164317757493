<quill-editor
  [styles]="{ height: model.height }"
  [(ngModel)]="content"
  [modules]="quillModules"
  [placeholder]="placeholder"
></quill-editor>

<div *ngIf="isSupport">
  <p>HTML direkt bearbeiten (nur für Support)</p>
  <textarea
    [(ngModel)]="content"
    data-selenium-id="textarea-@html-editor/support-html-editor"
  ></textarea>
</div>
