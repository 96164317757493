<span class="magic-fill">
  <button
    class="magic-fill__button"
    aria-label="Magic Fill"
    (click)="magicFill()"
    data-selenium-id="button-@magic-fill/magic-fill"
  >
    <mat-icon [inline]="true">auto_fix_high</mat-icon>
  </button>
</span>
