import Joi from 'joi'

export function uniqueNamesValidator(
  value: Record<string, unknown>,
  helpers: Joi.CustomHelpers,
) {
  const allNames: string[] = []
  getAllNames(value, allNames)
  const uniqueNames = new Set(allNames)
  if (allNames.length !== uniqueNames.size) return helpers.error('name.unique')
  return value
}

export function getAllNames(obj: unknown, names: string[]): void {
  if (obj == null || typeof obj !== 'object') return
  if (Array.isArray(obj)) {
    obj.map(item => getAllNames(item, names))
    return
  }
  const name = (obj as Record<string, unknown>)['name']
  if (name != null) names.push(name as string)
  Object.values(obj).forEach(value => getAllNames(value, names))
  return
}
