import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { WfaFormWithResponse as Fwr } from '@arzt-direkt/wfa-definitions'
import { newWfaMessage, set } from '@arzt-direkt/wfa-generic-utils'
import { catchError, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs'

import { isAdisWfaEnv, WfaEnv } from '../../../../../environments/wfa-env'
import { getDisplayableErrorRoute } from '../../../../user-message/displayable-errors'
import { httpOptions } from '../../../../utility/http-params'
import { replaceSpecialCharacters } from '../../../../utility/replace-special-characters'
import { replaceUmlauts } from '../../../../utility/replace-umlauts'
import { LinkType } from '../single-or-multilink.types'
import { encryptFormResponseData } from './encrypt-form-response-data'
import { LinkData } from './parse-raw-data'

const pathDict: Record<LinkType, string> = {
  single: 'updateWfaFormResponse',
  multi: 'updateWfaFormResponse',
  perma: 'createWfaFormResponseFromPermalink',
}

export function submitResponse(
  ld: Pick<LinkData, 'fwrs' | 'type'>,
  httpClient: HttpClient,
  wfaEnv: WfaEnv,
  router: Router,
): Observable<unknown> {
  const path = pathDict[ld.type as LinkType]
  const url = `${wfaEnv.serverUrl}${wfaEnv.serverUrlSubdirectory}/${path}`

  const withSubmissionDate = ld.fwrs.map(fwr => {
    const response = fwr.formResponse.surveyjsResponse
    // in earlier tomedo versions, we had troubles with some characters and we replace them
    set(
      fwr,
      'formResponse.surveyjsResponse',
      replaceSpecialCharacters(response ?? {}),
    )
    set(fwr, 'formResponse.submittedAt', new Date().valueOf())
    return fwr
  })

  const body$: Observable<object[]> = forkJoin(
    encryptFwr(withSubmissionDate, wfaEnv),
  )

  return body$.pipe(
    switchMap((body: any) =>
      httpClient.post(url, body, httpOptions).pipe(
        tap(v => {
          wfaEnv.logger.log(
            `[WFA] submit-form-response-data: calling post with url ${url}, got response ${JSON.stringify(
              v,
            )}`,
          )
        }),
        catchError(error => {
          wfaEnv.logger.error(
            '[WFA] submit-form-response-data: called post with url ${url}, got error ',
            error,
          )
          router.navigate([
            `${wfaEnv.frontendUrlSubdirectory}/user-message/`,
            getDisplayableErrorRoute('q5709', error?.message),
          ])
          return of(
            newWfaMessage(`[WFA] submitFormResponseData: ${error?.message}`),
          )
        }),
      ),
    ),
  )
}

function encryptFwr(fwrs: Fwr[], wfaEnv: WfaEnv) {
  return fwrs.map(fwr => encryptOneFwr(fwr, wfaEnv))
}

function encryptOneFwr(fwr: Fwr, wfaEnv: WfaEnv) {
  return isAdisWfaEnv(wfaEnv)
    ? encryptFormResponseData(
        fwr,
        (fwr.certificate as string) ??
          (fwr.form['certificate'] as string) ??
          (fwr.formResponse['certificate'] as string),
        wfaEnv,
      ).pipe(map(encrypted => ({ ...encrypted, form: fwr.form })))
    : of({
        form: replaceUmlauts(fwr.form), // indes-server.ts has troubles parsing umlauts for some reason
        formResponse: replaceUmlauts(fwr.formResponse),
        formIsValid: fwr.formIsValid,
      })
}
