import { CustomPropertyInfo } from '../../../../interfaces-autogen-from-joi'

function score(): CustomPropertyInfo {
  return {
    name: 'score',
    type: 'string',
    displayName: {
      de: 'Wert',
      en: 'Value',
      es: 'Valor',
      fr: 'Valeur',
      it: 'Valore',
    },
    visibleIndex: 3,
    category: 'general',
    isSerializable: true,
  }
}

const surveyElements = ['itemvalue']

export const surveyElementsWithScore = surveyElements.reduce(
  (acc, item) => {
    acc[item] = score()
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
