import { WfaFormWithResponse } from '@arzt-direkt/wfa-definitions'
import { isNil } from '@arzt-direkt/wfa-generic-utils'

import { SingleOrMultilinkState as State } from './single-or-multilink.state'

type MinState = Pick<State, 'activeResponseId' | 'fwrs'>
export interface RequiredMinState {
  activeResponseId: string
  fwrs: WfaFormWithResponse[]
}

export function getMultilinkState({ activeResponseId, fwrs }: State): MinState {
  return { activeResponseId, fwrs }
}

export function nonNilMultilinkState(s: MinState): s is RequiredMinState {
  if (isNil(s.activeResponseId)) return false
  if (isNil(s.fwrs)) return false
  return true
}
