import { Question, SurveyModel } from 'survey-core'

export function getScore(this: any, [rawQuestionName]: any[]) {
  const survey = this.survey as SurveyModel
  const question = survey.getQuestionByName(rawQuestionName)

  if (question) {
    if (question.getType() === 'matrix') {
      return getMatrixScores(question)
    }
    return getNonMatrixScores(question)
  }

  if (typeof rawQuestionName !== 'string') {
    return undefined
  }

  return getMatrixRowScores(survey, rawQuestionName)
}

function getNonMatrixScores(question: Question) {
  const value = question.value
  if (typeof value === 'string') {
    return getScoreForChoice(question, value)
  }
  if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
    return value.map(val => getScoreForChoice(question, val))
  }
  return undefined
}

function getMatrixScores(question: Question) {
  const matrixValue = question.value
  if (!matrixValue || typeof matrixValue !== 'object') return undefined
  const rowNames = Object.keys(matrixValue)
  return rowNames
    .map(rowName => {
      const rowValue = matrixValue[rowName]
      if (Array.isArray(rowValue)) {
        return rowValue.map(val => getScoreFromMatrixColumn(question, val))
      }
      return getScoreFromMatrixColumn(question, rowValue)
    })
    .flat()
}

function getMatrixRowScores(survey: SurveyModel, rawQuestionName: string) {
  const dotIndex = rawQuestionName.lastIndexOf('.')
  if (dotIndex === -1) return undefined

  const matrixName = rawQuestionName.substring(0, dotIndex)
  const rowName = rawQuestionName.substring(dotIndex + 1)
  const matrixQuestion = survey.getQuestionByName(matrixName)

  if (!matrixQuestion || matrixQuestion.getType() !== 'matrix') return undefined
  const matrixValue = matrixQuestion.value
  if (!matrixValue || !matrixValue[rowName]) return undefined

  const columnValue = matrixValue[rowName]
  if (Array.isArray(columnValue)) {
    return columnValue.map(val => getScoreFromMatrixColumn(matrixQuestion, val))
  }
  return getScoreFromMatrixColumn(matrixQuestion, columnValue)
}

function getScoreForChoice(question: Question, choiceValue: string) {
  const choice = question.choices?.find((ch: any) => ch.value === choiceValue)
  return parseStringToNumber(choice?.score)
}

function getScoreFromMatrixColumn(question: Question, columnValue: any) {
  const column = question.columns?.find((col: any) => col.value === columnValue)
  return parseStringToNumber(column?.score)
}

function parseStringToNumber(value: any) {
  if (typeof value === 'string' && !isNaN(Number(value))) {
    return Number(value)
  }
  return value
}
