import { isNil, set } from '@arzt-direkt/wfa-generic-utils'

import academicTitle from './academic-title.json'
import birthdate from './birthdate.json'
import contact from './contact.json'
import country from './country.json'
import gender from './gender.json'
import sendingInformation from './sending-information.json'
import pflegegrad from './pflegegrad.json'
import gradDerBehinderung from './grad-der-behinderung.json'
import { TomedoVersion } from '@arzt-direkt/wfa-definitions'

export const customQuestionTypes = (
  tomedoVersion: TomedoVersion = 'latest',
) => {
  const supportedBefore152 = [
    academicTitle,
    birthdate,
    contact,
    country,
    gender,
    sendingInformation,
  ]

  const allCustomQuestionTypes = [
    ...supportedBefore152,
    pflegegrad,
    gradDerBehinderung,
  ]

  if (
    isNil(tomedoVersion) ||
    (tomedoVersion !== 'latest' && Number(tomedoVersion) < 152)
  ) {
    return supportedBefore152
  }

  return allCustomQuestionTypes
}

export const questionTypeTitles = customQuestionTypes().reduce((acc, item) => {
  set(acc, item.name, item.title)
  return acc
}, {})
