/**
 * created with ChatGPT 3.5
 * Limitations: cannot handle circular references (causes infinity loop)
 */
export function cloneDeep<T>(obj: T): T {
  if (obj == null || typeof obj !== 'object') return obj

  if (Array.isArray(obj)) return Array.from(obj, item => cloneDeep(item)) as T

  const newObj: Record<string, unknown> = {}
  Object.keys(obj).forEach(key => {
    const value = (obj as Record<string, unknown>)[key]
    newObj[key] = cloneDeep(value)
  })
  return newObj as T
}
