<div class="booking-date">
  <!-- message to be displayed in case there are no openings -->
  <div class="no-openings" *ngIf="!openingsExist">
    <div class="error">
      <!-- title -->
      <div class="title">
        {{ errorTitle }}
      </div>
      <span></span><img [src]="failureAssetUrl" alt='error'>
    </div>
  </div>
  <!-- the form:
    only visible if there are openings.
  using css instead of *ngIf to make sure calendar is defined otherwise there are errors. -->

  <form [formGroup]="bookingDateFormGroup" [ngClass]="openingsExist ? '' : 'form-hide'" #form>
    <!-- calendar (desktop only) -->
    <div class="calendar">
      <mat-calendar #calendar [minDate]="calendarMinDate" [maxDate]="calendarMaxDate"
        [(selected)]="calendarSelectedDate" (selectedChange)="onCalendarSelectDate($event)"
        (monthSelected)="onCalendarSelectMonth($event)" [headerComponent]="calendarHeaderComponent">
      </mat-calendar>
    </div>
    <!-- appointment mini-summary, doctor, day (mobile only) & openings -->
    <div class="column-right">
      <!-- icon + mini-summary -->
      <div class="complete-mini-summary">
        <div class="icon-and-mini-summary">
          <mat-icon class="icon">event_available</mat-icon>
          <!-- mini summary -->
          <div class="mini-summary" *ngIf="appointmentTypeName">
            <span class="appointment-type-name">
              {{ appointmentTypeName[baseLanguage] === bookingService.I18NString_MISSING_VALUE ?
              appointmentTypeName[languageService.DEFAULT_BASE_LANG] : appointmentTypeName[baseLanguage]
              }}
            </span>
            <span class="opening-date-string" *ngIf="bookingService.openingDateString">
              {{ bookingService.openingDateString }}
            </span>
            <span class="opening-time-string" *ngIf="bookingService.openingTimeString">
              {{ bookingService.openingTimeString }}&nbsp;{{'OTK.BOOKING-DATE.UHR' | translate}}
            </span>
          </div>
        </div>
        <div *ngIf="bsFilterActive && bsSelected" class="icon-and-mini-summary" style="padding-top:0">
          <mat-icon class="icon">location_on</mat-icon>
          <div class="mini-summary">
            <span class="bs-name">{{bsSelected?.name}}</span>
          </div>
        </div>
      </div>
      <!-- doctors -->
      <div attr.data-selenium-id="div-doctors" class="doctors" *ngIf="allowDoctorChoice">
        <mat-form-field appearance="outline">
          <mat-label>{{handlerName}}</mat-label>
          <div *ngIf="!doctor.value" class="doctor-any">
            {{'OTK.BOOKING-DATE.ANY' | translate}}
          </div>
          <mat-select attr.data-selenium-id="mat-select-doctors" formControlName="doctor">
            <!-- selected result -->
            <!-- (notice the ".value" here in contrast to the mat-option-doctor below) -->
            <mat-select-trigger *ngIf="doctor.value" class="doctor">
              <div class="doctor">
                <div class="doctor-photo">
                  <img *ngIf="doctorPhoto" [src]="doctorPhoto" class="doctor-photo--custom" alt='doctorPhoto'>
                  <div *ngIf="!doctorPhoto" class="doctor-photo--default">
                    {{ doctor.value.representingLetter }}
                  </div>
                </div>
                <div class="doctor-text">
                  <div class="doctor-text__fullname">
                    {{doctor.value.fullName}}
                  </div>
                  <div class="doctor-text__description" *ngIf="doctor.value.description">
                    {{doctor.value.description}}
                  </div>
                </div>
              </div>
            </mat-select-trigger>
            <!-- no filter ("beliebig") -->
            <mat-option attr.data-selenium-id="mat-option-doctors-beliebig" *ngIf="doctor.value">
              <div class="doctor">
                <div class="doctor-text">
                  <div class="doctor-text__fullname">
                    {{'OTK.STEPPER.ANY-DOCTOR' | translate }}
                  </div>
                </div>
              </div>
            </mat-option>
            <!-- drop down options -->
            <mat-option attr.data-selenium-id="mat-option-doctors-{{i}}"
              *ngFor="let doctor of doctorsVisible; let i = index" [value]="doctor">
              <div class="doctor">
                <div class="doctor-photo">
                  <img *ngIf="doctor.photo" src="{{ doctor.photo }}" class="doctor-photo--custom" alt='doctorPhoto'>
                  <div *ngIf="!doctor.photo" class="doctor-photo--default">
                    {{ doctor.representingLetter }}
                  </div>
                </div>
                <div class="doctor-text">
                  <div class="doctor-text__fullname">
                    {{doctor.fullName}}
                  </div>
                  <div class="doctor-text__description" *ngIf="doctor.description">
                    {{doctor.description}}
                  </div>
                </div>
              </div>
            </mat-option>
          </mat-select>
          <!-- reset button ("x") -->
          <button attr.data-selenium-id="button-doctors-reset" mat-icon-button matSuffix (click)="resetDoctor($event)"
            *ngIf="doctor.value && !onlyOneVisibleDoctor">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <!-- date (mobile-only) -->
      <!-- known issue:
        user typing date manually leads to format change,
        see https://github.com/angular/components/issues/14291
       -->
      <div attr.data-selenium-id="div-day" class="day">
        <!-- note the 'name="day"'. Without it chrome suggests saved email addresses for this field  -->
        <mat-form-field appearance="outline" class="date">
          <mat-label>{{'OTK.STEPPER.TITLES.APPOINTMENT-DATE' | translate }}</mat-label>
          <input attr.data-selenium-id="input-day-mobile-only" formControlName="day" name="day" matInput
            [matDatepicker]="picker" [matDatepickerFilter]="dateFilter"
            [placeholder]="'OTK.BOOKING-DATE.MAT-DATEPICKER-PLACEHOLDER' | translate " required autocomplete="off"
            [min]="calendarMinDate" [max]="calendarMaxDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker attr.data-selenium-id="datepicker-mobile-only" touchUi #picker
            [calendarHeaderComponent]="calendarHeaderComponent"></mat-datepicker>
          <mat-error *ngIf="dayError">{{dayError}}</mat-error>
        </mat-form-field>
      </div>
      <!-- opening -->
      <div *ngIf="dayString" class="opening-text" [ngClass]="allowDoctorChoice ? '' : 'no-doctor-style'">
        {{ 'OTK.BOOKING-DATE.OPENINGS-TEXT' | translate }} {{ dayString }}
      </div>
      <div attr.data-selenium-id="div-openings" class="openings">
        <mat-button-toggle-group [hideSingleSelectionIndicator]="true" formControlName="opening" required name="opening"
          id="otk-scrollable-content--booking-date">
          <mat-button-toggle attr.data-selenium-id="mat-button-toggle-opening-{{idx}}" class="mat-button-toggle"
            *ngFor="let openingFiltered of openingsFiltered; let idx = index" [value]="openingFiltered"
            [class.mat-button-toggle--isSelected]="opening.value===openingFiltered" [ngSwitch]="usedSlotStyle">
            <!-- doctors and betriebstaette -->
            <div class="button-content" *ngSwitchCase="slotStyle.both">
              <!-- time -->
              <div [ngClass]="leftAlignSlots ? 'content-left-tls-la' : 'content-left-tls'">
                <mat-icon class="icon-big">schedule</mat-icon> {{ 'OTK.STEPPER.OPENING-ITEM-TEMPLATE' |
                translate:{bookingTime: openingFiltered.displayStringTime} }}
              </div>
              <!-- doctor(s) and location -->
              <div [ngClass]="leftAlignSlots ? 'content-right-tls-la' : 'content-right-tls'">
                <!-- doctor(s) -->
                <div class="content-right-fl">
                  <mat-icon class="icon-small">person</mat-icon>
                  <div class="label">{{ openingFiltered.displayStringNames }}</div>
                </div>
                <!-- locations -->
                <div class="content-right-sl">
                  <mat-icon class="icon-small">location_on</mat-icon>
                  <div class="label">{{ openingBsStrings[idx] }}</div>
                </div>
              </div>
            </div>

            <!-- doctors  -->
            <ng-container *ngSwitchCase="slotStyle.onlyDoctor">
              <!-- Mobile -->
              <div class="button-content show--onSmall" [class.mobile-big]="leftAlignSlotsDoctors">
                <div [ngClass]="leftAlignSlotsDoctors ? 'content-left-la-mobile' : 'content-left-mobile'">
                  <mat-icon class="icon-big">schedule</mat-icon>
                  <div [ngClass]="leftAlignSlotsDoctors ? 'mobile-names' : 'mobile-names-inline'"> {{
                    'OTK.STEPPER.OPENING-ITEM-TEMPLATE' |
                    translate:{bookingTime: openingFiltered.displayStringTime} }}</div>
                </div>
                <div [ngClass]="leftAlignSlotsDoctors ? 'content-right-la-mobile' : 'content-right-mobile'">
                  <mat-icon class="icon-big">person</mat-icon>
                  <div [ngClass]="leftAlignSlotsDoctors ? 'mobile-names' : 'mobile-names-inline'">{{
                    openingFiltered.displayStringNames }}</div>
                </div>
              </div>
              <!-- Desktop-->
              <div class="button-content hide--onSmall">
                <div [ngClass]="leftAlignSlotsDoctors ? 'content-left-la' : 'content-left'">
                  <mat-icon class="icon-big">schedule</mat-icon>
                  <span>{{ 'OTK.STEPPER.OPENING-ITEM-TEMPLATE' |
                    translate:{bookingTime: openingFiltered.displayStringTime} }}</span>
                </div>
                <div [ngClass]="leftAlignSlotsDoctors ? 'content-right-la' : 'content-right'">
                  <mat-icon class="icon-big">person</mat-icon>
                  <span>{{ openingFiltered.displayStringNames }}</span>
                </div>
              </div>
            </ng-container>

            <!-- betriebsstaette  -->
            <ng-container *ngSwitchCase="slotStyle.onlyBs">
              <!-- Mobile -->
              <div class="button-content show--onSmall" [class.mobile-big]="leftAlignSlots">
                <div [ngClass]="leftAlignSlots ? 'content-left-la-mobile' : 'content-left-mobile'">
                  <mat-icon class="icon-big">schedule</mat-icon>
                  <div [ngClass]="leftAlignSlots ? 'mobile-names' : 'mobile-names-inline'">
                    {{ 'OTK.STEPPER.OPENING-ITEM-TEMPLATE' |
                    translate:{bookingTime: openingFiltered.displayStringTime} }}</div>
                </div>
                <div [ngClass]="leftAlignSlots ? 'content-right-la-mobile' : 'content-right-mobile'">
                  <mat-icon class="icon-big">location_on</mat-icon>
                  <div [ngClass]="leftAlignSlots ? 'mobile-names' : 'mobile-names-inline'">{{
                    openingBsStrings[idx] }}</div>
                </div>
              </div>
              <!-- Desktop-->
              <div class="button-content hide--onSmall" [class.mobile-big]="leftAlignSlotsDoctor">
                <div [ngClass]="leftAlignSlots ? 'content-left-la' : 'content-left'">
                  <mat-icon class="icon-big">schedule</mat-icon> {{ 'OTK.STEPPER.OPENING-ITEM-TEMPLATE' |
                  translate:{bookingTime: openingFiltered.displayStringTime} }}
                </div>
                <div [ngClass]="leftAlignSlots ? 'content-right-la' : 'content-right'">
                  <mat-icon class="icon-big">location_on</mat-icon> {{
                  openingBsStrings[idx] }}
                </div>
              </div>
            </ng-container>

            <!-- only time -->
            <div class="button-content" *ngSwitchDefault>
              <div class="content-center">
                <mat-icon class="icon-big">schedule</mat-icon> {{ 'OTK.STEPPER.OPENING-ITEM-TEMPLATE' |
                translate:{bookingTime: openingFiltered.displayStringTime} }}
              </div>
            </div>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </form>
</div>