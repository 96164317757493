import { FormControl } from '@angular/forms'
import { Nullable } from '@arzt-direkt/wfa-generic-utils'
import { map, merge, Observable, of } from 'rxjs'

import { sanitize } from './sanitize'

export function sanitizeControl<T>(
  control: FormControl<Nullable<T>>,
  defaultValue: T,
): Observable<T> {
  return control.valueChanges.pipe(
    map((value: Nullable<T>) => sanitize<T>(value, defaultValue)),
  )
}

export function emitDefaultAndSanitize<T>(
  control: FormControl<Nullable<T>>,
  defaultValue: T,
): Observable<T> {
  return merge(
    of(defaultValue),
    control.valueChanges.pipe(
      map((value: Nullable<T>) => sanitize<T>(value, defaultValue)),
    ),
  )
}
