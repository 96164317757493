<p>iframe view</p>
<button (click)="postNewForm()">post other form</button>
<button (click)="postNewForm()">post other form</button>
<button
  data-selenium-id="button-@test-webview-viewer/request-response"
  (click)="postRequestResponse()"
>
  request response
</button>
<button (click)="postPdfExport()">request pdf export</button>
<button
  data-selenium-id="button-@test-webview-viewer/complete-form"
  (click)="postCompleteForm()"
>
  complete form
</button>
<button (click)="postSampleForm()">post sample form</button>
<button (click)="postStatusRequested()">request render status</button>

<iframe #twvIframe [src]="url | safe" width="800" height="800"></iframe>
