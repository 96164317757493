import { WfaFormWithResponse as Fwr } from '@arzt-direkt/wfa-definitions'
import { get, Nullable, nonNil, isNil } from '@arzt-direkt/wfa-generic-utils'

import { LeanCoreState } from './single-or-multilink.state'

export function getResponseId(x: unknown) {
  const candidates = [
    get(x, 'fwr.formResponse.responseId'),
    get(x, 'formResponse.responseId'),
    get(x, 'responseId'),
  ].filter(nonNil)

  if (candidates.length === 1) return candidates[0]
  return undefined
}

interface MinWFR {
  formResponse: {
    responseId: Fwr['formResponse']['responseId']
  }
}
export function getDefaultActiveResponseId(fwrs: MinWFR[]): Nullable<string> {
  return fwrs.length === 0
    ? undefined
    : fwrs[0].formResponse.responseId.toString()
}

export function getIndexByResponseId(s: LeanCoreState): Nullable<number> {
  if (isNil(s.fwrs) || isNil(s.activeResponseId)) return null
  return s.fwrs.findIndex(f => getResponseId(f) === s.activeResponseId)
}

export function getNextResponseId(s: LeanCoreState): Nullable<string> {
  if (isNil(s.fwrs) || isNil(s.activeResponseId)) return null
  const ind = getIndexByResponseId(s)
  if (isNil(ind) || ind + 1 === s.fwrs.length) return null
  return getResponseId(s.fwrs[ind + 1])
}
