import {
  EditorOrigin,
  getGrandparentUrl,
  TargetBuild,
} from '@arzt-direkt/wfa-definitions'

import { Routing } from '../../../interfaces'

export function navigateToEditorOrigin({ router, wfaEnvService }: Routing) {
  const origin = wfaEnvService.editorEnv$.value.origin

  if (origin === 'orphan') {
    console.error(
      `[navigateToEditorOrigin]: need origin to be something other that 'orphan' to navigate away.`,
    )
    return
  }

  const indesUrlDict: Record<Exclude<EditorOrigin, 'orphan'>, string> = {
    doctorFormTableManagement: `${getGrandparentUrl(
      router.url,
    )}/test-doctor-form-table-management`,
    zsSupport: `${getGrandparentUrl(router.url)}/test-zs-support`,
  }
  const indesSubroute = indesUrlDict[origin]

  const indesUrl = `${getGrandparentUrl(router.url)}/${indesSubroute}`
  const adisUrl = `${getGrandparentUrl(router.url)}/formulare`
  const lotosUrl = `${getGrandparentUrl(router.url)}`

  const urlDict: Record<TargetBuild, string> = {
    indes: indesUrl,
    adis: adisUrl,
    lotos: lotosUrl,
  } as const

  const url = urlDict[wfaEnvService.targetBuild] ?? indesUrl
  if (wfaEnvService.targetBuild === 'lotos') {
    console.error('[navigateToEditorOrigin]: lotos should not display editor.')
    return
  }

  router.navigateByUrl(url)
}
