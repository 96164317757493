import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Route, Router } from '@angular/router'
import { WfaFormDraft } from '@arzt-direkt/wfa-definitions'
import { nonNil } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter, switchMap } from 'rxjs/operators'

import { WfaEnvService } from './../../../environments/wfa-env.service'
import { navigateToAdisEditor } from './navigate-to-adis-editor'
import { ZsSupportCommsService } from './zs-support-comms.service'

/**
 * Loads form draft by id and redirects to the editor
 */
@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-open-editor',
  template: '',
  imports: [CommonModule],
})
export class RedirectToEditorComponent implements OnInit {
  constructor(
    private comms: ZsSupportCommsService,
    private route: ActivatedRoute,
    private router: Router,
    public wfaEnvService: WfaEnvService,
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap(params => {
          const versionId = params['wfaFormVersionId']
          if (!versionId) {
            throw new Error('No formVersionId provided')
          }
          return this.comms.getDraft(versionId)
        }),
        filter(nonNil),
        untilDestroyed(this),
      )
      .subscribe({
        next: (wfaFormDraft: WfaFormDraft) => {
          navigateToAdisEditor(wfaFormDraft, this.router, this.wfaEnvService)
        },
        error: error => {
          console.error('Error loading form draft:', error)
        },
      })
  }
}

export const redirectToEditorRoute: Route = {
  path: 'editor/:wfaFormVersionId',
  title: 'Patientenformulare | Editor',
  component: RedirectToEditorComponent,
}
