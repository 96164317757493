<div class="overlay-button">
  <button
    (click)="showOverlay = !showOverlay"
    data-selenium-id="button-@test-editor/settings"
  >
    Settings
  </button>
</div>

<div class="overlay" *ngIf="showOverlay">
  <div>
    <h1>Testing AD Form Editor</h1>
  </div>

  <div>
    <label> Locale: </label>
    <select [formControl]="localeControl">
      <option *ngFor="let locale of locales" [ngValue]="locale">
        {{ locale }}
      </option>
    </select>
  </div>

  <div>
    <label> License Tier: </label>
    <select [formControl]="licenseTierControl">
      <option *ngFor="let licenseTier of licenseTiers" [ngValue]="licenseTier">
        {{ licenseTier }}
      </option>
    </select>
  </div>

  <div>
    <label> tomedo Version: </label>
    <select
      [formControl]="tomedoVersionControl"
      data-selenium-id="select-@test-editor/tomedo-version"
    >
      @for (tomedoVersion of tomedoVersions; track tomedoVersion) {
        <option
          [ngValue]="tomedoVersion"
          data-selenium-id="option-@test-editor/tomedo-version"
        >
          {{ tomedoVersion }}
        </option>
      }
    </select>
  </div>

  <div>
    <label> Support-Account: </label>
    <select
      [formControl]="isSupportOptionsControl"
      data-selenium-id="select-@test-editor/isSupportOption"
    >
      @for (isSupportOption of isSupportOptions; track isSupportOption) {
        <option
          [ngValue]="isSupportOption"
          data-selenium-id="option-@test-editor/isSupportOption"
        >
          {{ isSupportOption }}
        </option>
      }
    </select>
  </div>
</div>

@if (showEditor) {
  <wfa-editor [editorEnv$]="editorEnv$"></wfa-editor>
}
