<wfa-viewer
  [form$]="form$"
  [envState$]="envState$"
  [formResponse$]="formResponse$"
  [requestResponse$]="requestResponse$"
  (formResponseDataEmitter)="emitFormResponseData$.next($event)"
>
</wfa-viewer>

<!-- commented out by BiTa on 2024-12-19 to fix the missing export-pdf-button -->
<!-- <ng-container *ngIf="status === 'survey'">
  <wfa-viewer
    [form$]="form$"
    [envState$]="envState$"
    [formResponse$]="formResponse$"
    [requestResponse$]="requestResponse$"
    (formResponseDataEmitter)="emitFormResponseData$.next($event)"
  >
  </wfa-viewer>
</ng-container>

<ng-container *ngIf="status === 'submitting'">
  <wfa-user-message [surveyjsJson]="submittingJson"> </wfa-user-message>
</ng-container>

<ng-container *ngIf="status === 'submitted'">
  <wfa-user-message [surveyjsJson]="submittedJson"> </wfa-user-message>
</ng-container> -->
