import { isNil, nonNil } from '@arzt-direkt/wfa-generic-utils'

import { Id } from './../interfaces-autogen-from-joi/id'

export function id2String(id: undefined | null): undefined
export function id2String(id: Id): string
export function id2String(id: undefined | null | Id): string | undefined {
  if (isNil(id)) {
    console.info(`[id2String]: isNil input.`)
    return undefined
  }

  if (id === undefined) {
    return undefined
  }

  const { $oid } = id as { $oid: string }
  if (nonNil($oid) && typeof $oid === 'string') return $oid

  if (nonNil(id.toString)) {
    return id.toString()
  }

  if (typeof id === 'string') return id
  console.error(`[id2String]: could not convert ${id} to string.`)
  return ''
}
