import { nonNil } from '@arzt-direkt/wfa-generic-utils'

import { TomedoVersion } from '../../../../interfaces-autogen-from-joi'
import { surveyElementsWithGroupId } from './group-id'
import { surveyElementsWithInstance } from './instance'
import { surveyElementsWithPermalink } from './permalink'
import { surveyElementsWithPraxisId } from './praxis-id'
import { surveyElementsWithScore } from './score'
import { surveyElementsWithShortTitle } from './short-title'
import { surveyElementsWithWfaFormId } from './wfa-form-id'
import { surveyElementsWithWfaFormVersionId } from './wfa-form-version-id'
import { surveyElementsWithWfaInputType } from './wfa-input-type'
import { surveyElementsWithWfaVersion } from './wfa-version'

export * from './short-title'

export const sharedProperties = (tomedoVersion: TomedoVersion = 'latest') => {
  const customProperties = [
    surveyElementsWithInstance,
    surveyElementsWithGroupId,
    surveyElementsWithPermalink,
    surveyElementsWithPraxisId,
    surveyElementsWithShortTitle,
    surveyElementsWithWfaFormId,
    surveyElementsWithWfaInputType,
    surveyElementsWithWfaFormVersionId,
    surveyElementsWithWfaVersion,
  ]

  const customPropertiesSupportedSinceTomedoVersion152 = [
    surveyElementsWithScore,
  ]

  if (
    nonNil(tomedoVersion) &&
    tomedoVersion !== 'latest' &&
    Number(tomedoVersion) < 152
  ) {
    return customProperties
  } else {
    return [
      ...customProperties,
      ...customPropertiesSupportedSinceTomedoVersion152,
    ]
  }
}
