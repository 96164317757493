import { CustomPropertyInfo, TomedoVersion } from '@arzt-direkt/wfa-definitions'

import { wfaKeyChoices } from '../../../../editor/customization/property-grid-customization/wfa-key/wfa-key-utils'

function wfaKey(tomedoVersion?: TomedoVersion): CustomPropertyInfo {
  return {
    name: 'wfaKey',
    type: 'wfaKey',
    displayName: {
      de: 'tomedo® Rückschrieb Wert',
      en: 'tomedo® Write-back value',
      es: 'tomedo® valor de escritura',
      fr: `tomedo® Valeur d'écriture`,
      it: 'tomedo® valore di scrittura',
    },
    visibleIndex: 2,
    category: 'general',
    isSerializable: false,
    /* show wfaKey in editor as a dropdown field with a delete button */
    editorDisplaySettings: {
      fit: function (prop: { type: string }) {
        return prop.type === 'wfaKey'
      },
      getJSON: function () {
        return {
          type: 'dropdown',
          allowClear: true,
          choices: wfaKeyChoices(tomedoVersion),
        }
      },
    },
  }
}

const surveyElements = [
  'radiogroup',
  'rating',
  'checkbox',
  'dropdown',
  'tagbox',
  'boolean',
  'imagepicker',
  'ranking',
  'text',
  'comment',
  'multipletext',
  'matrix',
  'matrixdropdown',
  'matrixdynamic',
  'expression',
  /* custom question types */
  'academic-title',
  'birthdate',
  'contact',
  'country',
  'gender',
  'sending-information',
  'pflegegrad',
  'grad-der-behinderung',
]

export function surveyElementsWithWfaKey(tomedoVersion?: TomedoVersion) {
  return surveyElements.reduce(
    (acc, item) => {
      acc[item] = wfaKey(tomedoVersion)
      return acc
    },
    {} as Record<string, CustomPropertyInfo>,
  )
}
