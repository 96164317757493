import { SurveyjsPropertyInfo } from '@arzt-direkt/wfa-definitions'

export const clearIfInvisible: SurveyjsPropertyInfo = {
  name: 'clearIfInvisible',
  surveyElements: ['expression'],
  customPropertySettings: [
    {
      settingName: 'defaultValue',
      settingValue: 'none',
    },
  ],
}
