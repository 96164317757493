export function isEqual(value: any, other: any): boolean {
  // Handle identical references and primitive equality
  if (value === other) {
    return true
  }

  // Handle null/undefined cases
  if (value == null || other == null) {
    return value === other
  }

  // Handle arrays - must come before typeof check since arrays are also objects
  const valueIsArray = Array.isArray(value)
  const otherIsArray = Array.isArray(other)
  if (valueIsArray !== otherIsArray) {
    return false
  }

  if (valueIsArray && otherIsArray) {
    if (value.length !== other.length) {
      return false
    }
    return value.every((val, index) => isEqual(val, other[index]))
  }

  // Handle different types
  if (typeof value !== typeof other) {
    return false
  }

  // Handle basic types that weren't caught by ===
  if (typeof value !== 'object') {
    // Handle NaN case
    if (Number.isNaN(value) && Number.isNaN(other)) {
      return true
    }
    return value === other
  }

  // Handle Dates
  if (value instanceof Date && other instanceof Date) {
    // Special handling for invalid dates
    if (isNaN(value.getTime()) && isNaN(other.getTime())) {
      return true
    }
    return value.getTime() === other.getTime()
  }

  // Handle RegExp
  if (value instanceof RegExp && other instanceof RegExp) {
    return value.toString() === other.toString()
  }

  // Handle Map
  if (value instanceof Map && other instanceof Map) {
    if (value.size !== other.size) {
      return false
    }
    for (const [key, val] of value) {
      if (!other.has(key) || !isEqual(val, other.get(key))) {
        return false
      }
    }
    return true
  }

  // Handle Set
  if (value instanceof Set && other instanceof Set) {
    if (value.size !== other.size) {
      return false
    }
    for (const item of value) {
      let found = false
      for (const otherItem of other) {
        if (isEqual(item, otherItem)) {
          found = true
          break
        }
      }
      if (!found) {
        return false
      }
    }
    return true
  }

  // Handle Objects (only if both are plain objects)
  if (
    Object.getPrototypeOf(value) === Object.prototype &&
    Object.getPrototypeOf(other) === Object.prototype
  ) {
    const valueKeys = Object.keys(value)
    const otherKeys = Object.keys(other)

    if (valueKeys.length !== otherKeys.length) {
      return false
    }

    if (
      !valueKeys.every(key => Object.prototype.hasOwnProperty.call(other, key))
    ) {
      return false
    }

    return valueKeys.every(key => isEqual(value[key], other[key]))
  }

  // If we get here, the objects have different prototypes
  return false
}
