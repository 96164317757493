import { WfaEnvService } from './../../../environments/wfa-env.service'
import { CommonModule } from '@angular/common'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'
import { setDocumentStyles } from '@arzt-direkt/colors'
import {
  CoreLocale,
  dummyGetInstance,
  TomedoVersion,
} from '@arzt-direkt/wfa-definitions'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { combineLatest, map, startWith } from 'rxjs'

import { EditorComponent } from '../../editor/editor.component'
import { LicenseTier } from '../../types/license-tier'
import { emitDefaultAndSanitize } from '../../utility/sanitize-control'
import {
  EditorEnv,
  STATIC_EDITOR_ENV,
  testEditorEnv,
} from './../../editor/editor.env'

@UntilDestroy()
@Component({
  selector: 'app-test-editor',
  standalone: true,
  imports: [CommonModule, EditorComponent, ReactiveFormsModule],
  templateUrl: './test-editor.component.html',
  styleUrls: ['../overlay.scss', './test-editor.component.scss'],
  providers: [{ provide: STATIC_EDITOR_ENV, useValue: testEditorEnv }],
})
export class TestEditorComponent implements OnInit {
  locales = ['en', 'de', 'fr', 'it', 'es']
  localeControl = new FormControl<CoreLocale>('de')
  licenseTiers = ['basic', 'extended', 'devEverything']
  licenseTierControl = new FormControl<LicenseTier>('devEverything')
  tomedoVersions = ['latest', 148, 149, 150, 151, 152]
  tomedoVersionControl = new FormControl<TomedoVersion>('latest')
  isSupportOptions = [true, false]
  isSupportOptionsControl = new FormControl<boolean>(false)
  showOverlay = false
  showEditor = true

  constructor(
    private cdr: ChangeDetectorRef,
    private wfaEnvService: WfaEnvService,
  ) {}

  ngOnInit(): void {
    setDocumentStyles(document)

    combineLatest([
      this.tomedoVersionControl.valueChanges.pipe(
        startWith(this.tomedoVersionControl.value),
      ),
      this.isSupportOptionsControl.valueChanges.pipe(
        startWith(this.isSupportOptionsControl.value),
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([tomedoVersion, isSupportOption]) => {
        this.showEditor = false
        this.cdr.detectChanges()
        this.wfaEnvService.getInstance = () =>
          dummyGetInstance(tomedoVersion, isSupportOption ?? undefined)
        setTimeout(() => {
          this.showEditor = true
          this.cdr.detectChanges()
        })
      })
  }

  editorEnv$ = combineLatest({
    locale: emitDefaultAndSanitize(this.localeControl, 'de'),
    licenseTier: emitDefaultAndSanitize(
      this.licenseTierControl,
      'devEverything',
    ),
    tomedoVersion: emitDefaultAndSanitize(this.tomedoVersionControl, 'latest'),
  }).pipe(
    map(partialEnv => ({ ...partialEnv, origin: 'orphan' }) as EditorEnv),
    untilDestroyed(this),
  )
}
