export interface Logger {
  debug: (x: unknown, ...args: unknown[]) => void
  info: (x: unknown, ...args: unknown[]) => void
  log: (x: unknown, ...args: unknown[]) => void
  warn: (x: unknown, ...args: unknown[]) => void
  error: (x: unknown, ...args: unknown[]) => void
}

export const consoleLogger: Logger = {
  debug: x => console.debug(x),
  info: x => console.info(x),
  log: x => console.log(x),
  warn: x => console.warn(x),
  error: x => console.error(x),
}
