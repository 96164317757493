import { CustomPropertyInfo } from './../../../../interfaces-autogen-from-joi/custom-property-info'

const shortTitle: CustomPropertyInfo = {
  name: 'shortTitle',
  type: 'string',
  displayName: {
    de: 'tomedo® Kurz-Titel',
    en: 'tomedo® short title',
    es: 'tomedo® título abreviado',
    fr: 'tomedo® Titre court',
    it: 'tomedo® titolo breve',
  },
  isRequired: true,
  visibleIndex: 1,
  category: 'general',
  maxLength: 45,
}

export const surveyElements = [
  'radiogroup',
  'rating',
  'checkbox',
  'dropdown',
  'tagbox',
  'boolean',
  'imagepicker',
  'ranking',
  'text',
  'comment',
  'multipletext',
  'matrix',
  'matrixdropdown',
  'matrixdynamic',
  'expression',
  /* custom question types */
  'academic-title',
  'birthdate',
  'contact',
  'country',
  'gender',
  'sending-information',
  'pflegegrad',
  'grad-der-behinderung',
]

export const surveyElementsWithShortTitle = surveyElements.reduce(
  (acc, item) => {
    acc[item] = shortTitle
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
