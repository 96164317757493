import { calendarIcon } from './calendar-icon'
import { circleIcon } from './circle-icon'
import { countryIcon } from './country-icon'
import { fileIcon } from './file-icon'
import { mortarboardIcon } from './mortarboard-icon'
import { phoneIcon } from './phone-icon'
import { warningIcon } from './warning.icon'

export const customIcons: Record<string, string> = {
  'icon-country': countryIcon,
  'icon-calendar': calendarIcon,
  'icon-circle': circleIcon,
  'icon-file': fileIcon,
  'icon-mortarboard': mortarboardIcon,
  'icon-phone': phoneIcon,
  'icon-warning': warningIcon,
}
