// this import does not work in otk / server_appointment
// import slugify from '@sindresorhus/slugify'

import {
  getCurrentDateTime,
  isWfaMessage,
  validate,
} from '@arzt-direkt/wfa-generic-utils'

import { WfaFormId } from '../interfaces-autogen-from-joi'
import { WfaFormIdJoi } from '../joi/wfa-form-id.joi'

export function convertToWfaFormId(
  input: string,
  date: Date = new Date(),
): WfaFormId {
  // if input is a valid wfaFormId, do nothing
  if (!isWfaMessage(validate<WfaFormId>(WfaFormIdJoi, input))) {
    return input
  }

  // slugify
  // let result = slugify(input)

  // Make everything lowercase
  let result = input.toLowerCase()

  // Step 2: Replace multiple consecutive spaces with a single space
  result = result.replace(/\s+/g, ' ')

  // Step 3: Replace all whitespaces with '-'
  result = result.replace(/\s/g, '-')

  // Step 4: Convert special characters
  const specialChars: Record<string, string> = {
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    ß: 'ss',
  }
  result = result.replace(/[äöüß]/g, match => specialChars[match])

  // Step 5: Replace '_' with '-'
  result = result.replace(/_/g, '-')

  // Step 6: Drop any remaining invalid characters
  result = result.replace(/[^a-z0-9.-]/g, '')

  // Step 7: Remove periods and hyphens from the start and end of the string
  result = result.replace(/^[.-]+|[.-]+$/g, '')

  // Step 8: Ensure the string starts with a letter or number
  if (!/^[a-z0-9]/.test(result)) {
    result = 'a' + result
  }

  if (result.length < 2) {
    result = `form-${getCurrentDateTime(date)}`
  }

  return result
}
