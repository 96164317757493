import {
  initialViewerEnvStateDict,
  IsEmbeddedIn,
  ViewerEnvState,
  WfaFormWithResponse,
} from '@arzt-direkt/wfa-definitions'
import { Nullable } from '@arzt-direkt/wfa-generic-utils'

import { LinkType } from './single-or-multilink.types'

export type LoadableLinkType = LinkType | 'loading' | 'loadError'

export interface SingleOrMultilinkState extends Record<string, unknown> {
  env: ViewerEnvState
  activeResponseId: Nullable<string> // responseId of the currently edited form
  fwrs: Nullable<WfaFormWithResponse[]>
  valid: Record<string, Nullable<boolean>>
  visited: Record<string, Nullable<boolean>>
  linkType: LoadableLinkType
  // this counter is used to manually trigger re-render of currently selected fwr
  // some properties of the current form (like validity of certain questions)
  // can be changed even when activeResponseId and fwrs remain the same.
  // the attribute `rerenderCounter` is a simple and efficient tool to
  // do this / act as a manual change detection trigger
  rerenderCounter: number
  // the corresponding observable can be used to trigger viewer
  // to emit the latest form response
  requestResponseCounter: number
  vorbefuellung: Record<string, unknown>
}

export const initialSingleOrMultilinkState: SingleOrMultilinkState = {
  env: initialViewerEnvStateDict['arztDirekt'],
  activeResponseId: undefined,
  fwrs: undefined,
  valid: {},
  visited: {},
  linkType: 'loading',
  rerenderCounter: 0,
  requestResponseCounter: 0,
  vorbefuellung: {},
}

type State = SingleOrMultilinkState
export interface CoreState {
  fwrs: State['fwrs']
  activeResponseId: State['activeResponseId']
}
export interface LeanCoreState {
  fwrs: Nullable<
    {
      formResponse: {
        responseId: WfaFormWithResponse['formResponse']['responseId']
      }
    }[]
  >
  activeResponseId: State['activeResponseId']
}

export const linkEmbeddedDict: Record<LoadableLinkType, IsEmbeddedIn> = {
  single: 'arztDirekt',
  loading: 'arztDirekt',
  loadError: 'arztDirekt',
  multi: 'adMultilinkViewer',
  perma: 'adPermalinkViewer',
}
