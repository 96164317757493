import { nonNil } from '@arzt-direkt/wfa-generic-utils'
import { ComponentCollection } from 'survey-core'

import { customQuestionTypes } from './custom-question-types/custom-question-types'
import { TomedoVersion } from '@arzt-direkt/wfa-definitions'

export function addCustomQuestionTypes(
  tomedoVersion: TomedoVersion = 'latest',
): void {
  ComponentCollection.Instance.clear()
  const supportedCustomQuestionTypes = customQuestionTypes(tomedoVersion)
  supportedCustomQuestionTypes.forEach(type => {
    const questionTypeName = type.name
    const customQuestionTypeExists =
      doesCustomQuestionTypeExist(questionTypeName)
    if (customQuestionTypeExists) return
    ComponentCollection.Instance.add(type as unknown as any)
  })
}

function doesCustomQuestionTypeExist(name: string): boolean {
  const customQuestion =
    ComponentCollection.Instance.getCustomQuestionByName(name)
  if (nonNil(customQuestion)) return true
  return false
}
