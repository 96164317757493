import { Injectable } from '@angular/core'
import {
  WfaFormResponse,
  WfaFormWithResponse as Fwr,
} from '@arzt-direkt/wfa-definitions'
import { get, isNil, nonNil, set } from '@arzt-direkt/wfa-generic-utils'
import { ComponentStore } from '@ngrx/component-store'
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs'
import { catchError } from 'rxjs/operators'

import { assign, Assigner } from './../../../utility/assign'
import { NotificationService } from './../../../utility-components/notification/notification.service'
import { SingleOrMultilinkApiService } from './api/single-or-multilink.api.service'
import {
  getMultilinkState,
  nonNilMultilinkState,
} from './not-nullish-multilink-state'
import {
  getDefaultActiveResponseId,
  getIndexByResponseId,
  getNextResponseId,
  getResponseId,
} from './response-id.utils'
import {
  selectIcon,
  selectResponseIds,
  updateFormValidity,
} from './select-icon'
import {
  initialSingleOrMultilinkState,
  LoadableLinkType,
  SingleOrMultilinkState as State,
} from './single-or-multilink.state'
import {
  BooleanResponseInfo,
  LinkType,
  SidebarEntry,
} from './single-or-multilink.types'

/**
 * Manages state for single/multilink form responses
 */
@Injectable({
  providedIn: 'root',
})
export class SingleOrMultilinkStore extends ComponentStore<State> {
  constructor(
    public ns: NotificationService,
    private singleOrMultilinkApiService: SingleOrMultilinkApiService,
  ) {
    super(initialSingleOrMultilinkState)
  }

  /*****************************************************************************
   * Selectors
   *****************************************************************************/

  // Core state selectors
  readonly activeResponseId$ = this.select(s => s.activeResponseId).pipe(
    distinctUntilChanged(),
    filter(nonNil),
  )
  readonly env$ = this.select(s => s.env)
  readonly fwrs$ = this.select(s => s.fwrs).pipe(filter(nonNil))
  readonly linkType$ = this.select(s => s.linkType)
  readonly valid$ = this.select(s => s.valid)
  readonly visited$ = this.select(s => s.visited)
  readonly vorbefuellung$ = this.select(s => s.vorbefuellung)
  readonly requestResponse$ = this.select(s => s.requestResponseCounter > 0)

  // Form selectors
  readonly fwr$ = this.select(s => ({
    rerenderCounter: s.rerenderCounter,
    fwr: s.fwrs?.find(fwr => getResponseId(fwr) === s.activeResponseId),
  })).pipe(
    distinctUntilChanged((p, c) => p.rerenderCounter === c.rerenderCounter),
    map(({ fwr }) => fwr),
    filter(nonNil),
    take(5000),
  )
  readonly form$ = this.fwr$.pipe(map(s => s.form))
  readonly formResponse$ = this.fwr$.pipe(map(fwr => fwr.formResponse))
  readonly formLocales$ = this.fwr$.pipe(
    map(fwr => Object.keys(fwr.form.title)),
  )

  // Navigation and UI selectors
  readonly fwrIndex$ = this.select(getMultilinkState).pipe(
    filter(nonNilMultilinkState),
    map(getIndexByResponseId),
    distinctUntilChanged(),
  )
  readonly nextFwr$ = this.fwrIndex$.pipe(
    filter(nonNil),
    withLatestFrom(this.fwrs$),
    map(([fwrIndex, fwrs]) => get(fwrs, `${fwrIndex + 1}`)),
  )
  readonly isMultilink$ = this.fwrs$.pipe(
    map(fwrs => fwrs.length > 1),
    distinctUntilChanged(),
  )
  readonly titlesAndIds$ = this.select(({ fwrs }) =>
    fwrs?.map(fwr => ({
      title: fwr.form.title,
      responseId: fwr.formResponse.responseId.toString(),
    })),
  ).pipe(filter(nonNil))
  readonly fwrsIds$ = this.select(selectResponseIds).pipe(filter(nonNil))
  readonly validIcons$ = this.activeResponseId$.pipe(
    withLatestFrom(this.valid$, this.fwrsIds$, this.visited$),
    map(selectIcon),
  )
  readonly sidebarEntries$: Observable<SidebarEntry[]> =
    this.activeResponseId$.pipe(
      withLatestFrom(this.validIcons$, this.titlesAndIds$),
      map(([_, icons, titlesAndIds]) =>
        titlesAndIds.map((val, ind) => ({ ...val, icon: icons[ind] })),
      ),
    )

  /*****************************************************************************
   * Updaters
   *****************************************************************************/
  // Direct state access updaters
  readonly setActiveResponseId = this.updater(
    this.assignAndRerenderFwr('activeResponseId'),
  )
  readonly setEnv = this.updater(assign('env'))
  readonly setFwrs = this.updater((state: State, fwrs: Fwr[]) => {
    const activeResponseId = getDefaultActiveResponseId(fwrs)
    const rerenderCounter = state.rerenderCounter + 1
    return { ...state, fwrs, activeResponseId, rerenderCounter }
  })
  readonly setLinkType = this.updater(assign('linkType'))
  readonly setValid = this.updater(updateFormValidity)
  readonly setVisited = this.updater(
    (state: State, { responseId, visited }: BooleanResponseInfo<'visited'>) => {
      const newVisited = { ...state.visited, [responseId.toString()]: visited }
      return { ...state, visited: newVisited }
    },
  )
  readonly setVorbefuellung = this.updater(
    (state: State, response?: Record<string, unknown>) => {
      if (isNil(response)) return state
      const vorbefuellung = { ...state.vorbefuellung, ...response }
      return { ...state, vorbefuellung }
    },
  )

  // Current form updaters
  readonly setFwr = this.updater((state: State, fwr: Fwr) => {
    const activeResponseId = getResponseId(fwr)
    const nextState = { ...state, activeResponseId }
    const index = getIndexByResponseId(nextState)
    set(nextState, `fwrs.${index}`, fwr)
    return nextState
  })
  readonly setForm = this.updater(assignFwr('form'))
  readonly setFormResponse = this.updater(assignFwr('formResponse'))
  readonly setSurveyjsResponse = this.updater(
    assignFwr('formResponse.surveyjsResponse'),
  )

  readonly setFwrsResponse = this.updater(
    (state: State, formResponse: WfaFormResponse) => {
      const index = getIndexByResponseId(state)
      if (isNil(index)) {
        this.ns.error('WFA.MULTILINK-VIEWER.SET-FWRS-RESPONSE-ERROR')
        return state
      }
      set(state, `fwrs.${index}.formResponse`, formResponse)
      return state
    },
  )

  // Navigation and UI updaters
  readonly goToNextFwr = this.updater((state: State) => {
    if (isNil(state.fwrs)) {
      this.ns.error('WFA.MULTILINK-VIEWER.SELECT-NEXT-590')
      return state
    }

    const activeResponseId = getNextResponseId(state)
    if (isNil(activeResponseId)) {
      this.ns.error('WFA.MULTILINK-VIEWER.SELECT-NEXT-FWR-ERROR')
      return state
    }

    set(state, 'activeResponseId', activeResponseId)
    attemptVorbefuellung(state)
    return {
      ...state,
      rerenderCounter: state.rerenderCounter + 1,
    }
  })

  // Load and submit forms
  loadData(routeParams: Record<string, string>) {
    return this.singleOrMultilinkApiService.loadLinkData(routeParams).pipe(
      filter(nonNil),
      tap(linkData => {
        this.setFwrs(linkData.fwrs)
        this.setLinkType(linkData.type)
      }),
      catchError(err => {
        this.ns.error([
          `WFA.NOTIFICATION.MULTILINK-VIEWER.LOAD-FORM-ERROR`,
          JSON.stringify(err, null, 2),
        ])
        console.error(`[loadData]: HTTP request error:`, err)
        this.setFwrs([])
        this.setLinkType('loadError' as LoadableLinkType)
        return of(null)
      }),
    )
  }

  submitFormResponse() {
    return of(null).pipe(
      withLatestFrom(this.fwrs$, this.linkType$),
      filter(([_, _fwrs, type]) => type !== 'loading'),
      switchMap(([_, fwrs, type]) =>
        this.singleOrMultilinkApiService.submitLinkData({
          fwrs,
          type: type as LinkType,
        }),
      ),
    )
  }

  assignAndRerenderFwr(path: string): Assigner<State> {
    return (state: State, value: unknown) => {
      set(state, `${path}`, value)
      attemptVorbefuellung(state)
      return { ...state, rerenderCounter: state.rerenderCounter + 1 }
    }
  }
}

function assignFwr(path: string): Assigner<State> {
  return (state: State, value: unknown) => {
    const index = getIndexByResponseId(state)
    set(state, `fwrs.${index}.${path}`, value)
    return { ...state, rerenderCounter: state.rerenderCounter + 1 }
  }
}

/**
 * mutates fwr
 * returns true if worked correctly
 */
function attemptVorbefuellung(state: State): boolean {
  if (isNil(state.fwrs)) return false

  const index = getIndexByResponseId(state)
  if (isNil(index)) return false

  const fwr = state.fwrs[index]
  if (isNil(fwr)) return false

  try {
    const vorbefuellungResponse = state.vorbefuellung
    set(fwr, 'formResponse.surveyjsResponse', vorbefuellungResponse)
    return true
  } catch (err: any) {
    console.warn('[attemptVorbefuellung]', err)
    return false
  }
}
