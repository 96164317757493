import {
  CoreLocale,
  InputType,
  LocString,
  PropertyChoice,
  removeKeys,
  WfaKey,
  wfaKeys,
  WfaQuestionType,
} from '@arzt-direkt/wfa-definitions'
import { TomedoVersion } from '@arzt-direkt/wfa-definitions'
import { isNil, Nullable, nonNil } from '@arzt-direkt/wfa-generic-utils'

import { initialEditorState } from '../../../editor.state'
import qtd from './question-type-dictionary.json'
import std from './short-title-dictionary.json'

interface QuestionTypeDictionary {
  wfaKey: WfaKey
  questionType: WfaQuestionType
  inputType?: string
}

const questionTypeDictionary = qtd as unknown as QuestionTypeDictionary[]

function shortTitleDictionary(
  tomedoVersion?: TomedoVersion,
): Partial<Record<WfaKey, LocString>> {
  // certain keys are hidden from view until they are
  // supported in tomedo, see https://zollsoft.atlassian.net/browse/ADI-3198
  var shortTitlesToRemove: WfaKey[] = [
    '$wfaPatientKrankenkasse$',
    '$wfaPatientVersicherungsnummer$',
    '$wfaPatientVersichertennummer$',
    '$wfaPatientGewicht$',
    '$wfaPatientGroesse$',
    '$wfaPatientArbeitgeber$',
  ]

  if (
    nonNil(tomedoVersion) &&
    tomedoVersion !== 'latest' &&
    Number(tomedoVersion) < 152
  ) {
    shortTitlesToRemove = [
      ...shortTitlesToRemove,
      '$wfaPatientGradDerBehinderung$' as WfaKey,
      '$wfaPatientPflegegrad$' as WfaKey,
    ]
  }

  return removeKeys(std, shortTitlesToRemove) as unknown as Partial<
    Record<WfaKey, LocString>
  >
}

const fallbackLocale = initialEditorState.editorEnv.locale

export function wfaKeyChoices(tomedoVersion?: TomedoVersion): PropertyChoice[] {
  return Object.entries(shortTitleDictionary(tomedoVersion)).map(entry => {
    const [key, value] = entry
    return {
      value: key,
      text: value,
    }
  })
}

export function lookUpLocalizedShortTitleFromWfaKey(
  wfaKey: WfaKey,
  locale: CoreLocale,
): Nullable<string> {
  const shortTitleLocString = shortTitleDictionary('latest')[wfaKey]
  if (isNil(shortTitleLocString)) return undefined
  return shortTitleLocString[locale] ?? shortTitleLocString[fallbackLocale]
}

export function wfaKey2QuestionType(wfaKey: WfaKey): Nullable<WfaQuestionType> {
  const entry = questionTypeDictionary.find(e => e['wfaKey'] === wfaKey)
  if (isNil(entry)) return undefined
  return entry.questionType
}

export function wfaKey2TextInputType(wfaKey: WfaKey): Nullable<InputType> {
  const entry = questionTypeDictionary.find(e => e['wfaKey'] === wfaKey)
  if (isNil(entry)) return undefined
  return entry.inputType as InputType
}

export function questionType2WfaKey(
  questionType: WfaQuestionType,
): Nullable<WfaKey> {
  const entries = questionTypeDictionary.filter(
    e => e['questionType'] === questionType,
  )
  if (entries.length !== 1) return undefined
  return entries[0].wfaKey
}

export function isWfaKey(x: unknown): x is WfaKey {
  if (isNil(x)) return false
  return typeof x === 'string' && wfaKeys.includes(x as WfaKey)
}
