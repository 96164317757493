import { MatDialog } from '@angular/material/dialog'
import { CoreLocale, ViewerEnvState } from '@arzt-direkt/wfa-definitions'
import { Model, SurveyModel } from 'survey-core'

import { handleFileDeletion } from './handle-file-deletion'

function addHandlerToFileDeletionEvent(
  surveyModel: Model,
  dialog: MatDialog,
  env: ViewerEnvState,
): void {
  // remove previous configuration
  surveyModel.onClearFiles.clear()

  surveyModel.onClearFiles.add((_, options) => {
    const questionType = options.question.getType()
    if (questionType === 'file') handleFileDeletion(options, dialog, env)
  })
}

const initializeFileDeletion = addHandlerToFileDeletionEvent

function updateFileDeletionOnLocaleChange(
  surveyModel: SurveyModel,
  dialog: MatDialog,
  env: ViewerEnvState,
): void {
  surveyModel.onPropertyChanged.add((_, options) => {
    if (options.name === 'locale') {
      const locale = options.newValue as CoreLocale
      addHandlerToFileDeletionEvent(surveyModel, dialog, { ...env, locale })
    }
  })
}

export function customizeFileDeletion(
  surveyModel: Model,
  dialog: MatDialog,
  env: ViewerEnvState,
): void {
  initializeFileDeletion(surveyModel, dialog, env)
  updateFileDeletionOnLocaleChange(surveyModel, dialog, env)
}
