import Joi from 'joi'

import { BaseQuestionJoi } from './base-question.joi'
import { WfaGradDerBehinderungQuestionExample } from './examples/wfa-grad-der-behinderung-question.joi.example'

export const WfaGradDerBehinderungQuestionJoi = BaseQuestionJoi.keys({
  type: Joi.string().valid('grad-der-behinderung').required(),
})
  .meta({
    className: 'WfaGradDerBehinderungQuestion',
  })
  .example(WfaGradDerBehinderungQuestionExample)
