import { CoreLocale } from '@arzt-direkt/wfa-definitions'
import { Question } from 'survey-core'
import {
  SurveyCreatorModel,
  PropertyDisplayCustomErrorEvent,
} from 'survey-creator-core'
import {
  getLocalizedContainsMultiSpacesMessage,
  getLocalizedContainsSpacesMessage,
  getLocalizedDuplicateValueMessage,
} from './get-localized-message'
import { isNil } from '@arzt-direkt/wfa-generic-utils'
import { isShortTitleUnique } from '../generate-unique-short-title'

export function validateShortTitle(
  creator: SurveyCreatorModel,
  options: PropertyDisplayCustomErrorEvent,
) {
  const question = options.obj as Question
  const shortTitle = options.value

  if (isNil(options.value)) {
    return
  }

  if (shortTitle.includes('  ')) {
    const locale = creator.locale as CoreLocale
    options.error = getLocalizedContainsMultiSpacesMessage(locale)
  }

  if (isShortTitleUnique(creator, question, shortTitle) === false) {
    const locale = creator.locale as CoreLocale
    options.error = getLocalizedDuplicateValueMessage(locale)
  }
}
