export function replaceUmlauts<T>(obj: T): T {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      replaceUmlauts(obj[key])
    }
    if (typeof obj[key] === 'string') {
      const val = obj[key] as string
      Object.assign(obj as Record<string, unknown>, {
        [key]: replaceUmlautsInString(val),
      })
    }
  }
  return obj
}

export function replaceUmlautsInString(input: string): string {
  // Define a map of umlaut replacements
  const umlautMap: Record<string, string> = {
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    Ä: 'Ae',
    Ö: 'Oe',
    Ü: 'Ue',
    ß: 'ss',
    'a\u0308': 'ae',
    'o\u0308': 'oe',
    'u\u0308': 'ue',
    'A\u0308': 'Ae',
    'O\u0308': 'Oe',
    'U\u0308': 'Ue',
  }

  // Create a regular expression pattern to match umlaut characters
  const umlautPattern = /[ä,ö,ü,Ä,Ö,Ü,ß]|[a,o,u,A,O,U]\u0308/gu

  // Use the replace method to replace umlauts with their counterparts
  const result = input.replace(umlautPattern, match => umlautMap[match])

  return result
}
