import {
  LocString,
  surveyElements as questionTypesWithShortTitle,
} from '@arzt-direkt/wfa-definitions'
import { isNil } from '@arzt-direkt/wfa-generic-utils'

const defaultShortTitle = 'Default-Kurz-Titel'

export function setShortTitleIfEmpty(obj: Record<string, unknown>) {
  if (typeof obj !== 'object' || isNil(obj)) return

  if (Array.isArray(obj)) {
    obj.map(element => setShortTitleIfEmpty(element))
  }

  const shouldHaveShortTitle =
    questionTypesWithShortTitle.includes(obj['type'] as string) && obj['name']

  Object.entries(obj).forEach(([_key, value]) => {
    if (!shouldHaveShortTitle && typeof value === 'object')
      setShortTitleIfEmpty(value as Record<string, unknown>)
  })

  if (
    shouldHaveShortTitle &&
    (!obj['shortTitle'] || obj['shortTitle'] === defaultShortTitle)
  ) {
    obj['shortTitle'] = getShortTitle(obj['title'] as LocString | string)
  }
}

function getShortTitle(title: LocString | string) {
  if (typeof title === 'object' && title !== null && 'de' in title) {
    return (title as LocString).de.replace(/\s+/g, '-').slice(0, 45)
  } else if (typeof title === 'string') {
    return title.replace(/\s+/g, '-').slice(0, 45)
  } else {
    return defaultShortTitle
  }
}
