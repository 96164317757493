import { CoreLocale, WfaForm } from '@arzt-direkt/wfa-definitions'

import { initialViewerState } from '../../viewer.state'

const fallbackLocale = initialViewerState.env.locale

export function getFormTitle(
  wfaForm: Pick<WfaForm, 'title'>,
  locale: CoreLocale = fallbackLocale,
): string {
  return wfaForm.title[locale] ?? (wfaForm.title[fallbackLocale] as string)
}
