import { CoreLocale } from '@arzt-direkt/wfa-definitions'
import { Nullable } from '@arzt-direkt/wfa-generic-utils'

export function getLocalizedTranslations(
  translations: Record<string, unknown>,
  locale: CoreLocale,
): Nullable<Record<string, unknown>> {
  const result: Record<string, unknown> = {}

  for (const key in translations) {
    const tk = translations[key] as Record<string, unknown>
    if (typeof tk === 'object')
      result[key] = getLocalizedTranslations(tk, locale)
    if (tk[locale]) result[key] = tk[locale]
  }

  return Object.keys(result).length > 0 ? result : null
}
