import { Id } from '@arzt-direkt/wfa-definitions'
import { Nullable, isNil } from '@arzt-direkt/wfa-generic-utils'

import { getResponseId } from './response-id.utils'
import { SingleOrMultilinkState as State } from './single-or-multilink.state'
import {
  BooleanResponseInfo,
  ScrollableSidebarIcon,
} from './single-or-multilink.types'

type AreFormsValid = Record<string, Nullable<boolean>>

type Args = [Id, AreFormsValid, Id[], Record<string, Nullable<boolean>>]

export function selectResponseIds({ fwrs }: State): Nullable<Id[]> {
  return fwrs?.map(getResponseId)
}

export function updateFormValidity(
  state: State,
  { responseId, isValid }: BooleanResponseInfo<'isValid'>,
) {
  const valid = { ...state.valid, [responseId.toString()]: isValid }
  return { ...state, valid }
}

export function selectIcon(args: Args): ScrollableSidebarIcon[] {
  const [responseId, valid, fwrsIds, visited] = args
  const res = fwrsIds.map(fwrsId => {
    const isValid = valid[fwrsId.toString()]
    const isVisited = visited[fwrsId.toString()]

    // for currently edited form
    if (responseId.toString() === fwrsId.toString()) return 'edit'
    // for pristine forms
    if (isNil(isValid)) return null
    // for forms that were clicked in sidebar but not edited
    if (isValid === false && isVisited !== true) return 'warning'
    // for invalidly/incompletely filled out forms that were edited
    if (isValid === false && isVisited === true) return 'warning'
    // for valid/completely filled out forms
    if (isValid === true) return 'done'
    // fallback shouldn't be shown, but if it is, should not look suspicious
    return 'check'
  })
  return res
}
