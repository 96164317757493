// import { EventEmitter } from '@angular/core';
/**
 * note:
 * originally, we had this here
 * import { EventEmitter } from 'events';
 * however this is a node package which is imported to angular which is weird.
 * if the above doesn't work, another option is to use eventemitter3
 * import EventEmitter from 'eventemitter3';
 */

import '@angular/localize/init';
import EventEmitter from 'events';
import 'zone.js'; // Included with Angular CLI.
window.Buffer = window.Buffer || require('buffer').Buffer;
(window as any).global = window;
(window as any).globalEvents = new EventEmitter();

