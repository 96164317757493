import {
  ensureType,
  isNil,
  newWfaMessage,
  Nullable,
  validate,
  WfaMessage,
} from '@arzt-direkt/wfa-generic-utils'
import Joi from 'joi'

import { AnyWfaForm, WfaForm } from './../interfaces-autogen-from-joi/wfa-form'
import { wfaFormJoiDict } from './../joi/wfa-form.joi'
import { selectVersion } from './select-version'

const DEFAULT_VERSION = 'v1.5.0'

/** Gets the Joi schema for WFA form validation based on version */
function getWfaFormSchema(
  form: Nullable<unknown>,
): Joi.ObjectSchema<AnyWfaForm> {
  const wfaVersion = (form as WfaForm).wfaVersion ?? DEFAULT_VERSION
  return (
    selectVersion(wfaVersion, wfaFormJoiDict) ??
    (wfaFormJoiDict[DEFAULT_VERSION] as unknown as Joi.ObjectSchema<AnyWfaForm>)
  )
}

/** Throws error if form is null/undefined */
function checkNullish(form: Nullable<unknown>, errorMessage: string): void {
  if (isNil(form)) {
    throw new Error(errorMessage)
  }
}

/**
 * Validates WFA form, returns error message if invalid
 * @deprecated Use `ensureWfaForm` instead
 */
export function validateWfaForm(form: Nullable<unknown>): WfaForm | WfaMessage {
  if (isNil(form)) {
    return newWfaMessage('[validateWfaForm]: no message provided')
  }

  const schema = getWfaFormSchema(form)
  return validate(schema, form) as unknown as WfaForm | WfaMessage
}

/** Validates WFA form, throws error if invalid */
export function ensureWfaForm(form: Nullable<unknown>): WfaForm {
  checkNullish(form, '[WfaForm validation failed]: no form provided')

  const schema = getWfaFormSchema(form)
  return ensureType(schema, form) as WfaForm
}
