/**
 * contains entities for the "Booking" folder.
 * In other words contains entities related to the otk booking frontend but not for the configuration.
 * Note that the interfaces are partial, as in, the backend interfaces contain additional fields. Here only the fields actually needed for the frontend are defined.
 * Why do we have all of these extra fields? Because tomedo has them and it makes it easier for us to synchronize with tomedo.

 * design choice:
 * alle interfaces im Backend fangen mit "I" an weil:
 *  - es schon in Tomedo so ist
 *  - weil wir den gleichen Interface namen für das Model verwenden,
 *    halt ohne I, und möchten nicht, genau den gleichen Namen für beide
 *    zu verwenden, auch wenn es vom typescript erlaubt ist
 * alle interfaces im Frontend fangen ohne "I" an weil:
 *  - wir sie von den backend interfaces unterscheiden möchten, und zwar,
 *    wir möchten sie minimal behalten, also nur die Felder da haben, die
 *    wir im frontend wirklich brauchen.
 *
 * Warum brauchen wir verschiede interfaces für frontend und für backend?
 * weil die backend interfaces alle Felder von Tomedo direkt übernehmen,
 * auch viele, die wir nicht fürs frontend also für OTK2 brauchen.
 * Deswegen verwenden wir minimalistischere interfaces fürs frontend.
 * langfristig sollen wir die einigen, also nur die minimalistische verwenden.
 *
 * Um die redundante Typ-Deklarationen möglichst minimal zu halten, werden manche
 * Typen aus Backend ins Frontend importiert. Grundsätzlich ist ein Frontend-Typ
 * ein Subtyp von dem zugehörigen backend-Typ und kann davon abgeleitet werden.
 * Beispiel:
 * interface ICar { wheels: boolean, brake: boolean, sunroof: boolean }
 * type Car = Pick<ICar, "wheels"| "brake"> & Partial<ICar>
 */

import { SafeHtml } from '@angular/platform-browser';
import { ICheckboxResponse, ICustomFieldResponse, IPersonalData } from '@models/OtkAppointment.model';
import dayjs from 'dayjs';
import type { InsuranceType } from 'lib';
import { I18NString } from "../vendor-link/arzt-direkt";
import { PaymentAmount, PaymentInfo, PaymentInfoDeferred } from './AdPay.entity';
import { BetriebsstaetteEmailSettings, EmailFormat, FieldSettings, IOTKBookingLimitSettings, IOtkBookingStrategy, OTKUser, PatientTargetType } from './Calendar.entity';
import { BaseLanguage } from './I18N.entity';
import { Dialect } from './Instance.entity';
import { PraxisContact } from './Praxis.entity';

export { I18NSTRING_EMPTY, I18NString_MISSING_VALUE } from "../vendor-link/arzt-direkt";
export type { I18NString } from "../vendor-link/arzt-direkt";
export { BaseLanguage } from './I18N.entity';

export enum AttachmentType {
  COMPOUND = "compund",
  ADD = "add"
}
// ********** booking-start **********

// copy of IOtkCategoriesAndInsurances from backend
// response of getCategoriesAndInsurances()
export interface CategoriesAndInsurances {
  appointmentCategories: AppointmentCategory[],
  insuranceTypes: PatientInsuranceType[],
}

// ********** booking-type **********
export const BookingStep = {
  // "pre-steps"
  bookingInfo: 'BOOKING_INFO',
  bookingPreliminary: 'BOOKING_PRELIMINARY', // is known (Bestandspatient), insurance type and birthday
  // "steps"
  bookingBs: 'BOOKING_BS',
  bookingType: 'BOOKING_TYPE',
  bookingDate: 'BOOKING_DATE',
  bookingDateMulti: 'BOOKING_DATE_MULTI',
  bookingAnamnese: 'BOOKING_ANAMNESE',
  bookingPersonal: 'BOOKING_PERSONAL',
  bookingSummary: 'BOOKING_SUMMARY',
  bookingPayment: 'BOOKING_PAYMENT'
} as const
export type BookingStep = typeof BookingStep[keyof typeof BookingStep]
/**
 * the tab titles as they appear in the gui. These are strings and not enums
 * as they are taken from the json language files.
 * The ones for the presteps (info and preliminary) are never actually seen anywhere?
 */
export interface TabTitles {
  [BookingStep.bookingInfo]?: string,
  [BookingStep.bookingPreliminary]: string,
  [BookingStep.bookingBs]: string,
  [BookingStep.bookingType]: string,
  [BookingStep.bookingDate]: string,
  [BookingStep.bookingDateMulti]: string,
  [BookingStep.bookingAnamnese]: string,
  [BookingStep.bookingPersonal]: string,
  [BookingStep.bookingSummary]: string,
  [BookingStep.bookingPayment]: string,
}

export const FormType = {
  anamneseForm: 'ANAMNESE_FORM',
  personalForm: 'PERSONAL_FORM',
} as const
export type FormType = typeof FormType[keyof typeof FormType]
export interface AppointmentCategory {
  _id: string
  name: I18NString
  instance: string
  description?: I18NString
  order: number
  appointmentTypes: AppointmentType[]
  isGeneric: boolean
  appointmentTypeIds?: string[]
}

export type I18NHtmlString = Partial<Record<BaseLanguage, SafeHtml>>

export enum BookedOverType {
  APP = 'app',
  IFRAME = 'iframe',
  AD = 'a-d'
}

/**
 * after selecting the booking category, the user selects
 * a booking type.
 // OtkAppointmentType.model.ts IOtkAppointmentType
 */
export interface AppointmentType {
  _id: string                     // database Id
  instance: string                // instance id
  otkuser?: OTKUser
  name: I18NString                 // booking display name
  description?: I18NString         // detailed booking description
  showDescription?: boolean
  info?: I18NString                // special informational text
  showInfo?: boolean
  photo?: string                  // photo
  insurance: string[]             // accepted insurances (array of length 0 means all are accepted!)
  terminSucheIdent: string        // connected tomedo terminsuche
  terminKetteSucheIdent?: string
  isEnabled: boolean              // will the appointment type be shown (former field 'status': 'enabled' / 'disabled')
  isZuweiser: boolean             // is this appointment type protected by a zuweiser code? (former field 'status': 'zuweiser')
  active: boolean                 // true means it will be synced with Tomedo
  forerunTime?: number
  attachment?: string[]             // name of email attachment file
  tomTerminSuche?: TomTerminSuche // tomedo appointmentType settings
  tomTerminKetteSuche?: TomTerminKetteSuche // tomedo 
  formIdentifier?: string         // "Kennung" of the form to be used with this appointment type. If not set, no anamnese form will be used.
  displayLimit?: number           // maximum number of appointments to display per day
  emailSettings: AppointmentTypeEmailSettings
  useSpecificFieldSettings?: boolean
  waitinglist?: boolean
  specificFieldSettings?: FieldSettings //in the frontend we dont use the virtual fieldSettings from the mongoosemodel
  duration: number                      // calculated by getCategories() from the relevant tomterminsuche
  durationSearch?: number               // special display search range
  allowClientMoveDefault?: boolean
  allowClientCancellationDefault?: boolean
  urgentAppointment?: boolean     // special mode flag where only 1 opening is shown for the current day
  // the following are virtuals in backend. Here in frontend they are normal variables.
  allowDoctorChoice: boolean
  allowOnlyKnownEmail: boolean
  allowClientCancellation: boolean
  allowClientMove: boolean
  showDuration: boolean
  cancellationWindow: number      // number of hours before appointment start where patient is able to cancel
  showDoctors: boolean
  strategy: OtkBookingStrategy    // type of appointment confirmation
  showIfNoOpenings: boolean       // show in booking-type even if no openings?
  selectableIfNoOpenings: boolean // allow selecting in booking-type if showIfNoOpenings && no openings?
  textNoOpenings: I18NString      // shown if (selected in booking-type || reached via parametrization) && no openings
  bookingLimitSettings: IOTKBookingLimitSettings  // appointment type specific booking limitation
  dialect: Dialect                // determines how the handler is called (handler, doctor...)
  // front-end only
  hasOpenings: boolean            // does the appointment type have openings
  isDisabled: boolean             // !appointmentType.hasOpenings && !appointmentType.selectableIfNoOpenings
  onlineConsultation: boolean     // appointmentType for online consultations (attaches link for vss to appointment)
  onlineConsultationFormIdentifier?: string
  fileUpload?: boolean             // patient can upload files as otkAttachments to their appointment
  patientTarget: PatientTargetType
  displayOptions?: DisplayOptions
  skipOpeningSelection?: boolean   // should booking-date be skipped? used for the contact form
  appointmentSeriesType?: AppointmentSeriesType
  createdAt?: string
  adPayActive?: boolean
  vatPercentage?: number
  netAmount?: PaymentAmount
  amount?: PaymentAmount
  storeId?: string
  redirectionAfterBookingActive?: boolean
  redirectionAfterBookingUrls?: I18NString
}

export interface AppointmentSeriesTypeItem {
  name: I18NString,
  description: I18NString,
  terminKetteSucheEintragIdent: string,
  terminSucheIdent: string,
  durationSincePrevious: string,
  durationToSearch: string,
  duration?: number
  forerunTime?: number
  mhd?: number
}

export interface AppointmentSeriesType {
  _id: string
  instance: string
  terminKetteSucheIdent: string                  // id of associated tomedo TerminKetteSuche instance
  items: AppointmentSeriesTypeItem[]
  automaticSelection?: boolean
}

/**
 * I think:
 * - the user can't choose lte or gte
 * - however when the user selects lt or gt they actually get lte or gte respectively
 * - range is not saved in the db but converted to lte and gte (or lt and gt?)
 * if I'm correct I would recommend:
 * - delete lt and gt
 * - correctly label lte and gte
 * - add range to the backend, eliminating the need for a condition array
 */
export enum TimeComparator {
  LESS_THAN_EQUALS = 'lte',
  GREATER_THAN_EQUALS = 'gte',
  // the following three are used in display-option-range
  LESS_THAN = 'lt',
  GREATER_THAN = 'gt',
  RANGE = 'range' // not saved in the db directly but converted to lt and gt. todo: implement in backend as well and thus simplify the conditions array to a single condition
}

export enum TimeUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export interface AgeCondition {
  timeComparator: TimeComparator
  timeSpan: number
  timeUnit: TimeUnit
}

export interface ConditionalAge {
  show: boolean
  condition: AgeCondition[]
}

export interface DisplayOptions {
  ageAtBookingTime: ConditionalAge
  ageAtAppointmentTime: ConditionalAge
}

export interface AppointmentTypeEmailSettings {
  cancelled: AppointmentTypeEmailDefinition
  confirmed: AppointmentTypeEmailDefinition
  moved: AppointmentTypeEmailDefinition
}

export interface AppointmentTypeEmailDefinition {
  content: I18NString
  format: EmailFormat
  embedContent: boolean
  active: boolean
  sendHostMessage: boolean
}

export interface TomTerminSuche {
  ident: string
  instance: string
  laenge: number              // time interval where
  name: string
  suchTermin?: TomTerminArt
}

export interface TomTerminKetteSuche {
  instance: string
  ident: string
  name?: string
  visible?: boolean
  terminKetteSucheEintraege?: TomTerminKetteSucheEintrag[]
  availableOnline?: boolean
  listenpos?: number
}

export interface TomTerminKetteSucheEintrag {
  instance: string
  ident: string
  listenpos?: number
  terminSuche?: TomTerminSuche
  visible?: boolean
  mhd?: number
  plusZeit?: number
  varianzZeit?: number
  createOP?: boolean
}


export interface TomTerminArt {
  instance: string
  laenge?: number
  bezeichnung?: string
  farbe?: string
  ident: string
  visible?: boolean
  kuerzel?: string
  infoTermin?: boolean
  modus?: number
}


export interface Betriebsstaette {
  _id: string
  instance: string
  betriebsstaetteIdent: string
  tomBetriebsstaette: string
  contact: PraxisContact
  name: string
  lokalitaeten?: TomTerminLokalitaet[]
  localityIdents?: string[]
  active?: boolean
  displayPosition?: number,
  excludedAppointmentTypeIds?: string[],
  emailSettings?: BetriebsstaetteEmailSettings
  visible?: boolean // if false deleted for tomedo
  disableCoordinatesSync?: boolean
  disableContactSync?: boolean
  storeId?: string
  info?: I18NString
}

// ********** booking-date **********

// OtkDoctor is a front-end only interface. It is a combination of the fields in
// TomNutzer required by the frontend, plus imageUrl from user
export interface OtkDoctor {
  ident: string // Kalender id/identity
  fullName: string
  representingLetter: string // used if a photo is absent
  photo?: string
  description?: string
  kids: string[] // Kalender ids
}

// id of the doctor and the doctor's locality
export interface TomKalenderDaten {
  kid: string // id of the doctor
  lid: string // id of the doctor's locality
}
// an opening - a time window during which the opening occurs, along with
// the doctors offering the appointment
export interface BookingOpening {
  kdSet: TomKalenderDaten[]   // the doctors offering the opening with their identity ITomKalenderDaten[0] and locality ITomKalenderDaten[1]
  duration: number            // opening duration in minutes
  durationSearch?: number     // special tomedo search duration
  slotId?: number
  displayStringNames: string  // to be displayed in booking-date
  displayStringTime: string   // to be displayed in booking-date
  date: Date                  // opening date
  bsString?: string           // to display bs information
  // frontend only (waitinglist-dialog)
  formatDate?: string         // for slots in waitinglist
  dayIndex?: number           // dayIndex of date
}

export interface BookingOpeningL {
  kdSetKey: number
  start: string
  end: string | Date
  date: dayjs.Dayjs
}

export interface OptimizingOpening {
  start: number
  end: number
  kdSetKey: number
}

export interface BookingOpeningLInject {
  start: string
  end: string
  date: dayjs.Dayjs
  kdSet: TomKalenderDaten[]
}

export interface MultiOpening {
  lids: string[]
  kids: string[]
  openings: OpeningL[]
}
interface OpeningL {
  start: string
  end: string
}

export interface KdSetAlt {
  kids: string[]
  lids: string[]
  bsid?: string
}


export interface OtkReservation {
  _id: string                 // ObjectId in backend
  // instance: Types.ObjectId // no need, each instance has its own OtkReservation[]
  terminSucheIdent: string    // tomedo TerminSuche ident
  dateAppointment: Date,      // the booked appointment date
  dateExpiry: Date            // when does the reservation expire?
  doctorIds: string[]         // the ids of the involved doctors ("kid"s)
}

export interface OtkReservationL {
  dateAppointment: Date,      // the booked appointment date
  kids: string[]         // the ids of the involved doctors ("kid"s)
}

// ********** booking-anamnese **********
export const TravelDestination = {
  Africa: 'Afrika',
  America: 'Amerika',
  Antarctica: 'Antarktika',
  Asia: 'Asien',
  Atlantic: 'Atlantik',
  Australia: 'Australien',
  Europe: 'Europa',
  Indian: 'Indien',
  Pacific: 'Pazifik'
} as const
export type TravelDestination = typeof TravelDestination[keyof typeof TravelDestination]

export const TravelOccasion = {
  BusinessTrip: 'Geschäftsreise',
  RelativesOrFamily: 'Besuch von Bekannten oder Familie',
  Study: 'Studium',
  Backpacking: 'Backpacking',
  BeachHoliday: 'Strandferien',
  RemoteRegions: 'Reise in ländliche/abgelegene Regionen',
  Sportive: 'Sportliche Aktivitäten',
  Trekking: 'Trekking in Gebieten mit schlechter Infrastruktur',
  Humanitarian: 'Freiwilligenarbeit/Humanitärer Einsatz',
  Cruise: 'Kreuzfahrt',
  HighMountains: 'Hochgebirge',
  Pilgrimage: 'Pilgerreise',
  Safari: 'Safari',
} as const
export type TravelOccasion = typeof TravelOccasion[keyof typeof TravelOccasion]

export const Precondition = {
  Hypertension: 'Bluthochdruck',
  HeartDisease: 'Herzerkrankung',
  CoagulationDisorder: 'Blutgerinnungsstörung',
  LungDisease: 'Lungenerkrankung',
  Diabetes: 'Diabetes',
  Gastritis: 'Entzündliche Darmerkrankung',
  KidneyDisease: 'Nierenfunktionsstörung',
  LiverDisease: 'Lebererkrankung',
  Epilepsy: 'Epilepsie',
} as const
export type Precondition = typeof Precondition[keyof typeof Precondition]

export const CurrentTherapies = {
  Cortison: 'Cortison',
  Antibiotics: 'Antibiotika',
  Antidepressants: 'Antidepressiva',
  Hormones: 'Hormone (z.B. Pille)',
  Chemotherapy: 'Chemotherapie',
  RadiationTherapy: 'Strahlentherapie',
  Desensitization: 'Hyposensibilisierung',
} as const
export type CurrentTherapies = typeof CurrentTherapies[keyof typeof CurrentTherapies]
// ********** booking-summary **********

/**
 * the final object at the end of the booking process
 * which is sent to the server
 */
export interface OtkAppointment {
  readonly instance: string              // id of the instance where the appointment was booked
  readonly eventType: OtkEventType       // used for SSE emission; hard to replace by TypeScript introspection
  readonly terminSucheIdent: string      // tomedo TerminSuche ident
  start: Date                            // appointment start date
  end: Date                              // appointment end date
  readonly kdSet: TomKalenderDaten[]     // kid (doctor id) and lid (doctor's locality id) for all doctors giving the appointment.
  accessCode?: string                    // a code which one needs to type in order to confirm cancellation? for now ignore
  statusHistory?: OtkAppointmentStatus[] // optional because being set by the backend, so non-existent during book()
  readonly strategy: OtkBookingStrategy  // from AppointmentType
  readonly patientData: PatientData      // personal and insurance data submitted by the patient
  readonly isPrimary: boolean,           // is primary appointment out of several related ones?
  readonly referringDoctor?: string,
  readonly reservationId?: string,
  readonly schemaVersion: string,        // schema version
  // until and including tomedo version 123, "betriebsstaette" was actually the bs id, not the bs itself.
  // starting from tomedo version 124, "betriebsstaette" is really the bs, not the id
  readonly betriebsstaette?: string | Betriebsstaette // the (potentially) selected betriebstätte.
  waitinglistRequest?: WaitinglistRequest
  error?: string                         // error message received from backend load()
  isOnlineConsultation?: boolean         //is online consultation per vss
  onlineConsultationUrl?: string         // url for online consultation
  // for the app (therefore optional, because only defined during book() in booking-summary and by move in booking-edit)
  displayStringNames?: string            // to be displayed in booking-date. Example:  Dr. Acula, Dr. Agon'
  name?: I18NString                      // appointment display name
  description?: I18NString               // detailed appointment description,
  dialect?: Dialect                      // for booking-edit
  forerunTime?: number                   // appointment booked with forerunTime
  bookedOver?: BookedOverType
  isZuweiserTermin: boolean
  payment?: PaymentInfo
  paymentDeferred?: PaymentInfoDeferred
}

export interface OtkAppointmentSeries {
  instance: string
  terminKetteSucheIdent: string                 // tomedo TerminKetteSuche ident
  statusHistory: OtkAppointmentStatus[]
  otkAppointments: OtkAppointment[]
  accessCode?: string
  patientData: PatientData
  error?: string
}



// messages are sent to the app to notify the webview host of important events in the webview
// don't change this without communicating with the app team first
export enum MessageToApp {
  BOOK = 'messageToApp_Book',                 // user books an appointment
  MOVE_OPEN = 'messageToApp_MoveOpen',        // move dialog opened
  MOVE = 'messageToApp_Move',                 // appointmnet moved
  MOVE_CLOSE = 'messageToApp_MoveClose',      // move dialog closed
  CANCEL_OPEN = 'messageToApp_CancelOpen',    // cancel dialog opened
  CANCEL = 'messageToApp_Cancel',             // appointment cancelled
  CANCEL_CLOSE = 'messageToApp_CancelClose',  // cancel dialog closed
  EXIT = 'messageToApp_Exit',                 // on "exit" app-only button click in booking-result
  GET_BIRTHDATE = 'messageFromApp_Birthdate', // gets birthdate from app for booking-edit auth
  SET_BIRTHDATE = 'messageToApp_Birthdate',   // set birthdate in app in case of user input on bookin-edit
}

export interface WaitinglistRequest {
  before: Date
  statusHistory?: WaitinglistStatus[]
  rejected?: Date[]
}

export interface WaitinglistStatus {
  date: Date
  status: WaitinglistStatusType
}

export enum WaitinglistStatusType {
  SUBSCRIBE = 'subscribe',
  PROPOSAL_SENT = 'proposal_sent',
  RENEWED_INTEREST = 'renewed_interest',
  UNSUBSCRIBE = 'unsubscribe'
}

export enum OtkEventType {
  OTKAPPOINTMENT = 'OtkAppointment',
  OTKAPPOINTMENTSERIES = 'OtkAppointmentSeries',
  OTKATTACHMENT = 'OtkAttachment',
  OTKCOMPOUNDEVENT = 'OtkCompoundEvent',
  OTKMSGSTATUS = 'OtkMsgStatus',
  OTKRESERVATION = 'OtkReservation',
  OTKWEBFORM = 'OtkWebform',
}

export interface OtkAppointmentStatus {
  date: Date
  status: OtkAppointmentStatusType
}

export interface OtkAttachment {
  data: string
  mimeType: string
  filename: string
}

export enum OtkAppointmentStatusType {
  CANCELLED_BY_CLIENT = 'cancelled_by_client', // cancelled by patient; is replaced by CANCEL_REQUESTED_BY_CLIENT, CANCEL_CONFIRMED_BY_HOST
  CANCELLED_BY_HOST = 'cancelled_by_host',     // cancelled by doctor's office
  MOVED_BY_CLIENT = 'moved_by_client',         // moved by patient
  MOVED_BY_HOST = 'moved_by_host',             // moved by doctor's office
  CONFIRMED_BY_HOST = 'confirmed_by_host',     // booking confirmed by host
  CONFIRMED_BY_CLIENT = 'confirmed_by_client', // booking confirmed by client
  FAILED = 'failed',                           // technical failure prevented processing of booking
  PENDING = 'pending',                         // pending confirmation by doctor's office (SSE acked)
  REJECTED = 'rejected',                       // booking rejected
  REQUESTED_BY_CLIENT = 'requested_by_client', // booking requested (SSE emitted)
  REQUESTED_BY_HOST = 'requested_by_host',     // booking requested by doctor's office (TOM-12605)
  UNAVAILABLE = 'unavailable',                 // appointment slot unavailable; try different slot
  UNSPECIFIED = 'unspecified',                 // rejected for unspecified reason; contact doctor's office
  WAITING = 'waiting',                         // on waiting list, pending cancellation of confirmed booking
  WAITINGLIST_UPDATE = 'waitinglist_update',   // send unsubscribe in waitinglist_request
  // new move states with tomedo server 119
  MOVE_REQUESTED_BY_CLIENT = 'move_requested_by_client',
  MOVE_CONFIRMED_BY_HOST = 'move_confirmed_by_host',
  MOVE_CANCELLED_BY_HOST = 'move_cancelled_by_host',
  // new cancel states with tomedo version 140; they replace CANCELLED_BY_CLIENT
  CANCEL_REQUESTED_BY_CLIENT = 'cancel_requested_by_client',    // cancellation requested by client
  CANCEL_CONFIRMED_BY_HOST = 'cancel_confirmed_by_host'         // requested cancellation confirmed by host
}

export enum OtkBookingStrategy {
  CONFIRM = IOtkBookingStrategy.CONFIRM,            // the booking needs to be manually confirmed, until which the booking is pending. The final state is confirmed/rejected/unavailable
  AUTOCONFIRM = IOtkBookingStrategy.AUTOCONFIRM,    // the booking is automatically confirmed/unvailable
  ALWAYSBOOK = IOtkBookingStrategy.ALWAYSBOOK,      // the booking is always confirmed regardless of availability (not recommended)
}

export type CustomFieldResponse = ICustomFieldResponse
export function isCustomFieldResponse(x: any): x is CustomFieldResponse {
  return (
    (x as CustomFieldResponse).identifier !== undefined
    && (x as CustomFieldResponse).required !== undefined
  )
}

export type CheckboxResponse = ICheckboxResponse
export interface PatientData {
  personal: PersonalData
  insurance: PatientInsurance
  customFieldResponses?: CustomFieldResponse[]
  checkboxResponses?: CheckboxResponse[]
}

export interface ContactData {
  personal: PersonalContactData
  customFieldResponses?: CustomFieldResponse[]
}

export interface PersonalContactData {
  customerId?: string     // tomedo customer number
  fname: string           // requesting users first name
  lname: string           // requesting users last name
  email: string           // requesting users contact email address
  issue: string           // type of contact request
  info?: string           // full request information
  birthdate?: Date        // date of birth - optional when zollsoftMode active
  gender?: string         // gender code
  phone?: string          // phone number
  street?: string         // full street name
  streetNumber?: string   // house number
  zip?: string            // postal code of city
  city?: string           // city name
  countryCode?: string    // alpha2code of country
}

export interface PatientInsurance {
  type: PatientInsuranceType
  name?: string,
  number?: string
}


/**
 * Do we need this one? ToMa 2023-12-12
 * compare {@link InsuranceType}
 */
export enum PatientInsuranceType {
  GKV = 'gkv',        // gesetzlich versichert
  PKV = 'pkv',        // privat versichert
  HZV = 'hzv',        // Hausarztzentrierte Versorgung
  SZ = 'sz',          // selbstzahler
  BG = 'bg',          // berufsgenossenschaft
  UNKNOWN = 'unknown',// unbekannt
  NONE = 'none'       // unused by backend. In frontend the form sends this value if patientType does not exist. So we need to account for it.
}

export type PersonalData = Pick<
  IPersonalData,
  'fname' | 'lname' | 'email' | 'birthdate'
> &
  Partial<IPersonalData>;

// doctor locality. Used only for displaying it to the user.
// It is assumed that all doctors of the list of doctors
// offering the appointment have the same locality.
export interface TomTerminLokalitaet extends Document {
  bezeichnung?: string
}

export interface TomTerminLokalitaetWithSite extends TomTerminLokalitaet {
  site?: Betriebsstaette
}

export const BookingResult = {
  SUCCESS: 'Success',
  FAILURE: 'Failure'
} as const
export type BookingResult = typeof BookingResult[keyof typeof BookingResult]

export interface BookingData {
  start: Date                   // appointment start date
  end: Date                     // appointment end date
  accessCode: string            // special page access code
  birthDate: Date               // patient birthdate
  name: string                  // appointment type name
  description: string           // appointment type description
  status: OtkAppointmentStatus  // current booking status of appointment
}

export enum AppointmentErrors {
  Expired = 'ERROR-EXPIRED',                        // current date already after appointment start
  CancellationWindow = 'ERROR-CANCELLATION-WINDOW',  // tried to cancel or move appointment after alotted time window
  PartlyCancelled = "ERROR-PARTLY-CANCELLED",         // tried to move appointment series after partly cancelled by host
  Parameter = 'ERROR-PARAMETER',                      // no access code has been set
  Unknown = 'ERROR-UNKNOWN',                          // no matching appointment found
  Cancelled = 'ERROR-IS-CANCELLED',                    // appointment was already cancelled
  MoveButCancelled = "ERROR-CANCELLED"              //Tries to move a cancelled appointment
}

export type BookingCancelDialogComponentData = {
  accessCode: string,
  instanceId: string,
  inApp: boolean
}