import { AppointmentSeriesType, AppointmentSeriesTypeItem, Betriebsstaette, BookingOpeningL, BookingOpeningLInject, ConditionalAge, KdSetAlt, MultiOpening, OptimizingOpening, OtkDoctor, OtkReservationL, TimeComparator } from "@a-d/entities/Booking.entity";
import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import dayjs from 'dayjs';
import duration from "dayjs/plugin/duration";
import { BehaviorSubject } from "rxjs";

dayjs.extend(duration);

/** 
 * An instance of this class handles the multislots coming from the backend for an appointment series type.
 * It manages the whole pipeline in the patient selecting process, processing, filtering, pruning ...
 * it will be reset/initialized fo a new appointmentseriestype with the init method
*/
export class MultiSlots {
  constructor(
    private adLoggerService: AdLoggerService,
  ) { }

  public openingsMulti: BookingOpeningL[][] = []
  public openingsMultiPruned: BookingOpeningL[][] = []
  public kdSetMap: Map<number, KdSetAlt> = new Map()
  public doctorArray = new BehaviorSubject<string[]>([])
  private doctorsVisible: OtkDoctor[]
  public bsArray = new BehaviorSubject<string[]>([])
  private bsIdSelected = ''
  private durationSincePrevious: duration.Duration[] = []
  private durationToSearch: duration.Duration[] = []
  private appointmentDuration: duration.Duration[] = []
  private mhd: number[] = []
  private chainLength = 0
  public calendarPerSeriesItems: string[][] = []
  private isBsActive = false
  private allBs: Betriebsstaette[]
  public selectedOpenings: BookingOpeningL[] = []
  public doctorsPerSeriesItems = new BehaviorSubject<OtkDoctor[][]>([])
  private ageConditions: ConditionalAge
  private birthDate: Date
  private reservations?: OtkReservationL[][]





  /**
   * method to prepare the MultiOpening from to backend 
   * It will then prepare the openingsMulti 2-dim array, where the openingsMulti will be displayed in the booking-date multi component
   * Hint: For frontend gridding - in case the distance from the previous selected appointment is not in day - the openingsMulit gets further gridded in the prune Openings method
   * This function assumes also that reset() is run so all arrays to be filled are empty
   */
  public prepareOpeningsMultiAndKdSetMap(multiIn: MultiOpening[][], reservations?: OtkReservationL[][]) {

    console.time("prepareOpeningsMulti")

    let idx = 0
    //Loop over the length of the chain
    for (let i = 0; i < multiIn.length; i++) {
      this.openingsMulti.push([])
      this.calendarPerSeriesItems.push([])

      //Loop over the different kdsets
      for (let j = 0; j < multiIn[i].length; j++) {
        const kids = multiIn[i][j].kids
        const lids = multiIn[i][j].lids

        //determine the id of the bs which is associated to the lids should be only one so we take first 
        const bsid = this.isBsActive ? this.allBs.find(x => x.localityIdents.some(y => lids.includes(y)))?._id || '' : ''

        //collect all calendars for the current element in chain, so we can fill the doctor selection input on booking-date multi, which filters the openings for doctor
        this.calendarPerSeriesItems[i].push(...kids)

        //set the map from index key to kdset. the openingsMulti from this kdset will have the key for the map
        this.kdSetMap.set(idx, { kids, lids, bsid })

        //construct the multiOpenins with dajs date and kdsetkey
        const newOps = multiIn[i][j].openings.map(op => ({
          ...op,
          kdSetKey: idx,
          date: dayjs(op.start)
        }))

        //push the multiOpenings 
        this.openingsMulti[i].push(...newOps)

        //Increment the map key
        idx++
      }

      // sorting takes long time so we dont do it as they should be sorted from the backend response anyway
      // the long time 
      //this.openingsMulti[i] = this.openingsMulti[i].sort((opening1, opening2) =>
      //opening1.date.diff(opening2.date))
    }
    console.timeEnd("prepareOpeningsMulti")

    /**
     * Reservations will be checked we get them form the backend also for each of the entries in the chain
     * We filter them here but just for the first chain entry or in case the distance to previous is in days mhd = 2
     * Otherwise the openings are not gridded yet
     */
    if (reservations) {
      for (let i = 0; i < this.openingsMulti.length; i++) {
        if (reservations[i]?.length && (this.mhd[i] === 2 || i === 0)) {
          for (const reservation of reservations[i]) {
            try {
              const resTime = reservation.dateAppointment
              const idx = this.openingsMulti[i].findIndex(x => x.date.toDate().getTime() === resTime.getTime() && this.kdSetMap.get(x.kdSetKey).kids.every(y => reservation.kids.includes(y)))
              if (idx > -1) this.openingsMulti[i].splice(idx, 1)
            } catch (error) {
              this.adLoggerService.error(error)
            }
          }
        }
      }
    }


    /**
     * We check the age Condition of the kinderarzt modul like in the case for normal appointments
     * we do it just for the first appointment slots in the chain since this would propably the behavior the doctors will expect
     */
    if (this.ageConditions?.show && this.birthDate && this.ageConditions.condition.length) {
      const firstCondition = this.ageConditions.condition[0]
      const firstBoundaryDate = dayjs(this.birthDate).add(firstCondition.timeSpan, firstCondition.timeUnit);
      const boundaryComparators = [TimeComparator.GREATER_THAN, TimeComparator.GREATER_THAN_EQUALS]
      const firstPredicate = boundaryComparators.includes(firstCondition.timeComparator) ? (op: BookingOpeningL) => op.date.unix() > firstBoundaryDate.unix() : (op: BookingOpeningL) => op.date.unix() < firstBoundaryDate.unix()

      const secondCondition = this.ageConditions.condition[1]
      if (!secondCondition) {
        this.openingsMulti[0] = this.openingsMulti[0].filter(firstPredicate);
      }
      else {
        const secondBoundaryDate = dayjs(this.birthDate).add(secondCondition.timeSpan, secondCondition.timeUnit);
        const secondPredicate = boundaryComparators.includes(secondCondition.timeComparator) ? (op: BookingOpeningL) => op.date.unix() > secondBoundaryDate.unix() : (op: BookingOpeningL) => op.date.unix() < secondBoundaryDate.unix()
        this.openingsMulti[0] = this.openingsMulti[0].filter(op => firstPredicate(op) && secondPredicate(op));
      }
    }

    /**
     * we copy the openingsMulti. We will prune and work with openingsMultiPruned 
     * but need the openingsMulti as backup to reset the slots
     */
    this.openingsMultiPruned = [...this.openingsMulti]
  }


  /**
   * Init/reset the multislots with new data from the backend
   */
  public init(appSeriesType: AppointmentSeriesType, multiOpenings: MultiOpening[][], isBsActive = false, allBs: Betriebsstaette[], reservations?: OtkReservationL[][], ageConditions?: ConditionalAge, birthDate?: Date) {
    //bsstuff
    this.isBsActive = isBsActive
    this.allBs = allBs
    this.reservations = reservations
    //pediatrician stuff
    this.ageConditions = ageConditions
    this.birthDate = birthDate
    //reset
    this.reset()
    this.setHelpers(appSeriesType)
    this.prepareDurations(appSeriesType)
    this.prepareOpeningsMultiAndKdSetMap(multiOpenings, reservations)
  }

  /**
   * We reset all properties to the initial state
   */
  private reset() {
    this.kdSetMap.clear()
    this.openingsMulti.length = 0
    this.openingsMultiPruned.length = 0
    this.durationSincePrevious.length = 0
    this.durationToSearch.length = 0
    this.selectedOpenings.length = 0
    this.calendarPerSeriesItems.length = 0
    this.mhd.length = 0
    this.appointmentDuration.length = 0
    this.bsIdSelected = ''
  }

  private setHelpers(appSeriesType: AppointmentSeriesType) {
    this.chainLength = appSeriesType.items.length
  }


  /**
   * We prepare the distances constraints between the appointment
   * They will be initialized as dayjs durations which can be build from the iso string in the db
   * eg 'P1Y2M3DT4H5M6S' = 1Jahr, 2Monate, 3Tage - 4 Stunden, 5 Minuten, 6 Sekunden
   * we only have durations in minutes,hours and days
   */
  private prepareDurations(appSeriesType: AppointmentSeriesType) {
    appSeriesType.items.forEach((item: AppointmentSeriesTypeItem) => {
      this.durationSincePrevious.push(dayjs.duration(item.durationSincePrevious))
      this.durationToSearch.push(dayjs.duration(item.durationToSearch))
      this.appointmentDuration.push(dayjs.duration(item.duration || 0, 'minutes'))
      this.mhd.push(item.mhd || 0)
    })
  }

  /**
   * Method to select opening at the give index in the chain
   */
  public selectOpening(opening: BookingOpeningL, index: number) {
    // check is to select next in series
    // if (this.selectedOpenings.length !== index) return

    // do we need to reset the remaining slots in case patient goes back in chain 
    // and changes selection (eg. has selected firstopening, secondopening, thirdopening and changes secondopening
    // we have to reset the slots in the third, fourth ... place in the chain)
    let resetRemaining = false
    if (this.selectedOpenings[index]) resetRemaining = true

    // If bsActive and first selected slot
    // the first slot fixes the betriebsstätte so all appointments in the chain are in the same betriebsstätte
    if (this.isBsActive && index === 0) this.bsIdSelected = this.kdSetMap.get(opening.kdSetKey).bsid

    // we set the selected opening
    this.selectedOpenings[index] = opening

    // if its the last item in chain we are done
    if (index + 1 === this.chainLength) return

    // if we need to reset the remaining slots 
    if (resetRemaining) this.resetRemaining(index)

    // now we prune the remaining openings, since the following appointment must fulfill the constraints
    // given by durationSincePrevious and durationToSearch which is plusZeit and varianzZeit in tomedo
    this.pruneOpenings(opening, index)
  }


  /*
  * Method to select all openings in case of automaticSelection property appointmentSeriesType
  * It tries to always select th first opening in the pruned openings for each search in the chain
  * It could be that this does not work (in case of badly placed reservations which invalidate the cleaning from 
  * tomedo or that there is no more valid appointment sequence in the openings any more)
  * 
  * In a previous version in the case of select of all first failure this function tried to utilize the magicprune function below.
  * Magic prun is a trashier variant of tomedo cleaning which works on discrete openings to clean the chain to it cleans the chain in a variant of tomedo cleaning and tries again to select with the all first strategy
  * Since now we use frontend gridding for appointment series which have constraints in minute and days (and this are the only appointment series with automatic selection) we cannot use magic prune anymore. 
  * Perspectively if this causes more frequent problems with many peoply booking at the sime time it should be considerer to rebuild the complete tomedo algorithm for cleaning the slots or devise maybe a light version of this algorithm
  * Maybe a recursive brute force approach with depth limit, so if it does not succeed it is unlikely that a valid series exists (see ADI-2388 ) 
  */
  public selectAllOpenings(opening: BookingOpeningL): BookingOpeningL[] {
    // try naively always select first. should work if no badly placed reservations 
    if (this.selectedOpenings[0]) this.resetRemaining(0)
    if (this.selectAllFirst(opening)) return this.selectedOpenings

    //did not work reset and magic prune
    this.resetRemaining(1)
    if (this.magicPrune() && this.selectAllFirst(opening)) return this.selectedOpenings
    else return null
  }



  /** 
  * select all first strategy: always select the first opening in 
  * openingsMultiPruned prune the openings for the following appointment and repeat
  */
  selectAllFirst(opening: BookingOpeningL): boolean {
    this.selectedOpenings[0] = opening

    // If bsActive
    if (this.isBsActive) this.bsIdSelected = this.kdSetMap.get(opening.kdSetKey).bsid


    // try naively always select first. should work if no reservations 
    for (let i = 0; i < this.openingsMultiPruned.length - 1; i++) {
      this.pruneOpenings(this.selectedOpenings[i], i)
      if (!this.openingsMultiPruned[i + 1].length) {
        return false
      }
      this.selectedOpenings[i + 1] = this.openingsMultiPruned[i + 1][0]
    }
    return true
  }


  /**
   * The fundament of this class a method to prune the openings for the following appointment.
   * Also griddes the slots for the next appointment before pruning in case mhd !== 2 which means the constraints 
   * durationSincePrevious and durationToSearchNext are given in minutes or seconds.
   * The frontend gridding is because the startpoint for the next appointment depends then on the previous selected opening, 
   * see also the explanation in the prepareOpenings method in chain.openings.service
   */
  pruneOpenings(lastOpeningChosen: BookingOpeningL, lastAppIndex: number) {
    console.time("prune Openings")

    const durationSincePreviousChosen = this.durationSincePrevious[lastAppIndex + 1]
    const durationToSearchNext = this.durationToSearch[lastAppIndex + 1]

    const refDate = lastOpeningChosen.date
    if (!refDate) return

    //If constraints is in minute or hour we get the ungridded slots from the backend and we grid them here
    if (this.mhd[lastAppIndex + 1] !== 2) {
      //constraints
      const after = refDate.add(durationSincePreviousChosen)
      const before = after.add(durationToSearchNext)

      const afterGrid = after.add(this.appointmentDuration[lastAppIndex + 1]) //
      const beforeGrid = before.subtract(this.appointmentDuration[lastAppIndex + 1]) //maybe laengeforautosuche

      //same day and appointment fits in
      this.openingsMultiPruned[lastAppIndex + 1] = this.openingsMultiPruned[lastAppIndex + 1].filter(op => op.date.isSame(refDate, 'day') && afterGrid.valueOf() <= (new Date(op.end)).getTime() && beforeGrid.valueOf() >= op.date.valueOf())

      //pruning ending
      if (this.isBsActive && this.bsIdSelected) this.openingsMultiPruned[lastAppIndex + 1] = this.openingsMultiPruned[lastAppIndex + 1].filter(
        (opening: BookingOpeningL) => this.bsIdSelected === this.kdSetMap.get(opening.kdSetKey).bsid)

      //cut 
      this.openingsMultiPruned[lastAppIndex + 1] = this.openingsMultiPruned[lastAppIndex + 1].map(op => ({ ...op, ...(op.date.isBefore(after) ? { date: after, start: after.toISOString() } : {}), ...(new Date(op.end).getTime() >= before.valueOf() ? { end: before.toISOString() } : {}) }))

      this.openingsMultiPruned[lastAppIndex + 1] = this.openingsMultiPruned[lastAppIndex + 1].reduce((prev, opening) => {
        const startUnix = opening.date.valueOf()
        const endUnix = new Date(opening.end).valueOf()
        const duration = this.appointmentDuration[lastAppIndex + 1].asMilliseconds()
        return [...prev, ...Array.from({ length: Math.floor((endUnix - startUnix) / duration) }, (_, i) =>
        ({
          start: new Date(startUnix + (i * duration)).toISOString(),
          end: new Date(startUnix + (i + 1) * duration).toISOString(),
          date: dayjs(startUnix + (i * duration)),
          kdSetKey: opening.kdSetKey
        }))]
      }, [])

      //reservations
      if (this.reservations?.[lastAppIndex + 1]?.length)
        this.openingsMultiPruned[lastAppIndex + 1] = this.openingsMultiPruned[lastAppIndex + 1].filter(op => !this.reservations[lastAppIndex + 1].some(res => Math.abs(res.dateAppointment.getTime() - op.date.valueOf()) <= this.appointmentDuration[lastAppIndex + 1].asMilliseconds()))

    } else {
      //constraints
      const after = (durationSincePreviousChosen.asMilliseconds() !== 0) ? refDate.startOf("day").add(durationSincePreviousChosen) : refDate
      const before = after.add(durationToSearchNext).add(1, 'day')


      if (this.isBsActive && this.bsIdSelected) this.openingsMultiPruned[lastAppIndex + 1] = this.openingsMulti[lastAppIndex + 1].filter(
        (opening: BookingOpeningL) => opening.date.valueOf() >= after.valueOf() && opening.date.isBefore(before) && this.bsIdSelected === this.kdSetMap.get(opening.kdSetKey).bsid)
      else this.openingsMultiPruned[lastAppIndex + 1] = this.openingsMulti[lastAppIndex + 1].filter(
        (opening: BookingOpeningL) => opening.date.valueOf() >= after.valueOf() && opening.date.isBefore(before))
    }
    console.timeEnd("prune Openings")

    const allkdkeys = [...new Set(this.openingsMultiPruned[lastAppIndex + 1].map(x => x.kdSetKey))]
    const newCalPerSeries = allkdkeys.map(x => this.kdSetMap.get(x).kids).flat()
    const currentDocs = this.doctorsPerSeriesItems.value
    const newdocsforitem = this.doctorsVisible.filter(doc => doc.kids.some(y => newCalPerSeries.includes(y)))
    currentDocs[lastAppIndex + 1] = newdocsforitem
    this.doctorsPerSeriesItems.next(currentDocs)
  }


  magicPrune(): boolean {
    let before: dayjs.Dayjs, after: dayjs.Dayjs

    if (this.openingsMultiPruned.length <= 2) return false
    for (let i = this.openingsMultiPruned.length - 2; i > 0; i--) {
      // filter all reachable
      this.openingsMultiPruned[i].filter((op: BookingOpeningL) => {
        after = this.mhd[i] === 2 ? op.date.startOf("day").add(this.appointmentDuration[i]) : op.date.add(this.durationSincePrevious[i + 1]).add(this.appointmentDuration[i])
        before = after.add(this.durationToSearch[i + 1]).subtract(this.appointmentDuration[i])
        return this.openingsMultiPruned[i + 1].some(opNext => opNext.date.isBefore(before) && opNext.date.isAfter(after))
      })
      if (!this.openingsMultiPruned[i].length) return false
    }
    return true
  }


  prepareOptimization(opening: BookingOpeningL) {
    const optOp: OptimizingOpening[][] = []
    for (let i = 1; i <= this.chainLength; i++) {
      optOp.push(this.openingsMulti[i].filter(op => op.date.isSame(opening.date, 'day') && (!this.isBsActive || !this.bsIdSelected || this.bsIdSelected === this.kdSetMap.get(op.kdSetKey).bsid))
        .map(op => ({ start: new Date(op.start).getTime(), end: new Date(op.end).getTime(), kdSetKey: op.kdSetKey })))
    }
  }


  resetRemaining(index: number) {
    this.selectedOpenings.length = index + 1
    for (let i = index + 1; i < this.openingsMultiPruned.length; i++) {
      this.openingsMultiPruned[i] = [...this.openingsMulti[i]]
    }
  }

  makeDoctorArray(doctorsVisible: OtkDoctor[]) {
    //save reference
    this.doctorsVisible = doctorsVisible

    if (!doctorsVisible?.length) return
    const doctorsArray = []
    this.kdSetMap.forEach((kdSetAlt, key) => {
      doctorsArray[key] = doctorsVisible.filter(x => x.kids.some(y => kdSetAlt.kids.includes(y))).map((doc: OtkDoctor) => doc.fullName).join(',')
    })
    this.doctorArray.next(doctorsArray)

    const docs = this.calendarPerSeriesItems.map(cals => doctorsVisible.filter(doc => doc.kids.some(y => cals.includes(y))))
    this.doctorsPerSeriesItems.next(docs)
  }

  makeBsArray(betriebsstaetten: Betriebsstaette[]) {
    if (!betriebsstaetten?.length || !this.isBsActive) return
    const bsArray = []
    this.kdSetMap.forEach((kdSetAlt, key) => {
      bsArray[key] = betriebsstaetten.find(x => x.localityIdents.some(y => kdSetAlt.lids.includes(y))).name || 'Kein Standort'
    })
    this.bsArray.next(bsArray)
  }


  isDoctorForSlot(opening: BookingOpeningL, doc: OtkDoctor) {
    return this.kdSetMap.get(opening.kdSetKey).kids.some(x => doc.kids.includes(x))
  }

  injectOpenings(openings: BookingOpeningLInject[]) {
    let kdSetMapIndex = this.kdSetMap.size

    for (const [index, op] of openings.entries()) {
      const kids = op.kdSet.map(x => x.kid)
      const lids = op.kdSet.map(x => x.lid)
      const bsid = this.isBsActive ? this.allBs.find(x => x.localityIdents.some(y => lids.includes(y)))?._id || '' : ''
      this.kdSetMap.set(kdSetMapIndex, { kids, lids, bsid })

      this.openingsMulti[index].push({ kdSetKey: kdSetMapIndex, start: op.start, date: op.date, end: op.end })
      this.calendarPerSeriesItems[index] = [...this.calendarPerSeriesItems[index], ...kids]

      //need to sort it now
      this.openingsMulti[index] = this.openingsMulti[index].sort((opening1, opening2) =>
        opening1.date.unix() - opening2.date.unix())

      kdSetMapIndex++;
    }

    //handle doctorperSeriesItemss
    const docs = this.calendarPerSeriesItems.map(cals => this.doctorsVisible.filter(doc => doc.kids.some(y => cals.includes(y))))
    this.doctorsPerSeriesItems.next(docs)
  }


}
