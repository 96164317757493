import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { Router } from '@angular/router'
import { validateWfaForm, WfaFormId } from '@arzt-direkt/wfa-definitions'
import { isWfaMessage, isNil } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { RxLet } from '@rx-angular/template/let'

import { WfaEnvService } from '../../../environments/wfa-env.service'
import { I18nPipe } from '../../utility/i18n.pipe'
import { MatInputPopupComponent } from './../../utility-components/mat-input-popup.component'
import { navigateToAdisEditor } from './../zs-support/navigate-to-adis-editor'
import {
  openEditWfaFormIdModal,
  ZsSupportService,
} from './../zs-support/zs-support.service'
import { ZsSupportCommsService } from './../zs-support/zs-support-comms.service'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-create-blank-form',
  styleUrls: [],
  templateUrl: `./create-blank-form.component.html`,
  imports: [
    CommonModule,
    I18nPipe,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputPopupComponent,
    MatTableModule,
    MatTooltipModule,
    RxLet,
    TranslateModule,
  ],
})
export class CreateBlankFormComponent {
  constructor(
    private comms: ZsSupportCommsService,
    private router: Router,
    private translate: TranslateService,
    public wfaEnvService: WfaEnvService,
    public zsSupportService: ZsSupportService,
  ) {}

  openEditorWithBlankDraft() {
    const praxisId = this.zsSupportService.praxisDetails$.value?._id
    if (isNil(praxisId)) {
      console.error(
        '[CreateBlankFormComponent/openEditorWithBlankDraft]: praxisId is undefined',
      )
      return
    }

    openEditWfaFormIdModal(this.zsSupportService.editWfaFormIdModal)
      .pipe(untilDestroyed(this))
      .subscribe((wfaFormId: WfaFormId) => {
        this.zsSupportService.showSpinner$.next(true)
        this.comms
          .createBlankDraft(praxisId, wfaFormId)
          .pipe(untilDestroyed(this))
          .subscribe((response: any) => {
            this.zsSupportService.showSpinner$.next(false)
            const wfaForm = validateWfaForm(response.queryResult)
            if (isWfaMessage(wfaForm)) {
              console.error(
                '[openDraftEditor]: expected valid formDraft, got',
                wfaForm.message,
                'for form',
                response.queryResult,
              )
            } else {
              navigateToAdisEditor(wfaForm, this.router, this.wfaEnvService)
            }
          })
      })
  }
}
