import { get, getCurrentDateTime } from '@arzt-direkt/wfa-generic-utils'

import { json } from './../../assets/json/json.barrel'
import { WfaForm } from './../../interfaces-autogen-from-joi/wfa-form'
import version from './../../version.json'
import { convertToWfaFormId } from './../convert-to-wfa-form-id'

const { wfaVersion } = version

export interface LegacyMap {
  legacyName: string
  wfaName: string
  id: string
}

function createLegacyMap(
  legacyName: string,
  wfaName: string,
): LegacyMap | null {
  const id = get(json, `anamnese.${wfaName}.wfaFormId`)
  if (id == null) return null
  return { legacyName, wfaName, id }
}

const legacyMap = [
  ['allgemein', 'generalHealthIssue'],
  ['haut', 'skinIssues'],
  ['varizen', 'varices'],
  ['bereitschaftsdienst', 'videoConsultationStandby'],
  ['op-kontrolle', 'postSurgeryControl'],
  ['corona', 'coronaVirus'],
]
  .map(e => createLegacyMap(e[0], e[1]))
  .filter(e => e != null) as unknown as LegacyMap[]

function idByLegacyName(legacyName: string): string | undefined {
  return legacyMap.find(e => e.legacyName === legacyName)?.id
}

function wfaNameById(id: string): string | undefined {
  return legacyMap.find(e => e.id === id)?.wfaName
}

export function form(id: string): WfaForm | undefined {
  const wfaName = wfaNameById(id)
  if (wfaName == undefined) return undefined
  return get(json, `anamnese.${wfaName}`)
}

export function legacyForm(legacyName: string): WfaForm | undefined {
  const id = idByLegacyName(legacyName)
  if (id == undefined) return undefined
  console.log(`for legacy name`, legacyName, `got name`, id)
  return form(id)
}

export const dummyMetadataTitle = {
  de: '[zq83] Formulartitel',
}
export const dummyMetadataPraxisId = '666c120302cd8cbc04a7f597'
export const dummyMetadataVersionId = '666c120302cd8cbc04a7f597'

export function updateMetadata(
  maybeForm: Partial<WfaForm>,
  date: Date = new Date(),
): WfaForm {
  const maybeWfaFormId =
    maybeForm.wfaFormId ?? `form-${getCurrentDateTime(date)}`
  const wfaFormId = convertToWfaFormId(maybeWfaFormId)
  return {
    title: dummyMetadataTitle,
    instance: dummyMetadataPraxisId,
    praxisId: dummyMetadataPraxisId,
    status: 'live',
    wfaFormVersionId: dummyMetadataVersionId,
    ...maybeForm,
    // the following attributes are always verified / updated
    wfaFormId,
    wfaVersion: wfaVersion,
  }
}
