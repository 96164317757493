import { validateWfaKey } from './validate-wfa-key-on-change'
import { validateShortTitle } from './validate-short-title-on-change'
import { SurveyCreatorModel } from 'survey-creator-core'

export function validatePropertiesOnValueChange(
  creator: SurveyCreatorModel,
): void {
  creator.onPropertyValidationCustomError.add((creator, options) => {
    if (options.propertyName === 'wfaKey') {
      validateWfaKey(creator, options)
    } else if (options.propertyName === 'shortTitle') {
      validateShortTitle(creator, options)
    }
  })
}
