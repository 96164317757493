import Joi from 'joi'

import { LocStringJoi } from '../loc-string.joi'
import { WfaQuestionValidatorJoi } from './../wfb/wfa-question-validator.joi'
import { ClearIfInvisibleJoi } from './base-question-props/clear-if-invisible.joi'
import { DescriptionLocationJoi } from './base-question-props/description-location.joi'
import { ErrorLocationJoi } from './base-question-props/error-location.joi'
import { TitleLocationJoi } from './base-question-props/title-location.joi'
import {
  baseQuestion,
  baseQuestionWithoutShortTitle,
  minBaseQuestion,
} from './examples/base-question.joi.example'

export const MinBaseQuestionJoi = Joi.object()
  .keys({
    clearIfInvisible: ClearIfInvisibleJoi.optional(),
    comment: Joi.string().optional(),
    commentPlaceholder: LocStringJoi.optional(),
    commentText: Joi.string().optional(),
    correctAnswer: Joi.string().optional(),
    defaultValue: Joi.any().optional(),
    defaultValueExpression: Joi.any().optional(),
    enableIf: Joi.string().optional(),
    errorLocation: ErrorLocationJoi.optional(),
    hideNumber: Joi.boolean().optional(),
    id: Joi.string().optional(),
    indent: Joi.string().optional(),
    name: Joi.string().required(),
    page: Joi.any().optional(),
    placeholder: LocStringJoi.optional(),
    requiredErrorText: Joi.string().optional(),
    resetValueIf: Joi.string().optional(),
    setValueExpression: Joi.string().optional(),
    setValueIf: Joi.string().optional(),
    showCommentArea: Joi.boolean().optional(),
    showOtherItem: Joi.boolean().optional(),
    startWithNewLine: Joi.boolean().optional(),
    title: LocStringJoi.optional(),
    titleLocation: TitleLocationJoi.optional(),
    type: Joi.string().required(),
    useDisplayValuesInDynamicTexts: Joi.boolean().optional(),
    validators: Joi.array().items(WfaQuestionValidatorJoi),
    valueName: Joi.string().optional(),
    visible: Joi.boolean().optional(),
    visibleIf: Joi.string().optional(),
  })
  .meta({
    className: 'MinBaseQuestion',
  })
  .example(JSON.stringify(minBaseQuestion, null, 2))

export const BaseQuestionWithoutShortTitleJoi = MinBaseQuestionJoi.keys({
  description: LocStringJoi.optional(),
  descriptionLocation: DescriptionLocationJoi.optional(),
  isRequired: Joi.boolean().optional(),
  requiredIf: Joi.string().optional(),
})
  .meta({
    className: 'BaseQuestionWithoutShortTitle',
  })
  .example(JSON.stringify(baseQuestionWithoutShortTitle, null, 2))

export const BaseQuestionJoi = BaseQuestionWithoutShortTitleJoi.keys({
  shortTitle: Joi.string().required().max(45),
})
  .meta({
    className: 'BaseQuestion',
  })
  .example(JSON.stringify(baseQuestion, null, 2))
